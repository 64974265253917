<template>
  <div v-if="!!label" class="root">
    <label>{{ label }}</label>
    <div ref="code" class="code" :data-language="language" :class="{ 'text-edit-mode': textEditMode }" />
  </div>
  <div v-else ref="code" class="code" :data-language="language" :class="{ 'text-edit-mode': textEditMode }" />
</template>
<script>
import * as monaco from 'monaco-editor/esm/vs/editor/editor.api';

export default {
  props: {
    value: {
      type: String,
      required: true
    },
    language: {
      type: String,
      default: 'json'
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    suggestions: {
      type: Array,
      default: () => null
    },
    autoHeight: {
      type: Boolean,
      default: false
    },
    customOptions: {
      type: Object,
      default: () => {}
    },
    label: {
      type: String,
      default: ''
    },
    textEditMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const options = {
      theme: 'vs-dark',
      minimap: {
        enabled: false
      },
      scrollbar: {
        verticalScrollbarSize: 6,
        horizontalScrollbarSize: 6
      },
      automaticLayout: true,
      wordWrap: 'on'
    };
    if (this.textEditMode) {
      options.wordWrap = 'off';
      options.lineNumbers = false;
      options.scrollbar = {
        verticalScrollbarSize: 0,
        horizontalScrollbarSize: 0
      };
    }

    return {
      ed: null,
      disaposables: [],
      options
    };
  },
  computed: {
    suggestionStrings() {
      return this.suggestions.map(s => s.label);
    }
  },
  watch: {
    readOnly() {
      this.ed.updateOptions({ readOnly: this.readOnly });
    },
    suggestions(nw) {
      for (const disaposable of this.disaposables) {
        disaposable.dispose();
      }
      this.addSuggestions();
      this.markErrors();
    }
  },
  destroyed() {
    for (const disaposable of this.disaposables) {
      disaposable.dispose();
    }
  },
  mounted() {
    this.ed = monaco.editor.create(this.$refs.code, {
      ...{ ...this.options, ...this.customOptions },
      readOnly: this.readOnly,
      language: this.language,
      value: this.value,
      scrollBeyondLastLine: false,
      scrollbar: {
        alwaysConsumeMouseWheel: this.autoHeight ? false : true
      }
    });

    if (this.suggestions) {
      this.addSuggestions();
    }

    this.ed.addAction({
      id: 'toggle-word-wrap',
      label: 'Toggle Word Wrap',
      precondition: null,
      keybindingContext: null,
      contextMenuGroupId: 'navigation',

      contextMenuOrder: 1.5,
      run: function(ed) {
        this.options.wordWrap = this.options.wordWrap === 'on' ? 'off' : 'on';
        ed.updateOptions({ wordWrap: this.options.wordWrap });
      }
    });
    this.ed.onDidChangeModelContent(this.change);
    if (this.autoHeight) {
      const height = (this.ed._modelData.viewModel.getLineCount() + 1) * 19;
      this.$refs.code.style.height = `${height}px`; // 19 is the line height of default theme.
      this.ed.layout();
    }
    this.markErrors();
  },
  methods: {
    change(_value, event) {
      const data = this.ed.getValue();
      if (this.autoHeight) {
        this.$refs.code.style.height = `${(this.ed._modelData.viewModel.getLineCount() + 1) * 19}px`; // 19 is the line height of default theme.
        this.ed.layout();
      }
      this.$emit('change', data, event);
      this.$emit('input', data);
      this.markErrors();
    },

    markErrors() {
      return; // disable errors

      const _value = this.ed.getValue();
      const bindingRegex = /{(?<binding>.+?)}/gi;
      const matches = [..._value.matchAll(bindingRegex)];
      monaco.editor.setModelMarkers(this.ed.getModel(), 'test', []);

      const markers = [];
      matches.forEach(([match, binding], index) => {
        if (!this.suggestionStrings.includes(binding)) {
          const matched = this.ed.getModel().findMatches(match);
          markers.push(...matched.map(m => ({ ...m.range, severity: monaco.MarkerSeverity.Error, message: 'Missing variable' })));
        }
      });
      monaco.editor.setModelMarkers(this.ed.getModel(), 'test', markers);
    },
    addSuggestions() {
      const modelId = this.ed.getModel().id;
      const suggestions = this.suggestions;
      this.disaposables.push(
        monaco.languages.registerCompletionItemProvider(this.language, {
          triggerCharacters: ['#', '{', '.'],
          provideCompletionItems: function(model, position) {
            if (model.id !== modelId) {
              return [];
            }

            return {
              suggestions: Array.isArray(suggestions)
                ? suggestions.map(s => ({
                    label: s.label,
                    kind: s.kind ?? monaco.languages.CompletionItemKind.Function,
                    documentation: s.documentation,
                    insertText: s.arguments ? `${s.label} "\${2:${s.arguments}}"` : `${s.label}`,
                    insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet
                  }))
                : []
            };
          }
        })
      );
    }
  }
};
</script>

<style scoped>
.root {
  height: 100%;
  display: grid;
  grid-template-rows: max-content max-content;
  grid-gap: 5px;
  align-items: center;

  label {
    font-weight: 500;
    font-size: 0.75rem;
    letter-spacing: 0.025em;
  }

  .text-edit-mode {
    border: 1px solid white;
    border-radius: 2px;
  }

  .code {
    width: 100%;
    height: 100%;
    min-height: 27px;
  }
}
</style>
