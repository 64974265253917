<template>
  <p-edit-configuration v-if="$hasPermission('administration')" />
  <p-not-found v-else />
</template>

<script>
import EditConfiguration from '@/components/llm-configurations/EditModelConfiguration.vue';
import NotFound from '@/components/NotFound.vue';

export default {
  components: {
    'p-edit-configuration': EditConfiguration,
    'p-not-found': NotFound
  }
};
</script>
