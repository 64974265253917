<template>
  <p-processing v-if="$hasPermission('portfolio.read')" />
  <p-not-found v-else />
</template>

<script>
import NotFound from './../components/NotFound.vue';
import Processing from '../components/processing/Processing.vue';

export default {
  components: {
    'p-processing': Processing,
    'p-not-found': NotFound
  }
};
</script>
