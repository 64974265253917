<template>
  <div class="root">
    <label v-if="!!label" :class="{ disabled }"> {{ label }}</label>
    <div v-if="loading" class="progress">
      <MdIcon size="md" name="progress-clock" />
      <label>{{ progress || 'Processing...' }}</label>
    </div>
    <div v-else-if="error.length" class="error">
      <Button class="button" @click="error = ''"> <MdIcon size="sm" name="alert-circle-outline" /> {{ error }} </Button>
    </div>
    <div v-else-if="!selected.length" class="image-variable">
      <FileSelect accept=".png,.jpeg,.jpg,.docx,.pptx,.vsdx,.pdf,.pptx" variant="text" :disabled="disabled" :multiple="true" @change="filesSelected">
        <MdIcon size="md" name="image-area" />Please select image(s)
      </FileSelect>
    </div>
    <div v-else class="preview-area">
      <div class="preview">
        <div v-for="file in selected" :key="file.name" class="images">
          <div v-if="compact" :title="file.name" class="compact" :class="{ disabled }">
            <div class="thumbnail">
              {{ file.name }}
              <span v-if="!disabled && preview"><img :src="file.base64" :alt="file.name" /> </span>
            </div>
            <Button v-if="!disabled" class="button" title="Remove" @click="clear(file)">
              <MdIcon size="md" name="close" />
            </Button>
          </div>
          <div v-else class="image" :class="{ disabled }">
            <div class="thumbnail">
              <img :src="file.base64" :alt="file.name" />
              <span v-if="preview"><img :src="file.base64" :alt="file.name" /> </span>
            </div>
            <Button v-if="!disabled" class="button" title="Remove" @click="clear(file)">
              <MdIcon size="md" name="close" />
            </Button>
          </div>
        </div>
      </div>
      <div>
        <Button v-if="!disabled" class="button" title="Clear All" @click="selected = []"><MdIcon size="md" name="close"/></Button>
      </div>
    </div>
  </div>
</template>

<script>
import JSZip from 'jszip';
import httpClient from '@/utils/httpClient';
import MdIcon from '@/components/common/MdIcon';
import FileSelect from '@/components/common/FileSelect';
import Button from '@/components/common/Button';

async function generateImagesFromPdf(pdf) {
  var pdfFile = new FormData();
  pdfFile.append('file', pdf);
  pdfFile.append('pages', []);
  pdfFile.append('formats', ['png']);

  const zip = await httpClient.post('/api/hub-pdf/convert/toimages', pdfFile, false, { json: false });
  const archive = await JSZip.loadAsync(zip);
  const zipFiles = archive['files'];

  const promises = Object.getOwnPropertyNames(zipFiles).map(async page => {
    const file = zipFiles[page];
    const content = await file.async('base64');
    return {
      name: file.name,
      page: +file.name.split('.')[0].split('_')[1],
      base64: `data:image/png;base64,${content}`
    };
  });

  return await Promise.all(promises);
}

export default {
  components: {
    MdIcon,
    FileSelect,
    Button
  },
  props: {
    value: {
      required: true,
      validator: v => true
    },
    label: {
      type: String,
      default: null
    },
    compact: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    preview: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      selected: this.value || [],
      loading: false,
      error: '',
      progress: ''
    };
  },
  methods: {
    async filesSelected(files) {
      try {
        this.loading = true;
        this.error = '';
        this.progress = '';

        const loadedFiles = [];
        for (const file of files) {
          if (/(jpg)|(jpeg)|(png)$/.test(file.name)) {
            this.progress = `Loading ${file.name}...`;
            const image = await new Promise(async function(resolve, reject) {
              var reader = new FileReader();
              reader.onload = () => {
                resolve({
                  name: file.name,
                  base64: reader.result
                });
              };
              reader.onerror = reject;
              reader.readAsDataURL(file);
            });
            loadedFiles.push(image);
          } else {
            this.progress = `Converting '${file.name}'. It may take a while...`;
            var officeFile = new FormData();
            officeFile.append('file', file);
            const pdf = file.name.endsWith('pdf') ? file : await httpClient.post('/api/converters/office2pdf', officeFile, false, { json: false });

            const images = await generateImagesFromPdf(new File([pdf], 'converted.pdf'));
            loadedFiles.push(...images.sort((a, b) => a.page - b.page));
          }
        }

        this.selected = loadedFiles;
        this.$forceUpdate();

        this.$emit('input', this.selected);
      } catch (error) {
        this.error = error.message || 'Failed to convert one or many files';
      } finally {
        this.loading = false;
        this.progress = '';
      }
    },
    clear(file) {
      const index = this.selected.indexOf(file);
      this.selected.splice(index, 1);
    }
  }
};
</script>

<style lang="scss" scoped>
.root {
  i {
    margin-right: 3px;
  }

  .disabled {
    opacity: 0.3;
  }

  .button {
    padding: 0;
    z-index: 1000;
    cursor: pointer;
    border-radius: 2px;

    i {
      width: 25px;
      height: 25px;
      font-size: 21px;
    }
  }

  label {
    font-weight: 500;
    font-size: 0.75rem;
    letter-spacing: 0.025em;
  }

  .image-variable {
    align-items: center;
    border: 1px solid var(--theme-on-background-dark);
    border-radius: 2px;
    padding: 4px;

    button {
      padding: 0;
    }
  }

  .progress {
    display: flex;
    align-items: center;
    font-style: italic;
    border: 1px solid var(--theme-on-background-dark);
    padding: 4px;
  }

  .error {
    display: flex;
    align-items: center;
    border: 1px solid var(--theme-on-background-dark);
    color: var(--theme-error);
  }

  .preview-area {
    display: flex;
    justify-content: space-between;
    gap: 5px;
    border: 1px solid var(--theme-on-background-dark);
    border-radius: 2px;
    padding: 2px;

    .preview {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;

      div:not(:last-child) {
        margin-bottom: 1px;
      }

      .compact {
        display: flex;
        border: 1px solid white;
        border-radius: 2px;
        align-items: center;
        padding-left: 5px;
        margin-right: 2px;
        user-select: none;

        .button {
          padding: 0;
          cursor: pointer;
          border-radius: 2px;
        }
      }

      .thumbnail {
        position: relative;
        z-index: 0;
        padding: 2px;

        img {
          max-height: 150px;
        }
      }

      .thumbnail:hover {
        background-color: transparent;
        z-index: 250;
      }

      .thumbnail span {
        position: absolute;
        left: -1000px;
        border: 1px dashed var(--theme-on-background-dark);
        visibility: hidden;
      }

      .thumbnail span img {
        border-width: 0;
        max-height: max(50vh, 350px);
      }

      .thumbnail:hover span {
        visibility: visible;
        top: 25px;
        left: -250px;
        z-index: 1000;
        transition-delay: 1s;
      }

      .image {
        display: flex;
        align-items: flex-start;
        position: relative;
        padding: 2px;
        .button {
          position: absolute;
          visibility: hidden;
          right: 0;
          padding: 0;
          z-index: 1000;
          cursor: pointer;
          color: var(--theme-surface);
          border-radius: 2px;
        }
      }
      .image:hover .button {
        visibility: visible;
      }
    }
  }
}
</style>
