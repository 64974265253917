import { render, staticRenderFns } from "./CreateDocument.vue?vue&type=template&id=0fbff38a&scoped=true&"
import script from "./CreateDocument.vue?vue&type=script&lang=js&"
export * from "./CreateDocument.vue?vue&type=script&lang=js&"
import style0 from "./CreateDocument.vue?vue&type=style&index=0&id=0fbff38a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0fbff38a",
  null
  
)

export default component.exports