<template>
  <form :class="{ dirty: $v.$anyDirty }" autocomplete="off" class="create-or-edit-document-form" @submit.prevent="submit">
    <div class="form-row">
      <p-file
        :disabled="!$hasPermission('templates')"
        :label="'Template file'"
        :value="fileTemplate"
        accept=".vstx"
        @input="templateSelected"
      ></p-file>
    </div>
    <div class="form-row">
      <p-text-field v-model="name" :label="'Name'" autocomplete="off" :disabled="true" />
      <div v-if="!$v.name.required" class="error">Template is required</div>
    </div>
    <div>
      <p-checkbox v-model="isPublic" :disabled="!$hasPermission('templates')" :label="'Public'" />
    </div>
    <div class="form-row">
      <p-text-field v-model="description" :disabled="!$hasPermission('templates')" :label="'Description'" autocomplete="off" />
    </div>
    <div class="form-row">
      <p-multiselect
        v-model="tags"
        :disabled="!$hasPermission('templates')"
        :multiple="true"
        :taggable="true"
        label="Tags"
        placeholder=""
        tag-placeholder=""
        @tag="tagAdded"
      ></p-multiselect>
    </div>
    <div v-if="isRequestFailed" class="error">
      Failed to create a new document. Check your input and try again.
    </div>
    <div class="form-row submit">
      <p-button type="button" @click.prevent="$router.back()">Cancel</p-button>
      <p-button v-if="$hasPermission('templates')" color="primary" type="submit" :disabled="isRequestPending || ($v.$anyDirty && $v.$invalid)"
        >Save</p-button
      >
    </div>
  </form>
</template>

<script>
import { mapState } from 'vuex';
import { required } from 'vuelidate/lib/validators';

import TextField from '@/components/common/TextField';
import Button from '@/components/common/Button';
import File from '@/components/common/File';
import Multiselect from '@/components/common/Multiselect';
import Checkbox from '@/components/common/Checkbox';

export default {
  components: {
    'p-text-field': TextField,
    'p-button': Button,
    'p-file': File,
    'p-multiselect': Multiselect,
    'p-checkbox': Checkbox
  },
  props: {
    item: {
      type: Object,
      default: null
    },
    isRequestPending: {
      type: Boolean,
      default: false
    },
    isRequestFailed: {
      type: Boolean,
      default: false
    }
  },
  data() {
    if (this.item) {
      return {
        name: this.item.name,
        ext: this.item.ext,
        description: this.item.description,
        fileTemplate: null,
        isPublic: this.item.isPublic,
        tags: this.item.tags
      };
    }
    return {
      name: '',
      ext: '',
      description: '',
      isPublic: false,
      fileTemplate: null,
      tags: []
    };
  },

  validations: {
    name: {
      required
    }
  },
  methods: {
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      await this.$emit('submit', {
        name: this.name && this.name.trim(),
        ext: this.ext,
        isPublic: this.isPublic,
        description: this.description && this.description.trim(),
        tags: Array.isArray(this.tags) ? this.tags : [],
        fileTemplate: this.fileTemplate
      });

      this.$v.$reset();
    },
    tagAdded(tag) {
      if (!Array.isArray(this.tags)) {
        this.tags = [];
      }

      if (this.tags.includes(tag)) {
        return;
      }
      this.tags.push(tag);
    },
    templateSelected(template) {
      const [name, ...ext] = template.name.split('.');
      this.name = name;
      this.ext = '.' + ext.join('.');
      this.fileTemplate = template;
    }
  }
};
</script>

<style lang="scss" scoped>
.create-or-edit-document-form {
  padding: 2rem;
  max-width: 480px;
  background: var(--theme-surface);
  overflow-y: auto;

  > * {
    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }
    &:last-child {
      display: flex;
      justify-content: flex-end;
    }
  }

  .error {
    font-size: 0.8rem;
    color: var(--theme-error);
    text-align: left;
    padding: 0.25rem 0;
    display: none;
  }
  &.dirty {
    .error {
      display: block;
    }
  }
}
</style>
