import { am } from './utils';

import httpClient from '@/utils/httpClient';
import logger from '@/utils/logger';

export const LOGIN = am('LOGIN');
export const LOGOUT = am('LOGOUT');
export const INITIALIZATION = am('INITIALIZATION');

export const state = {
  isReady: false,
  isRequestPending: false,
  isRequestFailed: false,

  isAuthenticated: false,

  email: null,
  firstName: null,
  lastName: null,

  permissions: null
};

export function decodeJwtToken(token) {
  const [, payload] = token.split('.');
  const { iss, sub, email, firstName, lastName, permissions } = JSON.parse(window.atob(payload));
  return {
    iss,
    sub,
    email,
    firstName,
    lastName,
    permissions
  };
}

const getters = {};

export const actions = {
  async initialize({ commit }) {
    commit(LOGIN.STARTED);
    try {
      const token = localStorage.getItem('PATENT_HUB_AUTH_TOKEN', token);
      if (token) {
        const token = await httpClient.post(`/api/auth/refresh`);
        localStorage.setItem('PATENT_HUB_AUTH_TOKEN', token);

        const { email, firstName, lastName, permissions } = decodeJwtToken(token);
        commit(LOGIN.COMPLETED, { email, firstName, lastName, permissions });
      } else {
        commit(LOGOUT.COMPLETED);
      }
    } catch (e) {
      commit(LOGOUT.COMPLETED);
    } finally {
      commit(INITIALIZATION.COMPLETED);
    }
  },
  async login({ commit }, { email, password }) {
    commit(LOGIN.STARTED);
    try {
      const token = await httpClient.post(`/api/auth/login`, { email, password });
      localStorage.setItem('PATENT_HUB_AUTH_TOKEN', token);

      const { firstName, lastName, permissions } = decodeJwtToken(token);
      commit(LOGIN.COMPLETED, { email, firstName, lastName, permissions });
    } catch (e) {
      commit(LOGIN.FAILED);
      logger.error(e);
    }
  },
  async logout({ commit }) {
    try {
      localStorage.removeItem('PATENT_HUB_AUTH_TOKEN');
      commit(LOGOUT.COMPLETED);
    } catch (e) {
      logger.error(e);
    }
  }
};

export const mutations = {
  [LOGIN.STARTED](state) {
    state.isRequestPending = true;
    state.isRequestFailed = false;
    state.isAuthenticated = false;
    state.email = null;
    state.firstName = null;
    state.lastName = null;
    state.permissions = null;
  },
  [LOGIN.FAILED](state) {
    state.isRequestPending = false;
    state.isRequestFailed = true;
  },
  [LOGIN.COMPLETED](state, { email, firstName, lastName, permissions }) {
    state.isRequestPending = false;
    state.isAuthenticated = true;
    state.email = email;
    state.firstName = firstName;
    state.lastName = lastName;
    state.permissions = permissions;
  },
  [LOGOUT.COMPLETED](state) {
    state.isRequestPending = false;
    state.isAuthenticated = false;
    state.email = null;
    state.firstName = null;
    state.lastName = null;
    state.permissions = null;
  },
  [INITIALIZATION.COMPLETED](state) {
    state.isReady = true;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
