<template>
  <div class="root">
    <label>Document</label>
    <div v-if="value" class="context">
      <div class="short">
        <Button class="button" @click="expanded = !expanded">
          <MdIcon size="md" :name="expanded ? 'chevron-up' : 'chevron-down'" />
        </Button>
        <span>{{ value.name }}</span>
        <Button class="button" @click="clear">
          <MdIcon size="md" name="close" />
        </Button>
      </div>
      <JsonView v-if="expanded" :value="value" class="json" />
    </div>
    <div v-else class="context">
      <FileSelect accept=".docx" variant="text" @change="contextFileSelected">
        <MdIcon size="md" name="file-word" style="margin-right: 3px" />Select Context Document
      </FileSelect>
    </div>
  </div>
</template>
<script>
import httpClient from '../../utils/httpClient';
import MdIcon from '@/components/common/MdIcon';
import FileSelect from '@/components/common/FileSelect';
import Button from '@/components/common/Button';
import JsonView from '@/components/common/JsonView';

export default {
  components: {
    Button,
    MdIcon,
    FileSelect,
    JsonView
  },
  props: {
    value: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      selected: this.value,
      expanded: false
    };
  },
  methods: {
    async contextFileSelected([file]) {
      const reader = new FileReader();

      reader.addEventListener(
        'load',
        async () => {
          const lock = this.$lock();
          try {
            const document = reader.result.split(',')[1];
            const { claims } = await httpClient.post(`/api/nlp/api/v1/parser/parse`, {
              document,
              sections: ['claims']
            });

            const data = await httpClient.post(`/api/nlp/api/v1/generator/contextObject`, {
              templates: [
                {
                  name: file.name,
                  body: 'test',
                  params: [],
                  claims: claims.claims.map(({ claimNumber }) => ({
                    number: claimNumber,
                    params: []
                  }))
                }
              ],
              document
            });

            this.selected = { name: file.name, ...data[file.name] };
            this.$emit('input', this.selected);
          } catch (e) {
            this.$toast.error({
              title: 'Failed to generate Context Object',
              message: `Please, try again later or contact our development team.`
            });
          } finally {
            lock.release();
          }
        },
        false
      );

      reader.readAsDataURL(file);
    },
    clear() {
      this.selected = null;
      this.$emit('input', this.selected);
    }
  }
};
</script>
<style lang="scss" scoped>
.root {
  label {
    font-weight: 500;
    font-size: 0.75rem;
    letter-spacing: 0.025em;
    margin-bottom: 3px;
  }

  span {
    font-weight: 500;
    font-size: 0.9rem;
    letter-spacing: 0.025em;
  }

  .button {
    padding: 0;
    cursor: pointer;
    :hover {
      background-color: var(--theme-on-surface);
      color: var(--theme-surface);
      border-radius: 2px;
    }

    i {
      width: 25px;
      height: 25px;
      font-size: 21px;
    }
  }

  .context {
    border: 1px solid var(--theme-on-background-dark);
    border-radius: 2px;
    padding: 2px;
    min-height: 30px;
    display: grid;
    align-items: center;

    .short {
      display: grid;
      align-items: center;
      gap: 5px;
      grid-template-columns: max-content 1fr max-content;
    }

    .json {
      max-height: 400px;
    }
  }
}
</style>
