<template>
  <section v-if="getModelPending">
    <p-loading />
  </section>
  <section v-else-if="getModelFailed">
    <p-not-found />
  </section>
  <section v-else-if="model" class="edit-model">
    <p-edit-model :item="model" @submit="edit" />
    <div>
      <p-button v-if="$hasPermission('administration')" color="secondary" type="submit" @click="deleteModel">DELETE</p-button>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import EditForm from './_CreateOrEdit';
import Loading from '@/components/common/Loading';
import Button from '@/components/common/Button';
import NotFound from '@/components/NotFound.vue';

export default {
  components: {
    'p-edit-model': EditForm,
    'p-loading': Loading,
    'p-button': Button,
    'p-not-found': NotFound
  },
  data() {
    return {
      model: null
    };
  },
  computed: {
    ...mapState({
      getModelFailed: s => s.nlp.getModelFailed,
      getModelPending: s => s.nlp.getModelPending
    })
  },
  async created() {
    this.model = await this.$store.dispatch('nlp/getModel', this.$route.params.id);
  },
  methods: {
    async edit(model) {
      try {
        await this.$store.dispatch('nlp/updateModel', model);
        this.$router.push({ path: `/administration` });

        this.$toast.success({
          title: 'Updated',
          message: `Model '${model.name}' succesfully updated`
        });
      } catch (error) {
        const { message } = JSON.parse(await error.response.text());
        this.$toast.error({
          title: 'Failed to update model',
          message: message
        });
      }
    },
    async deleteModel() {
      const confirmResult = await this.$confirm({
        title: 'Delete Model?',
        message: `Are you sure you want to delete model '${this.model.name}'?\n\nAll prompts using this model will stop working.`,
        confirm: 'Delete'
      });

      if (!confirmResult) {
        return;
      }
      try {
        await this.$store.dispatch('nlp/deleteModel', this.model);
        this.$toast.success({
          title: 'Deleted',
          message: `Model '${this.model.name}' succesfully deleted`
        });
        this.$router.push({ path: `/administration` });
      } catch (error) {
        const { message } = JSON.parse(await error.response.text());
        this.$toast.error({
          title: 'Failed to delete model',
          message: message
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.edit-model {
  display: grid;
  grid-gap: 0.5rem;
  grid-template-rows: minmax(0, 1fr);
  grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);
  width: 100%;
  height: 100%;
  padding: 0 0.5rem 0 1rem;
}
</style>
