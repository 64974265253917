<template>
  <div class="file-input">
    <label v-if="!!label" :for="id">{{ label }}</label>
    <div class="wrapper" :disabled="disabled" @click="openDialog">
      {{ title || placeholder }}
      <p-button color="" variant="contained" type="button" :disabled="disabled">
        &#x2912;
      </p-button>
    </div>
  </div>
</template>

<script>
import Button from './Button';

export default {
  components: {
    'p-button': Button
  },
  props: {
    id: {
      type: String,
      default: () => Math.random().toString()
    },
    value: {
      type: File,
      default: null
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    accept: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      files: []
    };
  },
  computed: {
    title() {
      if (!this.files || !this.files.length) {
        return '';
      }

      return `${this.files[0].name} (${this.filesize(this.files[0].size)})`;
    }
  },
  watch: {
    value() {
      if (this.value === null) {
        this.files = [];
      }
    }
  },
  methods: {
    openDialog() {
      if (this.disabled) {
        return;
      }
      for (const el of this.$el.querySelectorAll(`input[type='file']`)) {
        el.removeEventListener('change', input);
        el.parentElement.removeChild(el);
      }

      const input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.setAttribute('accept', this.accept);
      input.style.display = 'none';
      input.addEventListener('input', this.input);

      this.$el.appendChild(input);
      input.click();
    },
    input(e) {
      this.files = e.target.files;

      if (e.target.files && e.target.files.length) {
        this.$emit('input', e.target.files[0]);
        this.$emit('change', e.target.files[0]);
      }
    },
    filesize(size) {
      const i = Math.floor(Math.log(size) / Math.log(1024));
      return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['bytes', 'kb', 'mb', 'gb', 'tb'][i];
    }
  }
};
</script>

<style lang="scss" scoped>
.file-input {
  display: grid;
  grid-template-rows: max-content max-content;
  grid-gap: 5px;

  > * {
    min-width: 0;
    min-height: 0;
  }
  position: relative;

  label {
    font-weight: 500;
    font-size: 0.75rem;
    letter-spacing: 0.025em;
  }

  .wrapper {
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 8px 24px 8px 12px;
    font-size: 0.85rem;
    font-weight: 400;
    border-radius: 2px;
    border: 1px solid var(--theme-on-background-dark);
    color: var(--theme-on-background);
    background: var(--theme-background);
    outline: none;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    height: 16px;
    position: relative;

    &:not([disabled]) {
      cursor: pointer;
      &:hover {
        border-color: var(--theme-on-background);
        color: var(--theme-on-background);
      }
    }

    button {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  &.disabled {
    opacity: 0.5;
  }
}
</style>
