<template>
  <div class="content">
    <Code
      v-model="localItem.query"
      :read-only="!$hasPermission('prompts.write') || isPromptStreaming"
      :suggestions="suggestions"
      :language="'pgsql'"
      class="editor"
      :auto-height="true"
      @input="onChange"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Code from '@/components/common/Code';

export default {
  components: {
    Code
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    suggestions: {
      type: Array,
      required: true
    },
    variables: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      localItem: {
        ...this.item
      }
    };
  },
  computed: {
    ...mapState({
      isPromptStreaming: s => s.prompts.sample.isRequestPending
    })
  },
  methods: {
    onChange() {
      this.$emit('change', { ...this.localItem });
    }
  }
};
</script>

<style scoped lang="scss">
.content {
  .editor {
    height: 200px;
  }
}
</style>
