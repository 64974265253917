<template>
  <p-create v-if="$hasPermission('templates')" />
  <p-not-found v-else />
</template>
<script>
import Create from './../components/documents/word/CreateDocument.vue';
import NotFound from './../components/NotFound.vue';

export default {
  components: {
    'p-not-found': NotFound,
    'p-create': Create
  }
};
</script>
