<template>
  <section v-if="isGetRequestPending" class="change-password">
    <p-loading />
  </section>
  <section v-else class="change-password">
    <header>
      <h1>Change Password</h1>
    </header>
    <form :class="{ dirty: $v.$anyDirty }" autocomplete="off" @submit.prevent="submit">
      <div class="form-row">
        <p-text-field v-model="email" :label="'Email'" autocomplete="off" :disabled="true" />
      </div>
      <div class="form-row">
        <p-text-field v-model="password" :label="'Password'" type="password" autocomplete="new-password" :disabled="!$hasPermission('users.write')" />
        <div v-if="!$v.password.required" class="error">Password is required</div>
        <div v-if="!$v.password.minLength" class="error">Password must be at least {{ $v.password.$params.minLength.min }} characters long.</div>
      </div>
      <div v-if="isUpdateRequestFailed" class="error">
        Failed to change password for user. Check your input and try again. If an error still occurs, please, contact our developers.
      </div>
      <div class="form-row submit">
        <p-button type="button" @click.prevent="$router.back()">Cancel</p-button>
        <p-button
          v-if="$hasPermission('users.write')"
          color="primary"
          type="submit"
          :disabled="isUpdateRequestPending || ($v.$anyDirty && $v.$invalid)"
          >Update</p-button
        >
      </div>
    </form>

    <footer></footer>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import { required, minLength } from 'vuelidate/lib/validators';

import Loading from '@/components/common/Loading';
import TextField from '@/components/common/TextField';
import Button from '@/components/common/Button';

export default {
  components: {
    'p-loading': Loading,
    'p-text-field': TextField,
    'p-button': Button
  },
  data() {
    return {
      email: '',
      password: ''
    };
  },
  computed: {
    ...mapState({
      item: s => s.users.item,
      isGetRequestPending: s => s.users.isGetRequestPending,
      isUpdateRequestPending: s => s.users.isUpdateRequestPending,
      isUpdateRequestFailed: s => s.users.isUpdateRequestFailed
    })
  },
  validations: {
    password: {
      required,
      minLength: minLength(6)
    }
  },
  watch: {
    '$route.params': {
      async handler() {
        await this.$store.dispatch('users/getById', this.$route.params.id);
        this.email = this.item.email;
        this.password = '';
      },
      immediate: true
    }
  },
  methods: {
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      await this.$store.dispatch('users/update', {
        id: this.$route.params.id,
        password: this.password
      });
      if (!this.isUpdateRequestFailed) {
        this.$router.push({ path: `/people` });
      }
    },
    back() {
      this.$router.back();
    }
  }
};
</script>

<style lang="scss" scoped>
.change-password {
  width: 100%;
  header {
    width: 100%;
    padding: 1rem 2rem;
    border-bottom: 1px solid var(--theme-highlight);
    box-sizing: border-box;

    a {
      font-size: 0.8rem;
    }
  }

  h1 {
    color: var(--theme-on-surface);
    font-size: 1.5rem;
    font-weight: 700;
  }
  form {
    padding: 2rem;
    max-width: 480px;

    > * {
      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }
      &:last-child {
        display: flex;
        justify-content: flex-end;
      }
    }

    .error {
      font-size: 0.8rem;
      color: var(--theme-error);
      text-align: left;
      padding: 0.25rem 0;
      display: none;
    }
    &.dirty {
      .error {
        display: block;
      }
    }
  }
}
</style>
