<template>
  <div class="inventions-picker">
    <label>Invention</label>
    <div v-if="selected" class="invention">
      <div class="selected-invention">
        <Button class="button" @click="expanded = !expanded">
          <MdIcon size="md" :name="expanded ? 'chevron-up' : 'chevron-down'" />
        </Button>
        <div class="invention-info">
          <label>{{ selected.title }}</label>
          <span>{{ selected.references.join('; ') }}</span>
        </div>
        <div>
          <Button class="button" @click="select(null)">
            <MdIcon name="close" />
          </Button>
        </div>
      </div>
      <JsonView v-if="expanded" :value="selected" class="json" />
    </div>
    <TextField
      v-else
      v-model="searchText"
      placeholder="Search for invention"
      @input="onInput"
      @blur="isDropdownVisible = false"
      @focus="isDropdownVisible = true"
    />
    <Transition name="dropdown">
      <div v-if="isDropdownVisible && inventions.length" class="search-result">
        <ul class="search-list">
          <li v-for="item of inventions" :key="item.id" class="search-list-item" @click="select(item)">
            <label>{{ item.title }}</label>
            <div>{{ item.references }}</div>
          </li>
        </ul>
      </div>
    </Transition>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import TextField from '@/components/common/TextField';
import Button from '@/components/common/Button';
import MdIcon from '@/components/common/MdIcon';
import JsonView from '@/components/common/JsonView';

export default {
  components: {
    TextField,
    Button,
    MdIcon,
    JsonView
  },
  props: {
    value: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      debounce: null,
      searchText: '',
      selected: this.value,
      isDropdownVisible: false,
      inventions: [],
      expanded: false
    };
  },
  computed: {
    ...mapState({
      isRequestPending: s => s.inventions.isGetCollectionRequestPending,
      isRequestFailed: s => s.inventions.isGetCollectionRequestFailed
    })
  },
  methods: {
    onInput(e) {
      if (this.debounce) {
        clearTimeout(this.debounce);
        this.debounce = null;
      }
      this.debounce = setTimeout(this.performSearch, 300);
    },
    select(invention) {
      if (invention === null) {
        this.searchText = '';
      }
      this.selected = invention;
      this.$emit('input', this.selected);
    },
    async performSearch() {
      if (this.searchText.length) {
        const text = encodeURIComponent(this.searchText);
        try {
          this.inventions = await this.$store.dispatch('inventions/getCollection', { text });
        } catch (e) {
          // do nothing
        }
      } else {
        this.inventions = [];
      }
    }
  }
};
</script>
<style scoped lang="scss">
.inventions-picker {
  position: relative;

  label {
    font-weight: 500;
    font-size: 0.75rem;
    letter-spacing: 0.025em;
    margin-bottom: 3px;
  }

  .button {
    padding: 0;
    cursor: pointer;
    :hover {
      background-color: var(--theme-on-surface);
      color: var(--theme-surface);
      border-radius: 2px;
    }

    i {
      width: 25px;
      height: 25px;
      font-size: 21px;
    }
  }

  .json {
    max-height: 400px;
  }

  .invention {
    border: 1px solid var(--theme-on-background-dark);
    border-radius: 2px;
    padding: 2px;
  }

  .selected-invention {
    position: relative;
    display: grid;
    align-items: center;
    grid-template-columns: max-content 1fr max-content;

    .invention-info {
      display: grid;
      grid-template-rows: min-content min-content;

      label {
        font-size: 0.85rem;
      }

      span {
        font-size: 0.7rem;
      }
    }
  }

  .dropdown-enter-active,
  .dropdown-leave-active {
    transition: all 0.2s;
  }
  .dropdown-enter,
  .dropdown-leave-to {
    opacity: 0;
    transform: translateY(15px);
  }

  .search-result {
    position: absolute;
    z-index: 100;
    max-height: 400px;
    overflow: hidden;
    top: 100%;
    border-radius: 2px;
    width: 100%;
    background: var(--theme-surface);
    .search-list {
      padding: 0;
      margin: 0;
      list-style: none;
      overflow-y: scroll;
      height: 100%;
      width: 100%;
    }
    .search-list-item-meta {
      padding: 1rem 0.75rem;

      label {
        font-weight: 600;
        font-size: 0.65rem;
      }

      &.error {
        label {
          color: var(--theme-error);
        }
      }
    }
    .search-list-item {
      padding: 0.5rem 1rem;
      display: grid;
      grid-template-columns: minmax(0, 1fr) max-content;
      grid-template-rows: max-content max-content;
      background: var(--theme-header-surface);
      cursor: pointer;
      label {
        font-weight: 600;

        grid-column: 1/2;
        grid-row: 1/2;
        cursor: pointer;
      }

      div {
        font-size: 0.75rem;
        grid-column: 1/2;
        grid-row: 2/3;
      }

      button {
        grid-column: 2/3;
        grid-row: 1/3;
        align-self: center;
      }

      &:hover,
      &.selected {
        background-color: var(--theme-primary);
      }
    }
  }
}
</style>
