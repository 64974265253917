export function getDuplicateKey(data) {
  const keysSet = new Set();

  for (let i = 0; i < data.length; i++) {
    const key = data[i].key;

    if (keysSet.has(key)) {
      return key;
    }

    keysSet.add(key);
  }

  return null;
}