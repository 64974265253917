<template>
  <section class="applications">
    <ul class="application-list">
      <li class="application-list-item">
        <img :src="images.appcrawler" />
        <div>
          <h2>Application Crawler</h2>
          <div>Bulk upload patent applications (having MS Word and Visio files) into Patent Searchr from a local or network folder.</div>
        </div>
        <div></div>
        <div class="application-list-item-action-list">
          <a href="/tools/app-crawler/setup.exe" target="_blank">download</a>
        </div>
      </li>
    </ul>
  </section>
</template>

<script>
export default {
  data() {
    return {
      images: {
        appcrawler: require('./../assets/appcrawler.png')
      }
    };
  }
};
</script>

<style lang="scss" scoped>
.applications {
  width: 100%;
  height: 100%;
}
.application-list {
  margin: 0 0.5rem;
  list-style: none;
  padding: 0;
  max-width: 768px;

  .application-list-item {
    display: grid;
    background: var(--theme-surface);
    grid-template-columns: 250px minmax(0, 1fr);
    grid-gap: 2rem;
    padding: 1rem;
    color: var(--theme-on-surface-accent);

    h2 {
      font-size: 1.2rem;
      font-weight: 700;
      margin-bottom: 1rem;
      color: var(--theme-secondary);
    }

    img {
      max-width: 100%;
    }

    .application-list-item-action-list {
      display: flex;
      justify-content: flex-end;

      text-transform: uppercase;
    }

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }
}
</style>
