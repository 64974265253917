import { factory } from './utils';

import httpClient, { jsonObjectToFormData } from '@/utils/httpClient';

export default {
  namespaced: true,
  ...factory({
    actions: {
      async scriptExists({}, email) {
        try {
          return (await httpClient.get(`/api/auth/bots/${email}/script/exists`))?.exists;
        } catch (error) {
          return false;
        }
      },
      async getScript({}, email) {
        try {
          const response = await httpClient.get(`/api/auth/bots/${email}/script`);
          if (!response) {
            return null;
          }
          return new Blob([response]);
        } catch (error) {}
      },
      async uploadScript({}, { email, script }) {
        await httpClient.request(`/api/auth/bots/${email}/script`, {
          json: false,
          method: 'POST',
          body: jsonObjectToFormData({
            file: script
          })
        });
      },
      async deleteScript({}, email) {
        try {
          return await httpClient.delete(`/api/auth/bots/${email}/script`);
        } catch (error) {
          return false;
        }
      }
    }
  })('bots', {
    async getById(id) {
      return httpClient.get(`/api/auth/bots/${id}/`);
    },
    async getCollection() {
      return httpClient.get('/api/auth/bots/');
    },
    async create(data) {
      return httpClient.post('/api/auth/users/', data);
    },
    async update(id, data) {
      return httpClient.patch(`/api/auth/users/${id}`, data);
    },
    async delete(id) {
      return httpClient.delete(`/api/auth/users/${id}`);
    }
  })
};
