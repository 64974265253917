<template>
  <Multiselect
    :value="value"
    :disabled="disabled"
    label="Tags"
    :options="options"
    :multiple="true"
    :taggable="true"
    @input="($e) => $emit('input', $e)"
  />
</template>
<script>
import Multiselect from '@/components/common/Multiselect';

export default {
  components: {
    Multiselect
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean
    }
  },
  data() {
    return {
      options: []
    };
  },
  async created() {
    const options = await this.$store.dispatch('milestones/getTemplateTags');
    this.options = options;
  }
};
</script>
