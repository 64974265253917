import { cruduex } from './utils';

import httpClient, { jsonObjectToFormData } from '@/utils/httpClient';

export default {
  namespaced: true,
  ...cruduex('specification', {
    async getById(id) {
      return httpClient.get(`/api/drawings/${id}`);
    },
    async getCollection() {
      return httpClient.get('/api/drawings/');
    },
    async create(data) {
      return httpClient.request('/api/drawings/', {
        json: false,
        method: 'POST',
        body: jsonObjectToFormData(data)
      });
    },
    async update(id, data) {
      return httpClient.request(`/api/drawings/${id}`, {
        json: false,
        method: 'PATCH',
        body: jsonObjectToFormData(data)
      });
    },
    async delete(id) {
      return httpClient.delete(`/api/drawings/${id}`);
    }
  })
};
