import Lock from './../components/common/Lock';

export default {
  install(Vue) {
    Vue.prototype.$lock = () => {
      var ComponentClass = Vue.extend(Lock);
      var instance = new ComponentClass({});
      instance.$mount();
      document.body.appendChild(instance.$el);

      const release = () => {
        document.body.removeChild(instance.$el);
        instance.$destroy();
      };

      return {
        release
      };
    };
  }
};
