<template>
  <form class="schedule-form" @submit.prevent.stop="submit">
    <div>
      <p-text-field v-model="body" :disabled="!$hasPermission('portfolio')" :multiline="true" :rows="8" />
    </div>
    <div v-if="isCreateRequestFailed" class="error">Failed to save schedule application(s). Check your input and try again.</div>
    <div>
      <p-button v-if="$hasPermission('portfolio')" color="primary" type="submit" :disabled="isCreateRequestPending || !isValid">Schedule</p-button>
    </div>
    <div class="schedule-help">
      <div v-if="applications.length"><label>* About to schedule:</label> {{ applications.join(', ') }}</div>
    </div>
  </form>
</template>

<script>
import { mapState } from 'vuex';

import TextField from '@/components/common/TextField';
import Button from '@/components/common/Button';

export default {
  components: {
    'p-text-field': TextField,
    'p-button': Button
  },
  data() {
    return {
      body: ''
    };
  },
  computed: {
    ...mapState({
      isCreateRequestPending: s => s.processing.isCreateRequestPending,
      isCreateRequestFailed: s => s.processing.isCreateRequestFailed
    }),
    isValid() {
      return this.applications.length > 0;
    },
    applications() {
      const collection = this.body && this.body.match(new RegExp(/(PCT\w{2}\d{2,4}\d{5,6})|(\d{6,})/, 'gmi'));
      return Array.from(new Set(collection));
    }
  },
  methods: {
    async submit() {
      try {
        if (!this.isValid) {
          return;
        }

        await this.$store.dispatch('processing/create', { applications: this.applications });

        this.$toast.success({
          title: 'Schedule completed',
          message: `${this.applications.length} application(s) were scheduled for processing.`
        });
        this.body = '';
      } catch (e) {
        this.$toast.error({
          title: 'Schedule failed',
          message: `Please, try again later or contact our development team.`
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.schedule-form {
  > * {
    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }

    &:last-child {
      padding: 0 0.5rem;
    }
  }

  .schedule-help {
    font-size: 0.8rem;
    font-weight: 400;
    color: var(--theme-on-surface-accent);
    label {
      font-weight: 700;
    }
  }
}
</style>
