<template>
  <section v-if="!!help" class="specification-help">
    <nav>
      <ul class="specification-help-nav-list">
        <li class="specification-help-nav-list-item" :class="{ active: current === 'functions' }" @click="current = 'functions'">Functions</li>
        <li class="specification-help-nav-list-item" :class="{ active: current === 'context' }" @click="current = 'context'">Context</li>
      </ul>
    </nav>
    <section v-if="current === 'functions'" class="function-list-wrapper">
      <div class="specification-help-search">
        <p-text-field v-model="search" placeholder="Search by Name or Description"></p-text-field>
      </div>
      <ul class="function-list">
        <li v-for="f of functions" :key="f.name" class="function-list-item">
          <!-- eslint-disable-next-line vue/no-v-html -->
          <h4 v-html="highlight(f.name, search)"></h4>
          <div>
            <!-- eslint-disable-next-line vue/no-v-html -->
            <small v-html="highlight(f.description, search)"></small>
          </div>
          <div v-if="f.requirements"><label>Requires:</label> {{ f.requirements }}</div>
          <div><label>Arguments:</label> {{ f.arguments }}</div>

          <div>
            <label>Example:</label>
            <pre>{{ f.example }}</pre>
          </div>
        </li>
      </ul>
    </section>
    <section v-if="current === 'context'">
      <pre class="specification-context-object">{{ help.contextObject }}</pre>
    </section>
  </section>
  <section v-else class="specification-help">
    <p-loading></p-loading>
  </section>
</template>

<script>
import Loading from './../common/Loading';
import TextField from './../common/TextField';

import highlight from '@/filters/highlight';

export default {
  components: {
    'p-loading': Loading,
    'p-text-field': TextField
  },
  props: {
    help: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      search: '',
      current: 'functions'
    };
  },
  computed: {
    functions() {
      const searchText = this.search;
      if (!this.help || !Array.isArray(this.help.helperFunctions)) {
        return [];
      }

      if (!searchText || !searchText.length) {
        return this.help.helperFunctions;
      }

      return this.help.helperFunctions.filter(f => this.match(f, searchText).length);
    }
  },
  methods: {
    highlight,
    match(s, searchText) {
      if (!searchText || !searchText.length) {
        return [];
      }

      const collection = [];
      if (s.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1) {
        collection.push('name');
      }
      if (s.description.toLowerCase().indexOf(searchText.toLowerCase()) > -1) {
        collection.push('description');
      }

      return collection;
    }
  }
};
</script>

<style lang="scss" scoped>
.specification-help {
  height: 100%;
  width: 100%;
  overflow: hidden;
  padding: 1.25rem 0.75rem;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: max-content minmax(0, 1fr);
  grid-template-rows: minmax(0, 1fr);
  grid-gap: 1rem;

  .specification-help-nav-list {
    padding: 0.5rem;
    .specification-help-nav-list-item {
      text-transform: uppercase;
      font-size: 0.85rem;
      font-weight: 500;
      padding: 0.05rem 0.5rem;
      cursor: pointer;
      margin-bottom: 1rem;
      color: var(--theme-on-background-accent);
      &.active {
        color: var(--theme-on-background);
      }
    }
  }

  .function-list-wrapper {
    height: 100%;
    width: 100%;
    display: grid;
    grid-gap: 1rem;
    grid-template-rows: max-content minmax(0, 1fr);
    grid-template-columns: minmax(0, 1fr);
  }
  .function-list {
    list-style: circle;
    padding: 0.5rem 1rem 1rem 2rem;
    overflow-y: scroll;
    overflow-x: auto;
  }

  .function-list-item {
    font-size: 0.85rem;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    > div {
      margin-bottom: 0.25rem;
    }
  }

  .specification-context-object {
    height: 100%;
    width: 100%;
    overflow-y: scroll;
  }

  section {
    box-sizing: border-box;
  }

  h4 {
    font-weight: 600;
    font-size: 0.9rem;
    color: var(--theme-on-background-accent);
  }

  small {
    font-style: italic;
    font-size: 0.75rem;
  }
  label {
    font-weight: 600;
  }
  pre {
    background: var(--theme-background);
    padding: 0.5rem 0.25rem;
    font-family: monospace;
    font-size: 0.9rem;
    white-space: pre-wrap;
  }
}
</style>
