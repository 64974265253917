<template>
  <div class="filepicker-wrapper">
    <Multiselect
      label="Value"
      :value="value"
      :multiple="multiple"

      :options="availableContracts"
      @tag="$emit('input', $event)"
      @input="$emit('input', $event)"
    />
    <Multiselect label="Search" :value="search" :multiple="true" :taggable="true" @input="$emit('searchChanged', $event)" />
    <Multiselect
      label="Extensions"
      placeholder="List of extensions for files"
      :value="ext"
      :multiple="true"
      :taggable="true"
      @input="$emit('extChanged', $event)"
    />
    <Checkbox label="multiple" :value="multiple" @input="$emit('multipleChanged')" />
  </div>
</template>

<script>
import Multiselect from '@/components/common/Multiselect';
import Checkbox from '@/components/common/Checkbox';
import { mapVariablesFromContracts } from './utils';
import { mapState } from 'vuex';

export default {
  components: {
    Multiselect,
    Checkbox
  },
  props: {
    multiple: {
      type: Boolean,
      default: false
    },
    ext: {
      type: Array,
      default: null
    },
    value: {
      type: Array,
      default: null
    },
    search: {
      type: String,
      default: null
    },
    additionalOptions: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapState({
      contracts: s => s.milestones.contracts
    }),
    availableContracts: function() {
      if (!this.contracts.eventSources) {
        return [];
      }
      const options = mapVariablesFromContracts(this.contracts, this.source, this.code, 'file', undefined, this.excludeContracts);
      return [...options, ...this.additionalOptions];
    }
  }
};
</script>
<style lang="scss" scoped>
.filepicker-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 5px;
}
</style>
