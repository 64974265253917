import { cruduex, am } from './utils';

import httpClient from '@/utils/httpClient';

const GET_PERMISSION_COLLECTION = am(`GET_PERMISSION_COLLECTION`);
const GET_SETTINGS = am('GET_SETTINGS');
const UPDATE_SETTINGS = am('UPDATE_SETTINGS');
const DELETE_SETTINGS = am('DELETE_SETTINGS');

export default {
  namespaced: true,
  ...cruduex(
    'users',
    {
      async getById(id) {
        const collection = await this.getCollection();

        return collection.data.find(item => item.id === id);
      },
      async getCollection() {
        const data = await httpClient.get(`/api/auth/users`);

        return {
          data
        };
      },
      async create(obj) {
        return httpClient.post(`/api/auth/users`, obj);
      },
      async update(id, obj) {
        return httpClient.patch(`/api/auth/users/${id}`, obj);
      },
      async delete(id) {
        return httpClient.delete(`/api/auth/users/${id}`);
      }
    },
    {
      permissions: {
        namespaced: true,
        state: {
          isRequestPending: false,
          isRequestFailed: false,
          collection: []
        },
        mutations: {
          [GET_PERMISSION_COLLECTION.STARTED](state) {
            state.isRequestPending = false;
            state.isRequestFailed = false;
            state.collection = [];
          },
          [GET_PERMISSION_COLLECTION.FAILED](state) {
            state.isRequestPending = false;
            state.isRequestFailed = true;
          },
          [GET_PERMISSION_COLLECTION.COMPLETED](state, { collection }) {
            state.isRequestPending = false;
            state.collection = collection;
          }
        },
        actions: {
          async getCollection({ commit }) {
            try {
              commit(GET_PERMISSION_COLLECTION.STARTED);
              const collection = await httpClient.get(`/api/auth/permissions/list`);

              commit(GET_PERMISSION_COLLECTION.COMPLETED, { collection });
            } catch (e) {
              commit(GET_PERMISSION_COLLECTION.FAILED);
            }
          }
        }
      },
      settings: {
        namespaced: true,
        state: {
          collection: null,
          isGetRequestPending: false,
          isUpdateRequestPending: false,
          isUpdateRequestFailed: false,
          isDeleteRequestPending: false,
          isDeleteRequestFailed: false
        },
        mutations: {
          [GET_SETTINGS.STARTED](state) {
            state.isGetRequestPending = true;
          },
          [GET_SETTINGS.COMPLETED](state, payload) {
            state.isGetRequestPending = false;
            state.collection = payload;
          },
          [GET_SETTINGS.FAILED](state) {
            state.isGetRequestPending = false;
          },
          [UPDATE_SETTINGS.STARTED](state) {
            state.isUpdateRequestPending = true;
            state.isUpdateRequestFailed = false;
          },
          [UPDATE_SETTINGS.COMPLETED](state) {
            state.isUpdateRequestPending = false;
          },
          [UPDATE_SETTINGS.FAILED](state) {
            state.isUpdateRequestPending = false;
            state.isUpdateRequestFailed = true;
          },
          [DELETE_SETTINGS.STARTED](state) {
            state.isDeleteRequestPending = true;
          },
          [DELETE_SETTINGS.COMPLETED](state) {
            state.isDeleteRequestPending = false;
          },
          [DELETE_SETTINGS.FAILED](state) {
            state.isDeleteRequestPending = false;
            state.isDeleteRequestFailed = true;
          }
        },
        actions: {
          async get({ commit }, { owner }) {
            try {
              commit(GET_SETTINGS.STARTED);
              const response = await httpClient.get(`/api/settings/users/${owner}`);
              commit(GET_SETTINGS.COMPLETED, response);
            } catch (error) {
              commit(GET_SETTINGS.FAILED);
              throw new Error('Fetch failed');
            }
          },
          async update({ commit }, payload) {
            try {
              commit(UPDATE_SETTINGS.STARTED);
              await httpClient.put(`/api/settings/users/${payload.key}`, payload);
              commit(UPDATE_SETTINGS.COMPLETED);
            } catch (error) {
              commit(UPDATE_SETTINGS.FAILED);
              throw new Error('Update failed');
            }
          },
          async delete({ commit }, payload) {
            try {
              commit(DELETE_SETTINGS.STARTED);
              await httpClient.delete(`/api/settings/${payload.key}`, { owner: payload.owner });
              commit(DELETE_SETTINGS.COMPLETED);
            } catch (error) {
              commit(DELETE_SETTINGS.FAILED);
              throw new Error('Delete failed');
            }
          }
        }
      }
    }
  )
};
