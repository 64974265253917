<template>
  <section v-if="isGetRequestPending">
    <p-loading />
  </section>
  <section v-else-if="!!item" class="specifications">
    <header>
      <p-specifications-modal v-if="templateModalVisible" @close="templateModalVisible = false" />
      <div class="specification-name-wrapper" @click="templateModalVisible = true">
        Specification:
        <label class="specification-name">{{ item && item.name }}</label>
        ▾
      </div>
    </header>

    <p-with-claim-set :item="item" @submit="save"> </p-with-claim-set>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import Button from '@/components/common/Button';

import Loading from '@/components/common/Loading';
import SpecificationsModal from './SpecificationsModal';
import WithClaimSet from './WithClaimSet';

export default {
  components: {
    'p-specifications-modal': SpecificationsModal,
    'p-loading': Loading,
    'p-with-claim-set': WithClaimSet
  },
  data() {
    return {
      templateModalVisible: false
    };
  },
  computed: {
    ...mapState({
      item: s => s.specifications.item,
      isGetRequestPending: s => s.specifications.isGetRequestPending
    })
  },
  watch: {
    '$route.params'() {
      this.initialize();
    }
  },
  async created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      await this.$store.dispatch('specifications/getById', this.$route.params.id);
      await this.$store.dispatch('specifications/sample/initialize');

      if (this.item) {
        this.templateModalVisible = false;
      }
    },
    selectTemplate(id) {
      this.$router.push({ path: `/specifications/${id}` });
    },
    async save({ id, template }) {
      await this.$store.dispatch('specifications/update', {
        id,
        template
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.specifications-wrapper {
  width: 100%;
  height: 100%;
}
.specifications {
  width: 100%;
  margin: 0 0.5rem;
  overflow: hidden;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: max-content minmax(0, 1fr);
  grid-gap: 0.5rem;

  > header {
    grid-column: 1/3;
    background: var(--theme-surface);
    padding: 0.75rem 2rem;
    justify-content: flex-start;
    align-items: center;
    display: flex;

    .specification-name-wrapper {
      cursor: pointer;

      > * {
        cursor: pointer;
      }
    }

    .specification-name {
      text-transform: uppercase;
      font-weight: 500;
      color: var(--theme-on-background-dark);
      padding: 0 5px;
    }
  }
}
</style>
