<template>
  <p-edit v-if="$hasPermission('users.read')" />
  <p-not-found v-else />
</template>

<script>
import NotFound from './../components/NotFound.vue';
import EditUser from './../components/users/EditUser.vue';

export default {
  components: {
    'p-edit': EditUser,
    'p-not-found': NotFound
  }
};
</script>
