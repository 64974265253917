<template>
  <section class="faceted-search">
    <ul class="facets-list">
      <li v-for="group of groups" :key="group.name" class="facets-list-item">
        <h3 v-if="group.data.length">{{ group.label }} ({{ group.data.length }}):</h3>
        <ul v-if="group.data.length" class="facet-value-list">
          <li v-for="item of group.data.slice(0, expanded.includes(group.name) ? undefined : 10)" :key="item.name" class="facet-value-list-item">
            <label :class="{ active: !!active[`${group.name}:${item.name}`] }" :title="item.name" @click="toggle(group.name, item.name)">
              <span class="facet-item-label">{{ item.name }}</span>
              <span class="facet-item-count">({{ item.count }})</span></label
            >
          </li>
          <li v-if="group.data.length > 10" class="facet-value-list-item toggle-wrapper" @click="toggleMore(group)">
            <button v-if="expanded.includes(group.name)">hide ▵</button>
            <button v-else variant="text">show more ▿</button>
          </li>
        </ul>
      </li>
    </ul>
  </section>
</template>

<script>
import { mapState } from 'vuex';

import { ensureArray } from '@/utils/qs';

export default {
  data() {
    return { active: {}, expanded: [] };
  },
  computed: {
    ...mapState({
      aggs: s => s.portfolio.aggs
    }),
    groups() {
      return Object.keys(this.aggs || {})
        .filter(s => s === 'tags')
        .reduce((obj, key) => {
          const data = [];
          for (const name in this.aggs[key]) {
            data.push({ name, count: this.aggs[key][name] });
          }

          return [
            ...obj,
            {
              name: key,
              label: this.getGroupLabel(key),
              data: data.sort((a, b) => b.count - a.count)
            }
          ];
        }, []);
    }
  },
  watch: {
    aggs: {
      handler() {
        const active = {};
        for (const group of this.groups) {
          const names = ensureArray(this.$route.query[group.name]).map(decodeURIComponent);
          for (const name of names) {
            active[`${group.name}:${name}`] = true;
          }
        }
        this.active = active;
      },
      immediate: true
    }
  },
  methods: {
    toggle(group, value) {
      const collection = ensureArray(this.$route.query[group]).map(decodeURIComponent);
      const query = {
        ...this.$route.query,
        [group]: (collection.includes(value) ? collection.filter(s => s !== value) : [...collection, value]).map(encodeURIComponent)
      };
      delete query.from;

      this.$router.push({
        path: this.$route.path,
        query
      });
    },
    toggleMore(group) {
      if (this.expanded.includes(group.name)) {
        this.expanded = this.expanded.filter(s => s !== group.name);
      } else {
        this.expanded.push(group.name);
      }
    },
    getGroupLabel(key) {
      switch (key) {
        case 'tags':
          return 'Tags';
        default:
          return key;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.faceted-search {
  .facets-list {
    .facets-list-item {
      h3 {
        text-transform: uppercase;
        font-size: 0.8rem;
        color: var(--theme-on-surface-dark);
        margin-bottom: 10px;
      }
      &:not(:last-child) {
        margin-bottom: 2rem;
      }
    }
  }

  .facet-value-list {
    .facet-value-list-item {
      label {
        background-color: var(--theme-secondary-light);
        color: var(--theme-on-secondary);
        border-radius: 2px;
        display: inline-flex;
        justify-content: space-between;
        max-width: 75%;
        padding: 3px 5px 3px 5px;
        position: relative;
        font-weight: 500;
        font-size: 0.75rem;
        letter-spacing: 0.025em;
        cursor: pointer;
        &.active {
          background-color: var(--theme-secondary-dark);
        }

        .facet-item-label {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-right: 5px;
        }
      }

      &:not(:last-child) {
        margin-bottom: 3px;
      }

      &.toggle-wrapper {
        justify-content: flex-end;
        display: flex;

        button {
          outline: none;
          background: transparent;
          border: 0 none;
          padding: 0;
          font-size: 0.75rem;
          color: var(--theme-on-surface-accent);
          cursor: pointer;
        }
      }
    }
  }
}
</style>
