<template>
  <header>
    <div class="left">
      <router-link v-if="logo" to="/">
        <img alt="Patent Hub logo" src="./../assets/logo.svg" />
        <label>Backoffice</label>
      </router-link>
      <span v-if="$route.name" class="breadcrumb">
        <span style="padding: 0 .5rem">-</span><label>{{ $route.name }}</label>
      </span>
    </div>
    <div class="right">
      <p-button v-if="isAuthenticated" variant="text" @click="goToFeedback"><p-icon name="help"></p-icon></p-button>
      <div v-if="isAuthenticated" class="log-out-wrapper">
        <p-button @click="logout">Log out</p-button>
      </div>
    </div>
  </header>
</template>

<script>
import { mapState } from 'vuex';

import httpClient from '@/utils/httpClient';

import Button from './common/Button';
import Icon from './common/Icon';

export default {
  components: {
    'p-button': Button,
    'p-icon': Icon
  },
  props: {
    logo: {
      type: Boolean,
      default: true
    },
    search: {
      type: Boolean,
      default: true
    },
    help: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState({
      isAuthenticated: s => s.identity.isAuthenticated
    })
  },
  methods: {
    async goToFeedback() {
      const lock = this.$lock();
      const url = await httpClient.get('/api/feedback/canny/redirectUrl');
      window.open(url, '_blank');
      lock.release();
    },
    async logout() {
      this.$store.dispatch('identity/logout');
      window.location.reload();
    }
  }
};
</script>

<style scoped lang="scss">
header {
  display: grid;
  grid-template-columns: minmax(0, 1fr) 350px;
  grid-template-rows: 50px;
  align-items: center;
  color: var(--theme-on-surface);
  background-color: var(--theme-surface);
  padding: 0 2rem;
  grid-gap: 10px;

  a {
    color: var(--theme-on-surface);
    text-decoration: none;
    cursor: pointer;
    display: grid;
    grid-template-columns: max-content max-content;
    grid-gap: 10px;
    align-items: center;
    font-size: 1.5rem;
    font-family: 'Roboto Mono', monospace;

    &:visited,
    &:active,
    &:focus {
      color: var(--theme-on-surface);
      text-decoration: none;
    }

    img {
      max-width: 100%;
      max-height: 100%;
      height: 32px;
    }

    label {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    > * {
      cursor: pointer !important;
    }
  }

  .left {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .breadcrumb {
      color: var(--theme-on-surface-accent);
      font-size: 1.5rem;
      font-family: 'Roboto Mono', monospace;
      text-transform: capitalize;
    }
  }

  .right {
    display: flex;
    justify-content: flex-end;
    padding: 0 2rem;
  }
}
</style>
