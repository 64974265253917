<template>
  <section v-if="!isReady || isGetRequestPending" class="user-settings">
    <p-loading />
  </section>
  <section v-else class="user-settings">
    <header>
      <h1>User Settings</h1>
    </header>
    <p>
      Please make sure all keys are unique before saving. Use underscore to separate words. <br />Disallowed characters for the key name will be
      removed on input.
    </p>
    <form :class="{ dirty: $v.$anyDirty }" autocomplete="off" @submit.prevent="submit">
      <div v-for="(row, index) in rows" :key="row.id" class="form-row">
        <div class="form-row-input">
          <p-text-field
            v-model="row.key"
            name="key"
            placeholder="Key(name)"
            :disabled="row.savedToDB"
            @input="trimAndSanitizeKey($event, row)"
            @blur="$v.rows.$each.$iter[index].key.$touch()"
          />
          <p-text-field
            v-model="row.value"
            name="value"
            placeholder="Value"
            :disabled="isDisabled(row)" 
            :type="row.secured ? 'password' : 'text'"
          />
            <p-button v-if="row.secured" type="button" title="Unsecure bot setting" variant="text" :disabled="isDisabled(row)"  @click.prevent="toggleSecure(row)"
            ><md-icon name="lock"
          /></p-button>
          <p-button v-else type="button" title="Secure bot setting" variant="text" :disabled="isDisabled(row)" @click.prevent="toggleSecure(row)"
            ><md-icon name="lock-open"
          /></p-button>
          <p-button type="button" title="Remove bot setting" variant="text" @click.prevent="removeRow(row.id)"><md-icon name="delete"/></p-button>
        </div>
        <div v-if="!$v.rows.$each.$iter[index].key.required" class="error">Key is required</div>
        <div v-if="!$v.rows.$each.$iter[index].value.required" class="error">Value is required</div>
      </div>

      <div v-if="isUpdateRequestFailed" class="error">
        Failed to store user settings. Check your input and try again. If an error still occurs, please, contact our developers.
      </div>
      <div v-if="duplicate" class="error">
        Key "{{ duplicate }}" already exists.
      </div>

      <p-button type="button" title="Add setting" variant="text" @click.prevent="addRow"><md-icon name="plus"/></p-button>

      <div class="form-row submit">
        <p-button type="button" @click.prevent="$router.back()">Cancel</p-button>
        <p-button
          v-if="$hasPermission('users.write')"
          color="primary"
          type="submit"
          :disabled="isUpdateRequestPending || ($v.$anyDirty && $v.$invalid)"
          >Save</p-button
        >
      </div>
    </form>

    <footer></footer>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import { required } from 'vuelidate/lib/validators';

import Loading from '@/components/common/Loading';
import TextField from '@/components/common/TextField';
import Button from '@/components/common/Button';
import MdIcon from '../common/MdIcon.vue';
import { getDuplicateKey } from '../../utils/getDuplicateKey';
import { v4 as uuidv4 } from 'uuid';

export default {
  components: {
    'p-loading': Loading,
    'p-text-field': TextField,
    'p-button': Button,
    'md-icon': MdIcon
  },
  data() {
    return {
      rows: [],
      duplicate: null,
      isReady: false
    };
  },
  computed: {
    ...mapState({
      item: s => s.users.item,
      settings: state => state.users.settings.collection,
      isGetRequestPending: state => state.users.settings.isGetRequestPending,
      isUpdateRequestPending: state => state.users.settings.isUpdateRequestPending,
      isUpdateRequestFailed: state => state.users.settings.isUpdateRequestFailed,
      isDeleteRequestFailed: state => state.users.settings.isDeleteRequestFailed
    })
  },
  validations: {
    rows: {
      $each: {
        key: { required },
        value: { required }
      }
    }
  },
  watch: {
    '$route.params': {
      async handler() {
        this.isReady = false;
        await this.$store.dispatch('users/getById', this.$route.params.id);
        this.email = this.item.email;

        try {
          await this.$store.dispatch('users/settings/get', { owner: this.email });
        } catch (error) {
          this.$toast.error({
            title: 'Settings fetch failed',
            message: `Please, try again later or contact our development team.`
          });
        }

        if (Array.isArray(this.settings) && this.settings.length > 0) {
          this.settings.forEach(element => {
            if (!element.hasOwnProperty('secured')) {
              this.$set(element, 'secured', false);
            }
            element.savedToDB = true;
          });

          this.rows = this.settings;
        }

        this.isReady = true;

      },
      immediate: true
    }
  },
  methods: {
    isDisabled(row){
      return (typeof row.value) === 'object';
    },
    async submit() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }

      const dup = getDuplicateKey(this.rows);

      if (!dup) {
        try {
          this.isReady = false;
          for (const row of this.rows) {
            delete row.savedToDB;
            const payload = row;
            payload.owner = this.email;
            await this.$store.dispatch('users/settings/update', payload);
          }

          this.$toast.success({
            message: 'Settings updated'
          });

          if (!this.isUpdateRequestFailed) {
            this.$router.push({ path: `/people/${this.item.id}` });
          }
        } catch (err) {
          this.$toast.error({
            title: 'Settings update failed',
            message: `Please, try again later or contact our development team.`
          });
        }
      } else {
        this.duplicate = dup;
        return;
      }

      this.isReady = true;
    },
    back() {
      this.$router.back();
    },
    trimAndSanitizeKey(event, row) {
      row.key =  event.trim().replace(/[^a-zA-Z0-9\-._~]/g, '');
    },
    handleInput(value) {
      if (this.areKeysNotUnique) {
        this.areKeysNotUnique = false;
      }

      value
        .trim()
        .toLocaleUpperCase()
        .replace(/[^a-zA-Z0-9\-._~]/g, '');
    },
    addRow() {
      const newId = uuidv4();

      this.rows.push({ id: newId, key: '', value: '', secured: false });
    },
    async removeRow(id) {
      const rowIndex = this.rows.findIndex(row => row.id === id);

      if (rowIndex === -1) {
        return;
      }

      const rowToRemove = this.rows[rowIndex];

      if (rowToRemove.savedToDB) {
        try {
          await this.$store.dispatch('users/settings/delete', { key: rowToRemove.key, owner: this.email });

          this.$toast.success({
            message: 'Setting deleted'
          });
        } catch (err) {
          this.$toast.error({
            title: 'Setting delete failed',
            message: 'Please, try again later or contact our development team.'
          });
          return;
        }
      }

      this.rows.splice(rowIndex, 1);
    },
    toggleSecure(row) {
      row.secured = !row.secured;
    }
  }
};
</script>

<style lang="scss" scoped>
.user-settings {
  width: 100%;
  header {
    width: 100%;
    padding: 1rem 2rem;
    border-bottom: 1px solid var(--theme-highlight);
    box-sizing: border-box;

    a {
      font-size: 0.8rem;
    }
  }

  h1 {
    color: var(--theme-on-surface);
    font-size: 1.5rem;
    font-weight: 700;
  }

  p {
    padding: 1rem 2rem;
    font-size: 0.9rem;
    margin-bottom: 0.25rem;
  }
  form {
    padding: 2rem;
    max-width: 480px;

    > * {
      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }
      &:last-child {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
      }
    }

    .error {
      font-size: 0.8rem;
      color: var(--theme-error);
      text-align: left;
      padding: 0.25rem 0;
      display: none;
    }
    &.dirty {
      .error {
        display: block;
      }
    }
  }

  .form-row {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .form-row-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
}
</style>
