<template>
  <p-home />
</template>

<script>
import Home from './../components/Home.vue';

export default {
  components: {
    'p-home': Home
  },
  mounted() {}
};
</script>
