import { am } from './utils';

import httpClient from '@/utils/httpClient';

const GET_COLLECTION = am(`GET_FIP_COLLECTION`);

const state = {
  isGetCollectionRequestPending: false,
  isGetCollectionRequestFailed: false,

  collection: [],
  total: 0,
  size: 0,
  from: 0,
  aggs: null,

  isReady: false,
  active: null
};

const mutations = {
  [GET_COLLECTION.STARTED](state) {
    state.isGetCollectionRequestPending = true;
    state.isGetCollectionRequestFailed = false;
  },
  [GET_COLLECTION.FAILED](state) {
    state.isGetCollectionRequestPending = false;
    state.isGetCollectionRequestFailed = true;
  },
  [GET_COLLECTION.COMPLETED](state, collection) {
    state.isGetCollectionRequestPending = false;
    state.collection = [...collection.data];
    state.aggs = collection.aggs;
    state.total = collection.total;
    state.size = collection.size;
    state.from = collection.from;
    state.isReady = true;
  }
};

const actions = {
  async getCollection({ commit }, payload) {
    commit(GET_COLLECTION.STARTED);
    try {
      const { data, total, aggs } = await httpClient.post(`/api/fip/matters/sorted`, payload);
      commit(GET_COLLECTION.COMPLETED, {
        data,
        total,
        aggs
      });
    } catch (e) {
      commit(GET_COLLECTION.FAILED);
    }
  },
  async sync({ commit }, fipId) {
      await httpClient.request(`/api/fip/matters/byFipId/${fipId}`, {
        method: 'GET',
        headers: {
          'x-no-cache': true
        }
      });
  },
  async delete({ commit }, id) {
      await httpClient.delete(`/api/fip/matters/${id}`);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
