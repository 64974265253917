import Vue from 'vue';
import Vuelidate from 'vuelidate';

import App from './App.vue';
import router from './router';
import store from './store/index.js';

import { VLazyImagePlugin } from 'v-lazy-image';

import Toast from './plugins/ToastService';
import Authorization from './plugins/AuthorizationService';
import Modal from './plugins/ModalService';
import Lock from './plugins/LockService';

import highlight from './filters/highlight';
import decamelise from './filters/decamelise';

Vue.config.productionTip = false;

Vue.use(VLazyImagePlugin);
Vue.use(Vuelidate);
Vue.use(Toast);
Vue.use(Authorization);
Vue.use(Modal);
Vue.use(Lock);

Vue.filter('highlight', highlight);
Vue.filter('decamelise', decamelise);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
