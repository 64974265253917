<template>
  <p-create-prompt v-if="$hasPermission('prompts')" />
  <p-not-found v-else />
</template>

<script>
import CreatePrompt from './../components/prompts/CreatePrompt.vue';
import NotFound from './../components/NotFound.vue';

export default {
  components: {
    'p-create-prompt': CreatePrompt,
    'p-not-found': NotFound
  }
};
</script>
