<template>
  <section v-if="!isReady">
    <p-loading />
  </section>
  <section v-else class="processing">
    <header>
      <h1>Processing dashboard</h1>
    </header>
    <section v-if="$hasPermission('portfolio')" class="action-wrapper">
      <p-button v-if="active" color="secondary" @click="pause">Pause processing</p-button>
      <p-button v-else color="primary" @click="resume">Resume processing</p-button>
    </section>
    <section class="processing-list-wrapper">
      <p-processing-inspect-modal
        v-if="inspectModalVisible"
        :message="itemToInspect && itemToInspect.message"
        :stack="itemToInspect && itemToInspect.stack"
        @close="inspectModalVisible = false"
      />
      <div class="header-wrapper">
        <div class="filter">
          <p-checkbox v-model="filter.queued" label="Queued"></p-checkbox>
          <p-checkbox v-model="filter.requeued" label="Requeued"></p-checkbox>
          <p-checkbox v-model="filter.inprogress" label="In Progress"></p-checkbox>
          <p-checkbox v-model="filter.completed" label="Completed"></p-checkbox>
          <p-checkbox v-model="filter.failed" label="Failed"></p-checkbox>
        </div>
        <div>Sort by:<p-dropdown :value="sort" :options="options.sorting" @change="setSort"></p-dropdown></div>
        <div><label>Total:</label>&nbsp;{{ total }}</div>
      </div>
      <ul class="processing-list">
        <li class="processing-list-item header">
          <div>Id</div>
          <div>Status</div>
          <div>Created At</div>
          <div>Updated At</div>
          <div></div>
        </li>
      </ul>
      <ul class="processing-list">
        <li v-for="entry in sorted" :key="entry.id" class="processing-list-item">
          <div>
            <span>{{ entry.id }}</span>
          </div>
          <div :style="{ color: STATUS[entry.status].color }">{{ STATUS[entry.status].label }}</div>
          <div>{{ entry.createdAt && new Date(entry.createdAt).toLocaleString() }}</div>
          <div>{{ entry.updatedAt && new Date(entry.updatedAt).toLocaleString() }}</div>
          <div class="processing-list-item-actions">
            <p-button v-if="entry.status === 'failed'" variant="text" color="secondary" title="Inspect this application" @click="inspect(entry)"
              >≡</p-button
            >
            <p-button
              v-if="$hasPermission('portfolio') && (entry.status === 'completed' || entry.status === 'failed' || entry.status === 'dead')"
              variant="text"
              title="Requeue this application"
              @click="requeue(entry.id)"
              >&#8634;</p-button
            >
            <p-button
              v-if="$hasPermission('portfolio') && entry.status !== 'inprogress'"
              variant="text"
              title="Delete this application"
              @click="remove(entry.id)"
              >&times;</p-button
            >
          </div>
        </li>
      </ul>
    </section>
    <section class="schedule-wrapper">
      <header>
        <h2>Schedule</h2>
      </header>
      <p-processing-schedule />
    </section>
    <section class="chart-wrapper">
      <header>
        <h2>Summary</h2>
      </header>
      <p-pie-chart v-if="!!aggs && Object.keys(aggs).length" :key="aggsHash" :aggs="aggs" />
    </section>
  </section>
</template>

<script>
import { mapState } from 'vuex';

import { lw } from '@/utils/lw';

import Loading from '@/components/common/Loading';
import Checkbox from '@/components/common/Checkbox';
import Button from '@/components/common/Button';
import Dropdown from '@/components/common/Dropdown';

import PieChart from './PieChart.js';
import Schedule from './Schedule';

import InspectModal from './InspectModal';

import { STATUS } from './constants';

const LOCAL_STORAGE_PROCESSING_STATUS_LIST = 'LOCAL_STORAGE_PROCESSING_STATUS_LIST';
const LOCAL_STORAGE_PROCESSING_SORT_KEY = 'LOCAL_STORAGE_PROCESSING_SORT_KEY';

const STATUS_LIST = Object.keys(STATUS);

export default {
  components: {
    'p-loading': Loading,
    'p-button': Button,
    'p-dropdown': Dropdown,
    'p-checkbox': Checkbox,
    'p-pie-chart': PieChart,
    'p-processing-schedule': Schedule,
    'p-processing-inspect-modal': InspectModal
  },
  data() {
    const statuses = lw.get(LOCAL_STORAGE_PROCESSING_STATUS_LIST) || ['inprogress', 'failed'];

    return {
      sort: lw.get(LOCAL_STORAGE_PROCESSING_SORT_KEY) || '',
      filter: STATUS_LIST.reduce(
        (obj, key) => ({
          ...obj,
          [key]: statuses.includes(key)
        }),
        {}
      ),
      options: {
        sorting: [
          { id: '', label: 'Status' },
          { id: 'newest', label: 'Newest' },
          { id: 'oldest', label: 'Oldest' }
        ]
      },
      autorefreshInterval: null,
      inspectModalVisible: false,
      itemToInspect: null,
      STATUS
    };
  },
  computed: {
    ...mapState({
      total: s => s.processing.total,
      collection: s => s.processing.collection,
      aggs: s => s.processing.aggs,

      aggsHash: s =>
        `${s.processing.aggs.queued || '0'}${s.processing.aggs.requeued || '0'}${s.processing.aggs.inprogress || '0'}${s.processing.aggs.completed ||
          '0'}${s.processing.aggs.failed || '0'}${s.processing.aggs.dead || '0'}`,
      isReady: s => s.processing.isReady,
      active: s => s.processing.active
    }),
    filtered() {
      const statuses = Object.keys(this.filter).filter(key => !!this.filter[key]);
      return this.collection.filter(item => statuses.includes(item.status));
    },
    sorted() {
      let f;
      switch (this.sort) {
        case 'newest':
          f = (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt);
          break;
        case 'oldest':
          f = (a, b) => new Date(a.updatedAt) - new Date(b.updatedAt);
          break;
        default:
          f = (a, b) => STATUS_LIST.indexOf(b.status) - STATUS_LIST.indexOf(a.status);
          break;
      }

      return [...this.filtered].sort(f);
    }
  },
  watch: {
    $route: {
      handler() {
        this.$store.dispatch('processing/getCollection');
        this.$store.dispatch('processing/initialize');
      },
      immediate: true
    },
    filter: {
      handler() {
        lw.set(
          LOCAL_STORAGE_PROCESSING_STATUS_LIST,
          Object.keys(this.filter).filter(key => !!this.filter[key])
        );
      },
      deep: true
    }
  },
  created() {
    this.autorefreshInterval = 30 * 1000;
    this.autorefresh();
  },
  destroyed() {
    this.autorefreshInterval = null;
  },
  methods: {
    async pause() {
      try {
        const lock = this.$lock();
        await this.$store.dispatch('processing/pause');
        lock.release();

        this.$toast.success({ message: `Processing paused` });
      } catch (e) {
        this.$toast.error({ message: `Failed to pause processing` });
      }
    },
    async resume() {
      try {
        const lock = this.$lock();
        await this.$store.dispatch('processing/resume');
        lock.release();

        this.$toast.success({ message: `Processing resumed` });
      } catch (e) {
        this.$toast.error({ message: `Failed to resume processing` });
      }
    },
    async requeue(id) {
      try {
        const lock = this.$lock();
        await this.$store.dispatch('processing/create', { applications: [id] });
        lock.release();

        this.$toast.success({
          title: 'Requeu completed',
          message: `Application '${id}' was scheduled for processing.`
        });
      } catch (e) {
        this.$toast.error({
          title: 'Requeu failed',
          message: `Please, try again later or contact our development team.`
        });
      }
    },
    async remove(id) {
      try {
        const lock = this.$lock();
        await this.$store.dispatch('processing/delete', id);
        lock.release();

        this.$toast.success({
          title: 'Delete completed',
          message: `Application '${id}' was deleted.`
        });
      } catch (e) {
        this.$toast.error({
          title: 'Delete failed',
          message: `Please, try again later or contact our development team.`
        });
      }
    },
    setSort(id) {
      const option = this.options.sorting.find(option => option.id === id);
      if (!option) {
        return;
      }
      lw.set(LOCAL_STORAGE_PROCESSING_SORT_KEY, id);

      this.sort = id;
    },
    async autorefresh() {
      while (this.autorefreshInterval) {
        try {
          await this.$store.dispatch('processing/getCollection');
        } catch (e) {
          this.autorefreshInterval = null;
        }
        await new Promise(resolve => setTimeout(resolve, this.autorefreshInterval));
      }
    },
    inspect(item) {
      this.inspectModalVisible = true;
      this.itemToInspect = item;
    }
  }
};
</script>

<style lang="scss" scoped>
.processing {
  display: grid;
  padding: 0 0.5rem 0 1rem;
  grid-template-columns: minmax(0, 3fr) minmax(0, 1fr);
  grid-template-rows: max-content minmax(0, 1fr) minmax(0, 1fr);
  grid-gap: 0.5rem;
  width: 100%;
  height: 100%;

  > header {
    grid-column: 1/2;
    grid-row: 1/2;
    padding: 0.5rem 0.5rem 0;
    display: flex;

    box-sizing: border-box;
    h1 {
      color: var(--theme-on-surface);
      font-size: 1.5rem;
      font-weight: 700;
      margin-right: 0.25rem;
    }

    .status-label {
      display: inline-flex;
      line-height: 1;
      height: 1rem;
      justify-content: center;
      align-items: center;
      border-radius: 10%;
      padding: 0.1rem 0.25rem;
      font-size: 0.75rem;
      font-weight: 700;

      &.healthy {
        color: var(--theme-on-success);
        background-color: var(--theme-success);
      }
      &.unhealthy {
        color: var(--theme-on-error);
        background-color: var(--theme-error);
      }
    }
  }

  .action-wrapper {
    grid-column: 2/3;
    grid-row: 1/2;

    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .processing-list-wrapper,
  .schedule-wrapper,
  .chart-wrapper {
    background-color: var(--theme-surface);
    padding: 1rem;
    position: relative;

    h2 {
      color: var(--theme-on-surface);
      font-size: 0.8rem;
      font-weight: 500;
      padding: 0;
      margin: 0;
      text-transform: uppercase;
    }
  }

  .processing-list-wrapper {
    display: grid;
    grid-template-rows: 20px max-content minmax(0, 1fr);

    grid-column: 1/2;
    grid-row: 2/4;

    .header-wrapper {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-size: 0.75rem;
      margin: 0;
      padding: 0;
      font-weight: 400;
      margin-right: 6px;
      padding-bottom: 1rem;
      label {
        font-weight: 500;
      }

      > * {
        height: 100%;
        display: flex;
        align-items: center;

        &:not(:last-child) {
          margin-right: 2rem;
        }
      }

      .filter {
        > * {
          &:not(:last-child) {
            margin-right: 0.5rem;
          }
        }
      }
    }
  }

  .processing-list {
    margin: 0;
    padding: 0;
    list-style: none;

    .processing-list-item {
      display: grid;
      grid-template-columns: 130px 100px 175px 175px minmax(0, 1fr);
      grid-gap: 0;
      border-bottom: 1px solid var(--theme-highlight);
      font-size: 0.75rem;
      height: 1.75rem;
      align-items: center;

      > div {
        border-left: 1px solid var(--theme-highlight);
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 0.5rem;

        &:last-child {
          border-left: 1px solid transparent;
          border-right: 1px solid var(--theme-highlight);
        }

        > span {
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }

      &:first-child {
        border-top: 1px solid var(--theme-highlight);
      }

      &:hover {
        background-color: var(--theme-highlight);
        .processing-list-item-actions {
          display: flex;
          justify-content: flex-end;
        }
      }

      &.header {
        font-size: 0.75rem;
        color: var(--theme-on-background-accent);
        > * {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          padding: 0 0.5rem;
        }
      }

      .processing-list-item-actions {
        display: none;
      }
    }

    &:not(:last-child) {
      margin-right: 6px;
    }

    &:last-child {
      overflow-y: scroll;
    }
  }

  .chart-wrapper,
  .schedule-wrapper {
    display: grid;
    grid-template-rows: max-content minmax(0, 1fr);
    grid-gap: 0.5rem;
    position: relative;
  }

  .chart-wrapper {
    grid-row: 2/3;
    grid-column: 2/3;

    > * {
      min-width: 0;
      overflow: hidden;
    }
  }

  .schedule-wrapper {
    grid-row: 3/4;
    grid-column: 2/3;
    overflow-y: auto;
  }
}
</style>
