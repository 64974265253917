<template>
  <section v-if="!isReady || isGetRequestPending" class="forms">
    <p-loading />
  </section>

  <section v-else class="forms">
    <header>
      <h1>Forms</h1>
      <section class="action-wrapper">
        <router-link v-if="$hasPermission('forms')" to="/forms/create">+ new</router-link>
      </section>
    </header>
    <p-list
      name="forms"
      :data="collection"
      :total="total"
      :metadata="{
        name: { label: 'Name', width: 'minmax(0, 3fr)' },
        description: { label: 'Description', width: 'minmax(0, 3fr)' },
        fileNameTemplate: { label: 'Filename Template', width: 'minmax(0, 3fr)' },
        foreach: { label: 'Foreach', width: '75px' },
        clients: { label: 'Clients', width: 'minmax(100px, 2fr)' },
        tags: { label: 'Tags', width: 'minmax(100px, 2fr)' },
        modifiedAt: { label: 'Last modification', width: '175px' }
      }"
      @edit="edit"
    >
      <template #name="{ item }"> {{ item.name }}.{{ item.type }}</template>
      <template #__actions="{ item }">
        <p-button color="primary" variant="text" title="Download form template" @click="downloadFile(item.formUrl)">⤓</p-button>
        <p-button v-if="['xfa', 'xfah', 'xfdf'].some(a => a === item.type)" variant="text" title="Download model template" @click="downloadFile(item.modelUrl)">⤓</p-button>
        <p-button variant="text" title="Edit this form" @click="edit(item.id)"> &#9998;</p-button>
      </template>
    </p-list>
  </section>
</template>

<script>
import { mapState } from 'vuex';

import Button from './../common/Button';
import Loading from './../common/Loading';
import List from './../common/List';

export default {
  components: {
    'p-button': Button,
    'p-loading': Loading,
    'p-list': List
  },
  data() {
    return {
      isReady: false
    };
  },
  computed: {
    ...mapState({
      collection: s => s.forms.collection,
      total: s => s.forms.total,
      isGetRequestPending: s => s.forms.isGetRequestPending
    })
  },
  async created() {
    await this.$store.dispatch('forms/getCollection');
    this.isReady = true;
  },
  methods: {
    downloadFile(url) {
      window.open(`/api${url}`, '_blank');
    },
    async edit(id) {
      this.$router.push({ path: `/forms/${id}/edit` });
    }
  }
};
</script>

<style scoped lang="scss">
.forms {
  display: grid;
  padding: 0 0.5rem 0 1rem;
  grid-template-columns: 1fr;
  grid-template-rows: max-content minmax(0, 1fr);
  grid-gap: 0.5rem;
  width: 100%;
  height: 100%;

  > header {
    padding: 0.5rem 0.5rem 0;
    display: flex;
    justify-content: space-between;

    box-sizing: border-box;
    h1 {
      color: var(--theme-on-surface);
      font-size: 1.5rem;
      font-weight: 700;
      margin-right: 0.25rem;
    }

    .action-wrapper {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
}
</style>
