<template>
  <div class="form-content">
    <div v-if="valuesOnly">
      <div v-for="(variable, index) in localItem.variables" :key="index" class="values-row">
        <TextVariable
          v-if="variable.type !== 'image'"
          v-model="variable.value"
          :label="variable.label || variable.name"
          :disabled="readOnly"
          :multiline="true"
          @input="onChange"
        />
        <ImageVariable
          v-else
          v-model="variable.value"
          :label="variable.label || variable.name"
          :compact="false"
          :preview="false"
          :disabled="readOnly"
          @input="onChange"
        />
      </div>
    </div>
    <div v-else>
      <div class="button">
        <Button color="primary" :disabled="isPromptStreaming" @click="addVariable">+ Variable</Button>
      </div>
      <div v-for="(variable, index) in localItem.variables" :key="index" class="row">
        <TextField v-model="variable.name" label="Name" :disabled="isPromptStreaming" @input="onChange" />
        <TextField v-model="variable.label" label="Label" :disabled="isPromptStreaming" @input="onChange" />
        <Multiselect v-model="variable.type" label="Type" :disabled="isPromptStreaming" :options="types" @input="onChange" />
        <TextVariable
          v-if="variable.type !== 'image'"
          v-model="variable.value"
          :label="'Default Value'"
          :disabled="isPromptStreaming"
          @input="onChange"
        />
        <ImageVariable v-else v-model="variable.value" :label="'Default Value'" :compact="true" :disabled="isPromptStreaming" @input="onChange" />
        <Button class="remove-button" title="Remove variable" :disabled="isPromptStreaming" @click="removeVariable(index)">
          <MdIcon name="close" />
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import TextField from '@/components/common/TextField';
import Multiselect from '@/components/common/Multiselect';
import Button from '@/components/common/Button';
import MdIcon from '@/components/common/MdIcon';
import ImageVariable from '../variables/ImageVariable';
import TextVariable from '../variables/TextVariable';

export default {
  components: {
    TextField,
    Multiselect,
    Button,
    MdIcon,
    ImageVariable,
    TextVariable
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    suggestions: {
      type: Array,
      default: () => []
    },
    prefix: {
      type: String,
      default: ''
    },
    valuesOnly: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      localItem: {
        ...this.item
      }
    };
  },
  computed: {
    ...mapState({
      isPromptStreaming: s => s.prompts.sample.isRequestPending,
      providers: s => s.nlp.providers || []
    }),
    types() {
      const types = [];
      const allCapabilites = [];
      this.providers?.map(p => p.capabilities).forEach(pc => allCapabilites.push(...pc));

      for (const capability of allCapabilites) {
        types.push(...(capability?.variables || []));
      }

      return [...new Set(types)];
    }
  },
  watch: {
    'localItem.variables': {
      handler(newVal, oldVal) {
        for (const oldEl of oldVal) {
          const newEl = newVal.find(element => element.name === oldEl.name);
          if (!newEl) {
            break;
          }

          if (newEl.type !== oldEl.type) {
            newEl.value = newEl.type === 'image' ? [] : '';
            break;
          }
        }
      },
      deep: true // Enable deep watching
    }
  },
  methods: {
    addVariable() {
      let index = 1;
      let varName = `${this.prefix}_Var_${index}`;
      while (this.localItem.variables.find(v => v.name === varName)) {
        index++;
        varName = `${this.prefix}_Var_${index}`;
      }

      this.localItem.variables.push({
        name: varName,
        label: varName,
        type: 'string',
        value: ''
      });
      this.onChange();
    },
    removeVariable(index) {
      this.localItem.variables.splice(index, 1);
      this.onChange();
    },
    onChange() {
      this.$emit('change', { ...this.localItem });
    }
  }
};
</script>

<style scoped lang="scss">
.form-content {
  overflow-y: none;
  .button {
    margin-bottom: 10px;
  }

  .values-row {
    overflow-y: hidden;
    margin-bottom: 10px;
    grid-gap: 5px;
    align-items: flex-start;
    height: 100%;

    label {
      word-wrap: break-word;
    }
  }

  .row {
    display: grid;
    grid-template-columns: 100px 100px 100px 2fr 10px;
    margin-bottom: 10px;
    grid-gap: 5px;
    align-items: flex-start;

    .remove-button {
      align-self: center;
    }
  }
}
</style>
