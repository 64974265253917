<template>
  <p-modal :visible="true" class="inspect-modal">
    <template slot="title">
      Inspect application
    </template>
    <template slot="footer">
      <p-button color="secondary" @click="$emit('close')">Close</p-button>
    </template>
    <div class="inspect-modal-body">
      <h3>
        {{ message }}
      </h3>
      <pre>
      {{ Array.isArray(stack) ? stack.join('\n') : trace }}
      </pre>
    </div>
  </p-modal>
</template>

<script>
import Button from '@/components/common/Button';
import Modal from '@/components/common/Modal';

export default {
  components: {
    'p-button': Button,
    'p-modal': Modal
  },
  props: {
    stack: {
      type: [String, Array],
      default: () => []
    },
    message: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.inspect-modal {
  .inspect-modal-body {
    padding: 1.75rem 2rem 0.75rem 2rem;
    h3 {
      font-size: 1rem;

      color: var(--theme-on-background);
      margin-bottom: 1rem;
    }
    pre {
      font-family: monospace;
      font-size: 0.9rem;
      white-space: pre-wrap;

      color: var(--theme-on-background-dark);
    }
  }
}
</style>
