<template>
  <div>
    <label v-if="!!label" :for="id">{{ label }}</label>
    <form ref="form" class="file-select" :class="{ 'with-lable': label }">
      <p-button :color="color" :variant="variant" type="button" :disabled="disabled" @click="openDialog">
        <slot>Upload</slot>
      </p-button>
      <input :id="id" type="file" :accept="accept" :diabled="disabled" :multiple="multiple" @input="input" />
    </form>
  </div>
</template>

<script>
import Button from './Button';
export default {
  components: {
    'p-button': Button
  },
  props: {
    id: {
      type: String,
      default: () => Math.random().toString()
    },
    multiple: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: null
    },
    color: {
      type: String,
      default: '',
      validator: function(value) {
        return ['', 'primary', 'secondary'].indexOf(value) !== -1;
      }
    },
    variant: {
      type: String,
      default: 'contained',
      validator(value) {
        return ['contained', 'text'].indexOf(value) !== -1;
      }
    },
    accept: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    openDialog() {
      if (this.disabled) {
        return;
      }
      this.$refs.form.reset();
      document.getElementById(this.id).click();
    },
    input(e) {
      this.$emit('input', e.target.files);
      this.$emit('change', e.target.files);
    }
  }
};
</script>

<style lang="scss" scoped>
label {
  font-weight: 500;
  font-size: 0.75rem;
  letter-spacing: 0.025em;
}

.with-lable {
  border: 1px solid var(--theme-on-background-dark);
  color: var(--theme-on-background);
  background: var(--theme-background);
  border-radius: 2px;
  padding: 5px;
}

.file-select {
  input[type='file'] {
    display: none;
  }
}
</style>
