<template>
  <p-modal :visible="true">
    <span slot="title"> Step instructions </span>
    <section class="add-step-modal">
      <div class="text-field">
        <p-editor v-model="stepInstructions" :scrollabel="true" :height="400" />
      </div>
    </section>
    <div slot="footer">
      <p-button @click="$emit('cancel')">Cancel</p-button>
      <p-button color="secondary" @click="onConfirm">Update</p-button>
    </div>
  </p-modal></template
>

<script>
import Button from '@/components/common/Button';
import Modal from '@/components/common/Modal';
import Editor from '@/components/common/editor/Editor';

export default {
  components: {
    'p-button': Button,
    'p-modal': Modal,
    'p-editor': Editor
  },
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      stepInstructions: this.value
    };
  },
  methods: {
    onConfirm() {
      this.$emit('confirm', { instructions: this.stepInstructions });
    }
  }
};
</script>

<style lang="scss" scoped>
.add-step-modal {
  padding: 20px;
  height: 500px;

  .text-field {
    height: 450px;
    background: var(--theme-background);
    border: 1px solid var(--theme-on-background-accent);
  }
}
</style>
