<template>
  <div class="creation-event">
    <div class="row">
      <p-multiselect label="Choose event source for milestone creation" :value="source" :options="Object.keys(sources)" @input="onSourceChange" />
    </div>
    <div class="row">
      <p-multiselect
        v-if="source"
        label="Choose event for milestone creation"
        :value="valueObj"
        :options="sources[source] || []"
        :get-label-callback="source => source.name"
        @input="onEventChange"
      />
    </div>
  </div>
</template>
<script>
import Multiselect from '@/components/common/Multiselect';

import { mapState } from 'vuex';

export default {
  components: {
    'p-multiselect': Multiselect
  },
  props: {
    value: {
      type: Object,
      default: () => ({ code: null, source: null })
    }
  },
  data() {
    return {
      localSource: null
    };
  },
  computed: {
    ...mapState({
      eventSources: s => s.milestones.contracts.eventSources
    }),
    sources: function() {
      const result = {};
      this.eventSources
        .filter(({ canCreateMilestone }) => canCreateMilestone)
        .forEach(source => {
          if (source.events.length) {
            result[source.source] = source.events;
          }
        });
      return result;
    },
    source() {
      const entries = Object.entries(this.sources);
      if (this.localSource) {
        return this.localSource;
      }
      if (!this.value) {
        return entries[0][0];
      }
      const currentSource = entries.find(([key, value]) => value.some(e => e.code === this.value.code));
      return currentSource[0];
    },
    valueObj() {
      if (this.value) {
        const sources = this.sources[this.source];
        return sources.find(source => source.code === this.value.code);
      } else {
        const entry = Object.entries(this.sources)[0][1][0];
        this.onEventChange(entry);
        return entry;
      }
    }
  },
  methods: {
    onSourceChange(e) {
      this.localSource = e;
    },
    onEventChange(e) {
      this.$emit('input', { source: this.source, code: e.code });
    }
  }
};
</script>

<style scoped lang="scss">
.creation-event {
  margin-bottom: 20px;
  .row {
    margin-bottom: 20px;
  }
}
</style>
