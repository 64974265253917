<template>
  <section class="create-workflow">
    <header>
      <h1>New Workflow</h1>
    </header>
    <p-create-form :is-request-pending="isCreateRequestPending" :is-request-failed="isCreateRequestFailed" @submit="create" />
    <footer></footer>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import CreateOrEditForm from './_CreateOrEdit';

export default {
  components: {
    'p-create-form': CreateOrEditForm
  },
  data() {
    return {
      name: '',
      ext: '',
      description: '',
      template: null,
      text: '',
      tags: []
    };
  },
  computed: {
    ...mapState({
      isCreateRequestPending: s => s.workflows.isCreateRequestPending,
      isCreateRequestFailed: s => s.workflows.isCreateRequestFailed
    })
  },
  methods: {
    async create(data) {
      try {
        await this.$store.dispatch('workflows/create', data);

        this.$toast.success({
          title: 'Create completed',
          message: `Workflow was created.`
        });
        this.$router.push({ path: `/workflows/` });
      } catch (e) {
        const response = await e.response.json();

        this.$toast.error({
          title: 'Create failed',
          message: `Reason: ${response.message}`
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.create-workflow {
  display: grid;
  grid-gap: 0.5rem;
  grid-template-rows: minmax(0, max-content) minmax(0, 1fr);
  width: 100%;
  height: 100%;
  padding: 0 0.5rem 0 1rem;

  header {
    width: 100%;
    padding: 0.5rem 0.5rem 0 0;
    box-sizing: border-box;
  }

  h1 {
    color: var(--theme-on-surface);
    font-size: 1.5rem;
    font-weight: 700;
  }
}
</style>
