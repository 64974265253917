<template>
  <div v-if="editor" class="editor">
    <editor-panel :editor="editor" :size="size" />
    <StyledContent :light="light" class="scrollabel" :style="[{ height: `${height}px` }]">
      <EditorContent :editor="editor" class="content" />
    </StyledContent>
  </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2';
import StarterKit from '@tiptap/starter-kit';
import Link from '@tiptap/extension-link';
import Panel from './Panel';
import StyledContent from './StyledContent';
import Video from './EmbeddedVideo';

export default {
  components: {
    EditorContent,
    'editor-panel': Panel,
    StyledContent
  },
  props: {
    value: {
      type: String,
      default: null
    },
    size: {
      type: String,
      default: 'md'
    },
    light: {
      type: Boolean
    },
    scrollabel: {
      type: Boolean,
      default: false
    },
    height: {
      type: Number,
      default: 150
    }
  },

  data() {
    return {
      editor: null
    };
  },
  watch: {
    value(value) {
      const isSame = this.editor.getHTML() === value;

      if (isSame) {
        return;
      }

      this.editor.commands.setContent(value, false);
    }
  },
  mounted() {
    this.editor = new Editor({
      content: this.value,
      extensions: [
        StarterKit,
        Video,
        Link.configure({
          openOnClick: false
        })
      ],
      onUpdate: () => {
        this.$emit('input', this.editor.getHTML());
      }
    });
  },

  beforeUnmount() {
    this.editor.destroy();
  }
};
</script>
<style lang="scss">
.editor {
  .content {
    div[contenteditable] {
      height: 150px;
    }
  }
  .scrollabel {
    overflow-y: scroll;
  }
}
</style>
