<template>
  <section class="application-list-wrapper">
    <div class="header-wrapper">
      <div class="link-wrapper">
        <slot name="lw"></slot>
      </div>
      <div></div>
      <label>Total:</label> {{ total }}
    </div>
    <ul class="application-list">
      <li class="application-list-item header">
        <div>Title</div>
        <div>Abstract</div>
        <div>Specification</div>
        <div>Claims</div>
        <div>Tags</div>
      </li>
    </ul>
    <ul class="application-list">
      <li v-for="entry in applications" :key="entry.id" class="application-list-item">
        <div class="application-list-item-title">
          <div class="application-list-item-score" title="Relevance score">{{ entry.score }}</div>
          <div>
            <!-- eslint-disable-next-line vue/no-v-html -->
            <label v-html="entry.id"></label>:&nbsp;
            <!-- eslint-disable-next-line vue/no-v-html -->
            <span v-html="entry.title"></span>
          </div>
        </div>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div v-html="entry.abstract && entry.abstract"></div>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div v-html="entry.specification && entry.specification"></div>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div v-html="entry.claims && entry.claims"></div>
        <div>
          <ul class="tag-list">
            <li v-for="tag in entry.tags" :key="tag" class="tag-list-item">{{ tag }}</li>
          </ul>
        </div>
      </li>
    </ul>
  </section>
</template>

<script>
export default {
  props: {
    total: {
      type: Number,
      required: true
    },
    applications: {
      type: Array,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.application-list-wrapper {
  display: grid;
  grid-template-rows: 30px max-content minmax(0, 1fr);
  background-color: var(--theme-background);
  padding: 0 1rem;
  position: relative;
  height: 100%;
  .header-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 0.75rem;
    margin: 0;
    padding: 0;
    font-weight: 400;
    margin-right: 6px;
    label {
      font-weight: 500;
      margin-right: 0.5rem;
    }

    .link-wrapper {
      margin-right: auto;

      a {
        margin-right: 1rem;
        display: inline-flex;
        justify-content: flex-start;
        align-items: center;
        text-transform: uppercase;

        > * {
          &:not(:last-child) {
            margin-right: 0.25rem;
          }
        }
      }
    }
  }
  .application-list {
    margin: 0;
    padding: 0;
    list-style: none;

    .application-list-item {
      display: grid;
      grid-template-columns: minmax(0, 2fr) minmax(0, 2fr) minmax(0, 2fr) minmax(0, 2fr) 200px;
      grid-gap: 0;
      border-bottom: 1px solid var(--theme-highlight);
      font-size: 0.75rem;

      label {
        font-weight: 700;
      }

      > * {
        padding: 0.35rem 0.5rem;
        border-left: 1px solid var(--theme-highlight);

        &:last-child {
          border-right: 1px solid var(--theme-highlight);
        }
      }

      &:first-child {
        border-top: 1px solid var(--theme-highlight);
      }

      &.header {
        font-size: 0.75rem;
        color: var(--theme-on-background-accent);
        > * {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          padding: 0.25rem 0.5rem;
        }
      }

      .application-list-item-title {
        display: grid;
        grid-gap: 0.25rem;
        grid-template-rows: max-content minmax(0, 1fr);
      }

      .application-list-item-score {
        text-align: left;
        font-style: italic;
        color: var(--theme-on-background-accent);
        font-size: 0.6rem;
      }

      .tag-list {
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        .tag-list-item {
          display: inline-flex;
          padding: 0.1rem 0.25rem;
          color: var(--theme-on-surface);
          border: 1px solid var(--theme-on-surface-accent);
          background: var(--theme-surface);
          border-radius: 2px;
          box-sizing: border-box;
          font-size: 0.75rem;

          margin-right: 0.25rem;
          margin-bottom: 0.25rem;
        }
      }
    }

    &:not(:last-child) {
      margin-right: 6px;
    }

    &:last-child {
      overflow-y: scroll;
    }
  }
}
</style>
