<template>
  <div class="tags-wrapper">
    <label v-if="!!label" :for="id">{{ label }} </label>
    <vue-tags-input
      v-model="tag"
      :tags="tags"
      :separators="separators"
      :placeholder="placeholder"
      :validation="validationMapped"
      :allow-edit-tags="false"
      :autocomplete-items="autocompleteItems"
      :add-only-from-autocomplete="addOnlyFromAutocomplete"
      :autocomplete-always-open="autocompleteAlwaysOpen"
      :autocomplete-min-length="autocompleteMinLength"
      :disabled="disabled"
      @tags-changed="change"
      @before-adding-tag="beforeAddingTag"
    />
  </div>
</template>

<script>
import { VueTagsInput, createTags } from '@johmun/vue-tags-input';

export default {
  components: {
    VueTagsInput
  },
  props: {
    id: {
      type: String,
      default: () => (+new Date()).toString()
    },
    value: {
      type: [Array],
      default: () => null
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: 'Search or add a tag'
    },
    separators: {
      type: Array,
      default: () => [';']
    },
    validation: {
      type: Array,
      default: () => []
    },
    options: {
      type: [Array, Function],
      default: undefined
    },
    addOnlyFromAutocomplete: {
      type: Boolean,
      default: false
    },
    autocompleteAlwaysOpen: {
      type: Boolean,
      default: false
    },
    autocompleteMinLength: {
      type: Number,
      default: 1
    },
    beforeAddingTag: {
      type: Function,
      default: obj => obj.addTag()
    },
    disabled: {
      type: Boolean
    }
  },
  data() {
    return {
      tag: '',
      tags: [],

      data: [],
      debounce: null
    };
  },
  computed: {
    validationMapped() {
      return this.validation ? this.validation.map(s => ({ rule: s, classes: 'ti-error' })) : [];
    },
    autocompleteItems() {
      return this.data.length ? this.data.map(text => ({ text })) : [];
    }
  },
  watch: {
    value: {
      handler() {
        this.tags = createTags(this.value || []);
      },
      immediate: true
    },
    tag: {
      async handler() {
        if (typeof this.options === 'function') {
          // this.data = [];

          clearTimeout(this.debounce);
          this.debounce = setTimeout(async () => {
            const options = await Promise.resolve().then(() => {
              return this.options(this.tag);
            });

            this.data = options;
          }, 25);
        } else {
          // this.data = this.options.filter(i => i.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1);
          this.data = this.options;
        }
      }
    }
  },
  methods: {
    change(tags) {
      const value = tags.map(({ text }) => text);
      this.$emit('input', value);
      this.$emit('change', value);
    }
  }
};
</script>

<style lang="scss">
.tags-wrapper {
  display: grid;
  grid-template-rows: max-content max-content;
  grid-gap: 5px;
  > label {
    font-weight: 500;
    font-size: 0.75rem;
    letter-spacing: 0.025em;
  }

  .vue-tags-input {
    max-width: unset !important;
    box-sizing: border-box;

    font-size: 0.85rem;
    font-weight: 400;
    border-radius: 2px;
    color: var(--theme-on-background-dark);
    background: var(--theme-background) !important;
    cursor: default;
    border-collapse: separate;

    position: relative;
    outline: none;
  }

  .vue-tags-input .ti-new-tag-input-wrapper {
    margin: 0 5px 5px 0;
  }
  .vue-tags-input .ti-new-tag-input {
    background: transparent;
    color: var(--theme-on-background);
    font-size: 0.85rem;
    font-weight: 400;
  }

  .vue-tags-input .ti-input {
    width: 100%;
    border: 1px solid var(--theme-on-background-dark);
    min-height: 32px;
    padding: 5px 5px 0 5px;
  }

  .vue-tags-input.ti-focus .ti-input {
    border-color: var(--theme-on-background);
    color: var(--theme-on-background);
  }

  .vue-tags-input .ti-autocomplete {
    background: var(--theme-background);
    border: 1px solid var(--theme-on-background-dark);
    border-top: none;
  }

  .vue-tags-input .ti-autocomplete ul {
    max-height: 150px;
    overflow-y: auto;
  }

  .vue-tags-input .ti-item {
    padding: 6px 8px;
    font-family: inherit;
    font-size: 0.85rem;
    font-weight: 400;
    white-space: initial;
    display: block;
    color: var(--theme-on-background);
    background: var(--theme-background);
  }

  .vue-tags-input .ti-item.ti-selected-item {
    background: var(--theme-surface);
    color: var(--theme-on-surface);
  }

  .vue-tags-input ::-webkit-input-placeholder {
    color: var(--theme-on-background-dark);
    font-size: 0.85rem;
  }

  .vue-tags-input .ti-tag {
    position: relative;
    padding: 3px 4px 2px 4px;
    background-color: var(--theme-primary);
    border: 1px solid var(--theme-primary);
    color: var(--theme-on-primary);
    margin: 0 5px 5px 0;
    border-radius: 2px;
  }

  .vue-tags-input .ti-content {
    max-width: 250px;
    overflow: hidden;
  }

  .vue-tags-input .ti-tag.ti-invalid {
    background-color: var(--theme-error);
    border-color: var(--theme-error);
    color: var(--theme-on-error);
  }

  .vue-tags-input .ti-duplicate span,
  .vue-tags-input .ti-new-tag-input.ti-duplicate {
    text-decoration: line-through;
  }

  .vue-tags-input .ti-tag:after {
    transition: transform 0.2s;
    position: absolute;
    content: '';
    height: 2px;
    width: 108%;
    left: -4%;
    top: calc(50% - 1px);
    background-color: #000;
    transform: scaleX(0);
  }

  .vue-tags-input .ti-deletion-mark:after {
    transform: scaleX(1);
  }

  .vue-tags-input .ti-actions {
    font-size: 9px;
  }
}
</style>
