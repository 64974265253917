<template>
  <div id="app" class="application">
    <p-header></p-header>
    <main v-if="isAuthenticated">
      <p-shell />
    </main>
    <main v-else-if="isReady">
      <p-login />
    </main>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import Login from '@/components/Login';
import Header from '@/components/Header';
import Shell from '@/components/Shell';

export default {
  components: {
    'p-login': Login,
    'p-header': Header,
    'p-shell': Shell
  },
  computed: {
    ...mapState({
      isReady: s => s.identity.isReady,
      isAuthenticated: s => s.identity.isAuthenticated
    })
  },
  created() {
    this.$store.dispatch('identity/initialize');
  }
};
</script>

<style lang="scss">
:root {
  // --theme-default: #607d8b;
  // --theme-default-light: rgba(0, 0, 0, 0.12);
  // --theme-default-dark: #455a64;

  --theme-primary: #42b983;
  --theme-primary-light: #78cfa8;
  --theme-primary-dark: #2e815b;

  --theme-secondary: #ffd600;
  --theme-secondary-light: #fff9c4;
  --theme-secondary-dark: #f9a825;

  // #e80a89
  --theme-highlight: rgba(255, 255, 255, 0.08);

  --theme-success: #2e815b;
  --theme-error: #b00020;
  --theme-warning: #f9a825;
  --theme-background: #212121;

  --theme-surface: #181818;
  // --theme-surface: #424242;

  // --theme-on-default: #fff;
  --theme-on-primary: #fff;
  --theme-on-secondary: #2c3e50;
  --theme-on-error: #fff;
  --theme-on-success: #fff;

  --theme-on-background: #fff;
  --theme-on-background-dark: #999999;
  --theme-on-background-accent: #999999;
  --theme-on-surface: #fff;
  --theme-on-surface-dark: #999999;
  --theme-on-surface-accent: #999999;
}
</style>

<style lang="scss">
html,
body {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 100%;
  line-height: 1.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--theme-background);
  color: var(--theme-on-background);
  width: 100%;
  height: 100%;
}

body {
  // padding: 5px;
  min-width: 1200px;
}

a {
  color: var(--theme-primary);
  text-decoration: none;
  font-weight: 500;

  &.disabled,
  &[disabled] {
    opacity: 0.7;
    cursor: not-allowed;
    &:hover {
      color: var(--theme-primary);
    }
  }

  &:hover {
    color: var(--theme-primary-dark);
  }
}

hr {
  border-color: var(--theme-highlight);
  margin: 1.5rem 0;
}

.application {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: max-content minmax(0, 1fr);
  grid-gap: 0.5rem;
}

main {
  overflow-y: scroll;
  overflow-x: auto;
}
aside {
  overflow-y: auto;
  overflow-x: hidden;
}
</style>

<style lang="scss">
em {
  color: var(--theme-on-secondary);
  background: var(--theme-secondary-dark);
  padding-left: 2px;
  padding-right: 2px;

  &.m1 {
    background-color: #d4e157;
    color: var(--theme-on-secondary);
  }
  &.m2 {
    background-color: #ffee58;
    color: var(--theme-on-secondary);
  }
  &.m3 {
    background-color: #ffa726;
    color: var(--theme-on-secondary);
  }
  &.m4 {
    background-color: #ef5350;
    color: var(--theme-on-secondary);
  }
  &.m5 {
    background-color: #ec407a;
    color: var(--theme-on-secondary);
  }
  &.m6 {
    background-color: #ab47bc;
    color: var(--theme-on-secondary);
  }
  &.m7 {
    background-color: #7e57c2;
    color: var(--theme-on-secondary);
  }
  &.m8 {
    background-color: #5c6bc0;
    color: var(--theme-on-secondary);
  }
  &.m9 {
    background-color: #42a5f5;
    color: var(--theme-on-secondary);
  }
  &.m10 {
    background-color: #66bb6a;
    color: var(--theme-on-secondary);
  }
}
</style>

<style>
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-button {
  background-color: var(--theme-surface);
}
::-webkit-scrollbar-track {
  background-color: var(--theme-surface);
}
::-webkit-scrollbar-track-piece {
  background-color: var(--theme-background);
}
::-webkit-scrollbar-thumb {
  height: 50px;
  background-color: var(--theme-surface);
  border-radius: 2px;
  box-shadow: 0px 0px 1px 1px var(--theme-on-surface-dark) inset;
}
::-webkit-scrollbar-corner {
  background-color: var(--theme-surface);
}
::-webkit-resizer {
  background-color: var(--theme-surface);
}

div[contenteditable] {
  outline: none;
}
</style>
