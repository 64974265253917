<template>
  <p-modal :visible="true">
    <span slot="title">Define roadmap stage for "{{ title }}" step</span>
    <div class="roadmap-stage-modal">
      <p-multiselect v-model="value" :options="options" />
    </div>

    <div class="footer" slot="footer">
      <p-button @click="$emit('cancel')">Cancel</p-button>
      <p-button color="secondary" @click="onConfirm">Ok</p-button>
    </div>
  </p-modal>
</template>

<script>
import Multiselect from '@/components/common/Multiselect';
import Button from '@/components/common/Button';
import Modal from '@/components/common/Modal';

export default {
  components: {
    'p-button': Button,
    'p-multiselect': Multiselect,
    'p-modal': Modal
  },
  props: {
    currentOption: {
      type: String,
      default: null
    },
    options: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      value: this.currentOption
    };
  },
  methods: {
    onConfirm() {
      this.$emit('confirm', this.value);
    }
  }
};
</script>
<style lang="scss" scoped>
.roadmap-stage-modal {
  padding: 20px;
}
</style>
