export const SYNC_STATUS = {
  ['failed']: {
    label: 'Failed',
    color: '#da2d2d'
  },
  ['synchronized']: {
    label: 'Synchronized',
    color: '#2e815b'
  }
};
