<template>
  <div class="input-with-variables">
    <p-text-field
      v-if="!multiline"
      :multiline="false"
      class="text-field"
      :disabled="disabled"
      :placeholder="placeholder"
      :label="label"
      :value="value"
      @change="onChange"
    />
    <TextEditor v-else class="text-field" :disabled="disabled" :placeholder="placeholder" :label="label" :value="value" @input="onChange" />

    <template v-if="!disabled">
      <div v-if="!areControlsShown" class="button-wrapper">
        <p-button variant="text" @click="showControls">+var</p-button>
      </div>
      <div v-else class="variable-wrapper">
        <p-multiselect
          v-model="contract"
          placeholder="Select"
          :get-label-callback="contract => contract.name"
          :options="availableContracts"
          @input="onContractChange"
        />
        <p-multiselect v-if="contract" v-model="type" :options="availableTypes" placeholder="" />
        <p-button v-if="type" @click="addVar">Insert</p-button>
        <p-button v-if="!type" @click="cancelAdding">Cancel</p-button>
      </div>
    </template>
  </div>
</template>

<script>
import TextField from '@/components/common/TextField';
import Button from '@/components/common/Button';
import Multiselect from '@/components/common/Multiselect';
import { mapVariablesFromContracts } from './utils';
import TextEditor from '@/components/common/editor/Editor';

import { mapState } from 'vuex';

export default {
  components: {
    'p-text-field': TextField,
    'p-button': Button,
    'p-multiselect': Multiselect,
    TextEditor
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    code: {
      type: String,
      default: ''
    },
    source: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean
    },
    excludeContracts: {
      type: Array,
      default: () => []
    },
    additionalOptions: {
      type: Object,
      default: () => {}
    },
    multiline: {
      type: Boolean
    },
    dataType: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      areControlsShown: false,
      contract: null,
      type: null
    };
  },
  computed: {
    ...mapState({
      contracts: s => s.milestones.contracts
    }),
    availableContracts: function() {
      const eventSource = this.contracts.eventSources.find(source => source.source === this.source);
      const event = eventSource.events.find(event => event.code === this.code || event.name === this.code) || eventSource.events[0];
      const additional = this.additionalOptions ? Object.keys(this.additionalOptions).map(key => ({ name: key })) : [];
      const filtered = event.contract.filter(c => !this.excludeContracts.includes(c.name));
      return [...filtered, ...additional];
    },
    availableTypes: function() {
      if (!this.contract) {
        return [];
      }
      if (this.additionalOptions && this.additionalOptions[this.contract.name]) {
        return this.additionalOptions[this.contract.name];
      }

      return mapVariablesFromContracts(this.contracts, this.source, this.code, this.dataType, this.contract.name);
    }
  },

  methods: {
    cancelAdding() {
      this.areControlsShown = false;
      this.contract = null;
      this.type = null;
    },
    showControls() {
      this.areControlsShown = true;
    },
    onContractChange(e) {
      this.contract = e;
      this.type = null;
    },
    addVar() {
      const str = `${this.value} ${this.type}`;
      this.onChange(str);
      this.areControlsShown = false;
      this.contract = null;
      this.type = null;
    },
    onChange(e) {
      this.cancelAdding();
      this.$emit('input', e);
    }
  }
};
</script>
<style lang="scss" scoped>
.input-with-variables {
  .text-field {
    margin-bottom: 5px;
  }

  .variable-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 5px;
  }
  .button-wrapper {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
