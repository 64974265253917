<template>
  <div class="add-assignee">
    <div class="form-row">
      <div class="select">
        <p-multiselect
          :value="value"
          :options-async="availableContracts"
          :disabled="disabled"
          :label="label"
          placeholder="Start typing to insert assignee from list or add variable"
          :multiple="true"
          :search-change-callback="suggestMember"
          @input="submit"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';

import Multiselect from '@/components/common/Multiselect';
import httpClient from '@/utils/httpClient';
import { mapVariablesFromContracts } from './utils';

export default {
  components: {
    'p-multiselect': Multiselect
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    code: {
      type: String,
      default: ''
    },
    source: {
      type: String,
      default: ''
    },
    additionalOptions: {
      type: Array,
      default: () => []
    },
    excludeContracts: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean
    },
    label: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      emailToAdd: ''
    };
  },
  computed: {
    ...mapState({
      contracts: s => s.milestones.contracts
    }),
    availableContracts: function() {
      if (!this.contracts.eventSources) {
        return [];
      }
      const options = mapVariablesFromContracts(this.contracts, this.source, this.code, 'people', undefined, this.excludeContracts);

      return [...options, ...this.additionalOptions.filter(option => !options.includes(option))];
    }
  },
  methods: {
    async suggestMember(s) {
      if (s?.length) {
        const result = await httpClient.get('/api/user-suggest/assignees?q=' + s);

        const members = result.reduce((acc, curr) => {
          let label;
          if (curr.name && !curr.email) {
            label = curr.name;
          } else {
            label = curr.email;
            acc.push(`${curr.email.split('@')[0]} assistants`);
          }
          acc.push(label);
          return acc;
        }, []);
        const filteredContracts = this.availableContracts.filter(contract => {
          return contract.toLowerCase().includes(s.toLowerCase());
        });
        return [s, ...filteredContracts, ...members];
      } else {
        return [...this.availableContracts];
      }
    },
    submit(e) {
      this.$emit('input', e);
    }
  }
};
</script>
<style lang="scss" scoped>
.responsible {
  margin-bottom: 20px;
}
.add-assignee {
  .form-row {
    .select {
      flex: 0 0 80%;
      margin-bottom: 10px;
    }
  }
}
</style>
