var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isReady || _vm.isGetRequestPending)?_c('section',{staticClass:"drawings"},[_c('p-loading')],1):_c('section',{staticClass:"drawings"},[_c('header',[_c('h1',[_vm._v("Drawings")]),_c('section',{staticClass:"action-wrapper"},[(_vm.$hasPermission('templates.write'))?_c('router-link',{attrs:{"to":"/drawings/create"}},[_vm._v("+ new")]):_vm._e()],1)]),_c('p-list',{attrs:{"name":"drawings","data":_vm.collection,"total":_vm.total,"metadata":{
      name: { label: 'Name', width: 'minmax(0, 3fr)' },
      description: { label: 'Description', width: 'minmax(0, 3fr)' },
      tags: { label: 'Tags', width: 'minmax(100px, 2fr)' },
      modifiedAt: { label: 'Last modification', width: '175px' }
    }},on:{"edit":_vm.edit},scopedSlots:_vm._u([{key:"__actions",fn:function(ref){
    var item = ref.item;
return [_c('p-button',{attrs:{"color":"primary","variant":"text","title":"Download template"},on:{"click":function($event){return _vm.download(item.contentUrl)}}},[_vm._v("⤓")]),_c('p-button',{attrs:{"variant":"text","title":"Edit this drawing"},on:{"click":function($event){return _vm.edit(item.id)}}},[_vm._v(" ✎")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }