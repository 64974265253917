<template>
  <p-modal :visible="true">
    <div class="variables-modal">
      <div class="holder">
        <h3>Custom variables:</h3>
        <div v-for="(property, index) in localRealVariables" :key="index" class="block">
          <div class="title">
            <p-button variant="text" style="font-size: 2rem" @click="deleteProperty(index)"><md-icon name="close" /></p-button>
            <span>{{ camelCase(property.name) }}</span>
          </div>
          <div class="row">
            <p-text-field v-model="property.name" label="Property name" />
            <p-multiselect v-model="property.type" label="Type" :options="['date', 'datetime', 'string', 'number', 'people']" />
          </div>

          <template v-if="property.type">
            <span v-if="property.type !== 'date' && property.type !== 'datetime'" class="label">Value:</span>
            <input-with-variables
              v-if="property.type === 'string' || property.type === 'number'"
              v-model="property.value"
              :additional-options="{ 'milestone.variables': simpleVariables(camelCase(property.name)) }"
              :source="source.source"
              :code="source.code"
            />
            <due-date-with-variables
              v-if="property.type === 'date' || property.type === 'datetime'"
              v-model="property.value"
              :source="source.source"
              :code="source.code"
              :additional-options="dateVariables(camelCase(property.name))"
            />
            <assignees-with-variables
              v-if="property.type === 'people'"
              v-model="property.value"
              :additional-options="peopleVariables(camelCase(property.name))"
              :source="source.source"
              :code="source.code"
            />
          </template>
        </div>
        <p-button color="primary" @click="addVariable">Add variable</p-button>
      </div>

      <div v-if="localFormVariables.length" class="holder">
        <h3>Form variables:</h3>
        <div v-for="(property, index) in localFormVariables" :key="index" class="block">
          <div class="form-name">{{ formName(property.source) }}</div>
          <div class="row">
            <p-text-field v-model="property.name" :disabled="true" label="Property name" />
            <p-multiselect v-model="property.type" :disabled="true" label="Type" :options="['date', 'string', 'number', 'people']" />
          </div>

          <template v-if="property.type">
            <span v-if="property.type !== 'date' && property.type !== 'datetime' && property.type !== 'file'" class="label">Value:</span>
            <input-with-variables
              v-if="property.type === 'string' || property.type === 'number'"
              v-model="property.value"
              :disabled="true"
              source="workflow-form"
            />
            <due-date-with-variables
              v-if="property.type === 'date' || property.type === 'datetime'"
              v-model="property.value"
              :disabled="true"
              source="workflow-form"
            />
            <assignees-with-variables v-if="property.type === 'people'" v-model="property.value" :disabled="true" :options="['op']" />
            <p-multiselect v-if="property.type === 'email'" v-model="property.value" :disabled="true" :options="['op']" :multiple="true" />
          </template>
        </div>
      </div>
    </div>

    <span slot="title">Milestone variables</span>
    <div class="footer" slot="footer">
      <p-button @click="$emit('close')">Cancel</p-button>
      <p-button color="secondary" @click="onConfirm">Ok</p-button>
    </div>
  </p-modal>
</template>

<script>
import Modal from '@/components/common/Modal';
import Button from '@/components/common/Button';

import TextField from '@/components/common/TextField';
import MdIcon from '@/components/common/MdIcon.vue';
import Multiselect from '@/components/common/Multiselect';

import InputWithVariables from './parts/InputWithVariables.vue';
import DueDateWithVariables from './parts/DueDateWithVariables.vue';
import AssigneesWithVariables from './parts/AssigneesWithVariables.vue';
import { mapState } from 'vuex';

function camelCase(str) {
  return str
    .toLowerCase()
    .split(' ')
    .map((word, index) => (index === 0 ? word : word[0] ? `${word[0].toUpperCase()}${word.slice(1)}` : ''))
    .join('');
}

export default {
  components: {
    'p-button': Button,
    'p-text-field': TextField,
    'p-modal': Modal,
    'p-multiselect': Multiselect,
    MdIcon,
    InputWithVariables,
    DueDateWithVariables,
    AssigneesWithVariables
  },
  props: {
    template: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      localFormVariables: this.template.variables?.filter((v) => v.source).map((v) => ({ ...v })) || [],
      localRealVariables: this.template.variables?.filter((v) => !v.source).map((v) => ({ ...v })) || []
    };
  },
  computed: {
    ...mapState({
      milestone: (s) => s.milestones.draft || s.milestones.item,
      contracts: (s) => s.milestones.contracts
    }),
    allVariables() {
      return [
        ...this.localFormVariables,
        ...this.localRealVariables.map((v) => ({
          ...v,
          property: camelCase(v.name),
          saveTo: `{{milestone.variables.${camelCase(v.name)}}}`
        }))
      ];
    },
    source() {
      const start = this.milestone.events.find((e) => e.type === 'create');
      const source = this.contracts.eventSources.find((e) => e.events.some((p) => p.code === start.code));
      return { source: source.source, code: start.code };
    }
  },
  methods: {
    formName(source) {
      const path = this.template.paths.find((p) => p.from.step === source.step && p.from.port === source.port);

      if (!path) {
        return '';
      }

      const fromStep = this.template.steps.find((s) => s.id === path.from.step);
      const toStep = this.template.steps.find((s) => s.id === path.to.step);

      return path && `${path?.from?.action?.form?.name?.toUpperCase()} (${fromStep?.title} -> ${toStep?.title ?? 'NONE'})`;
    },
    onConfirm() {
      this.$store.commit('milestones/VARIABLES_CHANGED', {
        variables: this.allVariables
      });
      this.$emit('close');
    },
    addVariable() {
      this.localRealVariables.push({
        name: '',
        description: '',
        type: null,
        value: undefined,
        required: true
      });
    },
    camelCase,
    deleteProperty(index) {
      this.localRealVariables.splice(index, 1);
    },
    dateVariables(exclude) {
      const dates = this.allVariables.filter(
        (v) => (v.type === 'date' || v.type === 'datetime') && v.saveTo !== `{{milestone.variables.${exclude}}}`
      );
      return dates.map((d) => d.saveTo);
    },
    peopleVariables(exclude) {
      const people = this.allVariables.filter((v) => v.type === 'people' && v.saveTo !== `{{milestone.variables.${exclude}}}`);
      return people.map((d) => d.saveTo);
    },
    simpleVariables(exclude) {
      const vars = this.allVariables.filter((v) => v.saveTo !== `{{milestone.variables.${exclude}}}`);
      return vars.map((d) => d.saveTo);
    }
  }
};
</script>

<style lang="scss" scoped>
.variables-modal {
  padding: 20px;
  .footer {
    button {
      margin-right: 20px;
    }
  }
  .title {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .form-name {
    padding-bottom: 10px;
    font-size: 0.9rem;
    opacity: 0.5;
  }

  .row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
    margin-bottom: 10px;

    align-items: center;
  }
  .block {
    padding: 10px 0;
    border-bottom: 1px solid white;
    margin-bottom: 10px;
  }
  .label {
    font-weight: 500;
    font-size: 0.75rem;
    letter-spacing: 0.025em;
  }

  .holder {
    border: 1px solid white;
    padding: 10px;
    margin-bottom: 20px;
  }
}
</style>
