<template>
  <p-edit-specification v-if="$hasPermission('specifications.read')" />
  <p-not-found v-else />
</template>

<script>
import EditSpecification from './../components/specifications/EditSpecification';
import NotFound from './../components/NotFound.vue';

export default {
  components: {
    'p-edit-specification': EditSpecification,
    'p-not-found': NotFound
  }
};
</script>
