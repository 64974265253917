<template>
  <p-modal :visible="true" @close="$emit('close')">
    <span slot="title">{{ draft ? 'Save draft' : 'Save and activate'}}</span>
    <div class="commit-modal-content">
      <div class="row">{{ draft ? 'Comment for changes made in this draft' : 'Are you sure you want to ACTIVATE this Draft?'}}</div>
      <div>
        <TextField v-model="message" placeholder="Enter message" label="message(optional)" />
      </div>
    </div>
    <div slot="footer">
      <Button @click="$emit('close')">Cancel</Button>
      <Button v-if="draft" color="secondary" @click="onSaveDraft">Save draft</Button>
      <Button v-else color="secondary" @click="onSave">Save & activate</Button>
    </div>
  </p-modal>
</template>
<script>
import Modal from '@/components/common/Modal';
import Button from '@/components/common/Button';
import TextField from '@/components/common/TextField';
export default {
  components: {
    'p-modal': Modal,
    Button,
    TextField
  },
  props: {
    draft:{
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      message: ''
    };
  },
  methods: {
    onSave() {
      this.$emit('activate', this.message);
    },
    onSaveDraft() {
      this.$emit('saveDraft', this.message);
    }
  }
};
</script>
<style lang="scss" scoped>
.commit-modal-content {
  padding: 0 2rem;
  .row {
    margin: 20px 0;
  }
}
</style>
