<template>
  <p-list v-if="$hasPermission('forms.read')" />
  <p-not-found v-else />
</template>

<script>
import List from './../components/forms/List.vue';
import NotFound from './../components/NotFound.vue';
export default {
  components: {
    'p-not-found': NotFound,
    'p-list': List
  }
};
</script>
