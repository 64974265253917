<template>
  <p-modal :visible="true">
    <template slot="title"> Clone workflow "{{ target.title }}" </template>
    <div class="clone-confirmation">
      <div class="control">
        <p-text-field v-model="title" label="Title" :multiple="true" />
      </div>
      <div class="control">
        <p-multiselect :value="value" :multiple="true" :search-change-callback="suggestRefference" label="References" @input="addTag" />
      </div>
      <template v-if="pairs.length">
        <div class="title">Inventions count:</div>
        <ul>
          <li v-for="invention in pairs" :key="invention[0]" class="invention">{{ invention[0] }}: {{ invention[1] }}</li>
        </ul>
      </template>
    </div>
    <footer slot="footer">
      <p-button @click="$emit('cancel')">Cancel</p-button>
      <p-button color="secondary" :disabled="!pairs.length || !title.length" @click="$emit('confirm', { value, title })">Clone</p-button>
    </footer>
  </p-modal>
</template>
<script>
import Modal from '@/components/common/Modal';
import Button from '@/components/common/Button';
import Multiselect from '@/components/common/Multiselect';
import httpClient from '../../utils/httpClient';
import TextField from '@/components/common/TextField';

export default {
  components: {
    'p-modal': Modal,
    'p-button': Button,
    'p-multiselect': Multiselect,
    'p-text-field': TextField
  },
  props: {
    target: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      value: [],
      counts: {},
      pairs: [],
      title: `${this.target.title}(clone)`
    };
  },
  methods: {
    async addTag(e) {
      this.value = e;
      await this.fetchCounts();
    },
    async suggestRefference(e) {
      return [e];
    },
    async fetchCounts() {
      const result = {};
      for (let v of this.value) {
        if (!this.counts[v]) {
          const count = await httpClient.get(`/api/workflows/migration/inventions/${v}/count`);
          result[v] = count;
        } else {
          result[v] = this.counts[v];
        }
      }

      this.pairs = Object.entries(result);
    }
  }
};
</script>
<style lang="scss" scoped>
.clone-confirmation {
  padding: 20px;
  .title {
    margin: 20px 0;
  }
  .invention {
    font-size: 0.75rem;
  }

  .control {
    margin-bottom: 20px;
  }
}
</style>
