<template>
  <p-modal :visible="true">
    <span slot="title">Edit transitions</span>

    <section class="edit-connections-modal">
      <div>
        <div v-for="transition in transitions" :key="transition.key" class="accordion">
          <div class="transition-title" @click="toggle(transition.key)">
            <span>{{ expanded.includes(transition.key) ? '-' : '+' }}</span>
            <span>{{ transition.key }}</span>
            <span v-if="transition.hasChange" class="changed">(!)</span>
          </div>
          <div v-for="step in transition.steps" :key="step.next">
            <div v-if="expanded.includes(transition.key)" class="row">
              <p-checkbox v-model="step.enabled" :disabled="isInCompareMode" :label="step.next" :class="{ changed: step.changed }" />
              <p-text-field v-model="step.label" label="Label" :disabled="!step.enabled || isInCompareMode" />
              <p-text-field v-model="step.description" label="Description" :disabled="!step.enabled || isInCompareMode" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <footer slot="footer">
      <p-button @click="$emit('cancel')">Cancel</p-button>
      <p-button color="secondary" @click="onConfirm">Ok</p-button>
    </footer>
  </p-modal>
</template>

<script>
import Modal from '@/components/common/Modal';

import Button from '@/components/common/Button';
import { mapState } from 'vuex';
import Checkbox from '@/components/common/Checkbox.vue';
import TextField from '@/components/common/TextField';

export default {
  components: {
    'p-button': Button,
    'p-modal': Modal,
    'p-checkbox': Checkbox,
    'p-text-field': TextField
  },

  props: {
    stepProperties: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      transitions: [],
      expanded: []
    };
  },
  computed: {
    ...mapState({
      templates: (s) => s.milestones.templates
    }),
    availableTransitions() {
      if (this.stepProperties.transitions.length) {
        return this.stepProperties.transitions;
      }
      const currentStepTemplate = this.templates.steps.find((stepTemplate) => stepTemplate.type === this.stepProperties.type);
      return currentStepTemplate.transitions;
    },
    isInCompareMode() {
      return this.stepProperties.compareMode;
    }
  },
  created() {
    const currentStepTemplate = this.templates.steps.find((stepTemplate) => stepTemplate.type === this.stepProperties.type);
    const changes = [];
    if (this.isInCompareMode) {
      const transitionsChanged = this.stepProperties.changes.find((p) => p.fieldName === 'transitions');
      if (transitionsChanged) {
        const oldTransitions = [...transitionsChanged.curr];
        const newTransitions = [...transitionsChanged.next];

        newTransitions.reduce((acc, curr) => {
          const correspondingIndex = oldTransitions.findIndex((t) => t.current === curr.current && t.next === curr.next);
          if (correspondingIndex > -1) {
            const [transition] = oldTransitions.splice(correspondingIndex, 1);
            if (curr.enabled !== transition.enabled) {
              acc.push(curr);
            }
          }
          return acc;
        }, changes);
        changes.push(...oldTransitions);
      }
    }
    const map = currentStepTemplate.ports.reduce((acc, port) => {
      acc[port] = {};
      currentStepTemplate.ports.forEach((p) => {
        if (p === port) {
          return;
        }
        const changed = changes.find((c) => c.next === p && c.current === port);
        acc[port][p] = {
          next: p,
          current: port,
          enabled: false,
          label: '',
          description: '',
          changed: !!changed
        };
      });
      return acc;
    }, {});

    const groupped = this.availableTransitions.reduce((acc, transition) => {
      acc[transition.current][transition.next] = { enabled: true, ...transition };
      return acc;
    }, map);
    Object.entries(groupped).forEach(([key, value]) => {
      const values = Object.values(value);
      const hasChange = values.find(v => v.changed);
      this.transitions.push({
        hasChange: !!hasChange,
        key,
        steps: values.sort((a, b) => {
          if (a.enabled === b.enabled) {
            return 0;
          }
          if (a.enabled) {
            return -1;
          } else {
            return 1;
          }
        })
      });
    });
  },
  methods: {
    onConfirm() {
      const data = Object.values(this.transitions).reduce((acc, curr) => {
        const values = curr.steps.filter((s) => s.enabled);
        acc.push(...values);
        return acc;
      }, []);

      this.$emit('confirm', data);
    },
    toggle(key) {
      const index = this.expanded.findIndex((str) => str === key);
      if (index > -1) {
        this.expanded.splice(index, 1);
      } else {
        this.expanded.push(key);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.changed {
  color: var(--theme-warning);
}
.edit-connections-modal {
  padding: 20px;
  .accordion {
    .transition-title {
      padding: 10px;
      border: 1px solid white;
      margin-bottom: 10px;
      cursor: pointer;
      user-select: none;
      span {
        margin: 0 5px;
      }
    }
  }
  .row {
    display: grid;
    grid-template-columns: 100px 1fr 1fr;
    gap: 10px;
    align-items: center;
    margin-bottom: 10px;
  }
}
</style>
