import { factory, am } from './utils';

import httpClient from '@/utils/httpClient';

export default {
  namespaced: true,
  ...factory({})('docketingTasks', {
    async getCollection(filters) {
      const query = Object.entries(filters)
        .filter(([f, v]) => v != null)
        .map(([f, v]) => {
          if (Array.isArray(v)) {
            return v.map(item => `${f}=${item}`).join('&');
          }
          return `${f}=${v}`;
        })
        .join('&');

      return httpClient.get(`/api/streams/reports/docketing?status=to do&${query}`);
    }
  })
};
