<template>
  <p-modal :visible="!!application" class="add-tag-modal">
    <template slot="title">
      Add tag to application
    </template>
    <form class="add-tag-modal-content" @submit="addTag">
      <p-multiselect
        v-model="value"
        label="Tag"
        :taggable="true"
        :search-change-callback="query => suggestAsync('tags', query)"
        @tag="value = $event"
      ></p-multiselect>
    </form>
    <template slot="footer">
      <p-button color="secondary" variant="text" @click="close">Close</p-button>
      <p-button color="primary" :disabled="!isValid" @click="addTag">Add tag</p-button>
    </template>
  </p-modal>
</template>

<script>
import Modal from '@/components/common/Modal';
import Multiselect from '@/components/common/Multiselect';
import Button from '@/components/common/Button';

import httpClient from '@/utils/httpClient';

export default {
  components: {
    'p-modal': Modal,
    'p-button': Button,
    'p-multiselect': Multiselect
  },
  props: {
    application: {
      type: Object,
      required: false,
      default: () => null
    }
  },
  data() {
    return {
      value: ''
    };
  },

  computed: {
    isValid() {
      if (!this.value && !this.value.length) {
        return false;
      }

      if (!this.application || !this.application.tags || this.application.tags.includes(this.value)) {
        return false;
      }

      if (this.value && (this.value.includes(',') || this.value.includes(';'))) {
        return false;
      }

      return true;
    }
  },
  methods: {
    addTag() {
      if (!this.value && !this.value.length) {
        return;
      }

      if (this.application && this.application.tags.includes(this.value)) {
        return;
      }

      this.$emit('addTag', this.application, this.value);
      this.value = '';
    },
    close() {
      this.$emit('close');
      this.value = '';
    },
    suggestAsync(fieldName, query) {
      return httpClient.get(`/api/suggest/${fieldName}?q=${query}`);
    }
  }
};
</script>

<style lang="scss" scoped>
.add-tag-modal {
  .add-tag-modal-content {
    padding: 2rem;
  }

  footer {
    > button {
      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }
}
</style>
