<template>
  <label class="dropdown">
    <strong class="dropdown-label" @click="toggle"
      >{{ text }}<small class="dropdown-icon">{{ visible ? '&#x25B2;' : '&#x25BC;' }}</small></strong
    >
    <ul v-if="visible" class="dropdown-option-list">
      <li v-for="option in options" :key="option.id" class="dropdown-option-list-item" @click="change(option)">
        {{ option.label }}
      </li>
    </ul>
  </label>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      default: () => []
    },
    value: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      visible: false
    };
  },
  computed: {
    text() {
      const option = this.options.find(i => i.id === this.value);
      return option ? option.label : '';
    }
  },
  methods: {
    toggle() {
      this.visible = !this.visible;
    },
    hide() {
      this.visible = false;
    },
    change(option) {
      this.$emit('input', option.id, option);
      this.$emit('change', option.id, option);
      this.hide();
    }
  }
};
</script>

<style lang="scss" scoped>
.dropdown {
  display: inline-flex;
  position: relative;
  padding: 0 5px;

  .dropdown-label {
    font-weight: 500;
    font-size: 0.8rem;
    cursor: pointer;
  }

  .dropdown-icon {
    font-size: 7px;
    padding: 0 3px;
    transform: translateY(0px);
    display: inline-flex;
  }

  ul {
    position: absolute;
    top: 100%;
    right: 0;
    transform: translateY(8px);
    list-style: none;
    margin: 0;
    padding: 0;
    border: 1px solid var(--theme-on-background-dark);
    border-radius: 2px;
    font-size: 0.85rem;
    font-weight: 400;
    color: var(--theme-on-surface);
    background: var(--theme-surface);
    font-size: 0.8rem;
    z-index: 99;

    li {
      padding: 8px 12px;

      outline: none;
      transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

      &:hover {
        background: var(--theme-background);
        cursor: pointer;
      }
    }

    &:before {
      width: 0;
      height: 0;
      border-left: 6px solid rgba(0, 0, 0, 0);
      border-right: 6px solid rgba(0, 0, 0, 0);
      border-bottom: 7px solid var(--theme-on-background-dark);
      position: absolute;
      content: '';
      right: 6px;
      top: -8px;
    }

    &:after {
      width: 0;
      height: 0;
      border-left: 5px solid rgba(0, 0, 0, 0);
      border-right: 5px solid rgba(0, 0, 0, 0);
      border-bottom: 6px solid var(--theme-surface);
      position: absolute;
      content: '';
      right: 7px;
      top: -6px;
    }
  }
}
</style>
