<template>
  <form class="create-or-edit-specification-form" :class="{ dirty: $v.$anyDirty }" @submit.prevent.stop="submit">
    <!-- <div style="white-space: pre;">{{ JSON.stringify($v, null, 2) }}</div> -->
    <div>
      <p-text-field v-model.trim="name" :disabled="!$hasPermission('specifications.write')" :label="'Name'" />
      <div v-if="!$v.name.required" class="error">Name is required</div>
      <div v-if="!$v.name.minLength" class="error">Name must be at least {{ $v.name.$params.minLength.min }} characters.</div>
    </div>
    <div>
      <p-checkbox v-model="isPublic" :disabled="!$hasPermission('specifications.write')" :label="'Public'" />
    </div>
    <div>
      <p-multiselect
        v-model.trim="type"
        :disabled="!$hasPermission('specifications.write')"
        :options="['abstract', 'flowchart', 'summary', 'claims', 'boilerplate']"
        :label="'Type'"
        :searchable="false"
        placeholder=""
      />
      <div v-if="!$v.type.required" class="error">Type is required</div>
    </div>
    <div>
      <p-text-field v-model="description" :disabled="!$hasPermission('specifications.write')" :label="'Description'" :multiline="true" :rows="3" />
    </div>
    <div class="form-row">
      <p-multiselect
        v-model="tags"
        :multiple="true"
        :disabled="!$hasPermission('specifications.write')"
        :taggable="true"
        label="Tags"
        placeholder=""
        tag-placeholder=""
        @tag="tagAdded"
      ></p-multiselect>
    </div>
    <div>
      <p-text-field
        v-model="template"
        :disabled="!$hasPermission('specifications.write')"
        :label="'Template'"
        :multiline="true"
        :rows="12"
        :spellcheck="false"
      />
      <div v-if="!$v.template.required" class="error">Template content is required</div>
    </div>
    <div v-if="isRequestFailed" class="error">
      Failed to save specification. Check your input and try again.
    </div>
    <div>
      <p-button type="button" @click.prevent="$router.back()">Cancel</p-button>
      <p-button v-if="$hasPermission('specifications.write')" color="primary" type="submit" :disabled="isRequestPending">Save</p-button>
    </div>
  </form>
</template>

<script>
import { mapState } from 'vuex';
import { required, minLength } from 'vuelidate/lib/validators';

import Multiselect from '@/components/common/Multiselect';
import TextField from '@/components/common/TextField';
import Button from '@/components/common/Button';
import Checkbox from '@/components/common/Checkbox';

export default {
  components: {
    'p-text-field': TextField,
    'p-multiselect': Multiselect,
    'p-button': Button,
    'p-checkbox': Checkbox
  },
  props: {
    item: {
      type: Object,
      default: () => null
    },
    isRequestPending: {
      type: Boolean,
      default: false
    },
    isRequestFailed: {
      type: Boolean,
      default: false
    }
  },
  data() {
    if (this.item) {
      return {
        name: this.item.name,
        type: this.item.type,
        isPublic: this.item.isPublic,
        description: this.item.description,
        tags: this.item.tags,
        template: this.item.template
      };
    } else {
      return {
        name: '',
        type: '',
        isPublic: false,
        description: '',
        tags: [],
        template: ''
      };
    }
  },
  validations: {
    name: {
      required,
      minLength: minLength(4)
    },
    type: {
      required
    },
    template: {
      required
    }
  },
  computed: {
    ...mapState({
      isCreateRequestPending: s => s.specifications.isCreateRequestPending,
      isCreateRequestFailed: s => s.specifications.isCreateRequestFailed
    })
  },
  methods: {
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.$emit('submit', {
        name: this.name,
        type: this.type,
        isPublic: this.isPublic,
        description: this.description,
        tags: this.tags,
        template: this.template
      });
    },
    tagAdded(tag) {
      if (!Array.isArray(this.tags)) {
        this.tags = [];
      }

      if (this.tags.includes(tag)) {
        return;
      }
      this.tags.push(tag);
    }
  }
};
</script>

<style lang="scss" scoped>
.create-or-edit-specification-form {
  padding: 2rem;
  max-width: 768px;
  background: var(--theme-surface);
  overflow-y: auto;

  > * {
    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }
    &:last-child {
      display: flex;
      justify-content: flex-end;
    }
  }

  .error {
    font-size: 0.8rem;
    color: var(--theme-error);
    text-align: left;
    padding: 0.25rem 0;
    display: none;
  }
  &.dirty {
    .error {
      display: block;
    }
  }
}
</style>
