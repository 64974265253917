import { render, staticRenderFns } from "./Socket.vue?vue&type=template&id=05c3dc82&scoped=true&"
import script from "./Socket.vue?vue&type=script&lang=js&"
export * from "./Socket.vue?vue&type=script&lang=js&"
import style0 from "./Socket.vue?vue&type=style&index=0&id=05c3dc82&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05c3dc82",
  null
  
)

export default component.exports