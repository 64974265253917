import { Pie } from 'vue-chartjs';
import { SYNC_STATUS } from './fip_constants';

export default {
  extends: Pie,
  props: {
    aggs: {
      type: Object,
      required: true
    }
  },
  mounted() {
    if (!this.aggs) {
      return;
    }
    const data = [
      this.aggs.failed || 0,
      this.aggs.synchronized || 0
    ];

    const labels = Object.values(SYNC_STATUS).map(({ label }) => label);
    const colors = Object.values(SYNC_STATUS).map(({ color }) => color);

    this.renderChart(
      {
        datasets: [
          {
            data,
            backgroundColor: colors,
            borderWidth: 0
          }
        ],
        labels: labels
      },
      {
        responsive: true,
        maintainAspectRatio: false
      }
    );
  }
};
