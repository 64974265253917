<template>
  <p-email-connector v-if="$hasPermission('administration.read')" />
  <p-not-found v-else />
</template>

<script>
import NotFound from '@/components/NotFound.vue';
import EmailConnector from '@/components/emails-connector/EmailsConnector';

export default {
  components: {
    'p-email-connector': EmailConnector,
    'p-not-found': NotFound
  }
};
</script>
