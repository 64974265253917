import { factory, am } from './utils';

import httpClient from '@/utils/httpClient';

const CLONE = am(`CLONE`);

export default {
  namespaced: true,
  ...factory({
    state: {
      isClonePending: false,
      isCloneFailed: false
    },
    mutations: {
      [CLONE.STARTED](state) {
        state.isClonePending = true;
        state.isCloneFailed = false;
      },
      [CLONE.COMPLETED](state, item) {
        state.isClonePending = false;
        state.collection.push(item);
      },
      [CLONE.FAILED](state) {
        state.isClonePending = false;
        state.isCloneFailed = true;
      }
    },
    actions: {
      async clone({ commit }, { id }) {
        try {
          commit(CLONE.STARTED);
          const item = await httpClient.post(`/api/messages/${id}/clone`);

          commit(CLONE.COMPLETED, item);

          return item;
        } catch (e) {
          commit(CLONE.FAILED);
          throw e;
        }
      }
    }
  })('messages', {
    async getById(id) {
      return httpClient.get(`/api/messages/${id}`);
    },
    async getCollection() {
      return httpClient.get('/api/messages/');
    },
    async create(data) {
      return httpClient.post('/api/messages/', data);
    },
    async update(id, data) {
      return httpClient.patch(`/api/messages/${id}`, data);
    },
    async delete(id) {
      return httpClient.delete(`/api/messages/${id}`);
    }
  })
};
