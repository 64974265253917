<template>
  <p-modal :visible="true">
    <div class="milestone-import-modal">
      <p-text-field v-model="text" label="Paste import string" :multiline="true" />
    </div>
    <div slot="footer" class="buttons">
      <p-button color="secondary" @click="$emit('cancel')">Cancel</p-button>
      <p-button color="primary" @click="$emit('import', text)">Import</p-button>
    </div>
  </p-modal>
</template>
<script>
import Modal from '@/components/common/Modal';
import TextField from '@/components/common/TextField';
import Button from '@/components/common/Button';

export default {
  components: {
    'p-modal': Modal,
    'p-text-field': TextField,
    'p-button': Button
  },
  props: {
    milestone: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      text: ''
    };
  }
};
</script>
<style lang="scss" scoped>
.milestone-import-modal {
  padding: 20px;
}
.buttons {
  button {
    margin: 0 20px;
  }
}
</style>
