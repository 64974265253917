<template>
  <p-document-list v-if="$hasPermission('templates.read')" type="visio" />
  <p-not-found v-else />
</template>

<script>
import List from './../components/documents/visio/List.vue';
import NotFound from './../components/NotFound.vue';
export default {
  components: {
    'p-not-found': NotFound,
    'p-document-list': List
  },
  mounted() {}
};
</script>

<style lang="scss" scoped></style>
