<template>
  <p-create-specification v-if="$hasPermission('specifications')" />
  <p-not-found v-else />
</template>

<script>
import CreateSpecification from './../components/specifications/CreateSpecification.vue';
import NotFound from './../components/NotFound.vue';

export default {
  components: {
    'p-create-specification': CreateSpecification,
    'p-not-found': NotFound
  }
};
</script>
