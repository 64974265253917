<template>
  <p-workflow-editor v-if="$hasPermission('workflows.read')" />
  <p-not-found v-else />
</template>

<script>
import NotFound from './../components/NotFound.vue';
import WorkflowEditor from '../components/workflow-editor/WorkflowEditor';

export default {
  components: {
    'p-workflow-editor': WorkflowEditor,
    'p-not-found': NotFound
  }
};
</script>
