import { am } from './utils';

import httpClient from '@/utils/httpClient';
const GET_CONFIGURATIONS = am('GET_CONFIGURATIONS');
const GET_MODEL = am('GET_MODEL');
const UPSERT_MODEL = am('UPSERT_MODEL');
const DELETE_MODEL = am('DELETE_MODEL');

export default {
  namespaced: true,
  state: {
    providers: [],
    models: [],
    configurationsPending: false,
    configurationsFailed: false,

    updatePending: false,
    updateFailed: false,

    deletePending: false,
    deleteFailed: false,

    getModelPending: false,
    getModelFailed: false
  },
  getters: {
    getModelById: state => id => {
      return state.models?.find(m => m.id === id);
    }
  },
  mutations: {
    [GET_CONFIGURATIONS.STARTED](state) {
      state.configurationsFailed = false;
      state.configurationsPending = true;
      state.providers = [];
      state.models = [];
    },
    [GET_CONFIGURATIONS.COMPLETED](state, { configurations }) {
      state.configurationsPending = false;
      state.models = configurations?.models ?? [];
      state.providers = configurations?.providers ?? [];
    },
    [GET_CONFIGURATIONS.FAILED](state) {
      state.configurationsFailed = true;
      state.configurationsPending = false;
    },
    [UPSERT_MODEL.STARTED](state) {
      state.updateFailed = false;
      state.updatePending = true;
    },
    [UPSERT_MODEL.COMPLETED](state, { model }) {
      state.updatePending = false;
      var existing = state.models.find(m => m.id == model.id);

      if (existing) {
        var index = state.models.indexOf(existing);
        state.models[index] = model;
      } else {
        state.models.push(model);
      }
    },
    [UPSERT_MODEL.FAILED](state) {
      state.updateFailed = true;
      state.updatePending = false;
    },
    [DELETE_MODEL.STARTED](state) {
      state.deleteFailed = false;
      state.deletePending = true;
    },
    [DELETE_MODEL.COMPLETED](state, { id }) {
      state.deletePending = false;
      var existing = state.models.find(m => m.id !== id);

      if (!existing) {
        return;
      }

      var index = state.models.indexOf(existing);
      state.models.splice(index, 1);
    },
    [DELETE_MODEL.FAILED](state) {
      state.deleteFailed = true;
      state.deletePending = false;
    },
    [GET_MODEL.STARTED](state) {
      state.getModelFailed = false;
      state.getModelPending = true;
    },
    [GET_MODEL.COMPLETED](state) {
      state.getModelPending = false;
      state.getModelFailed = false;
    },
    [GET_MODEL.FAILED](state) {
      state.getModelFailed = true;
      state.getModelPending = false;
    }
  },
  actions: {
    async getConfigurations({ commit }) {
      try {
        commit(GET_CONFIGURATIONS.STARTED);
        const response = await httpClient.get(`/api/v2/nlp/configurations`);

        commit(GET_CONFIGURATIONS.COMPLETED, {
          configurations: response
        });

        return response;
      } catch (e) {
        commit(GET_CONFIGURATIONS.FAILED);
        throw e;
      }
    },
    async getModel({ commit }, id) {
      try {
        commit(GET_MODEL.STARTED);
        var model = await httpClient.get(`/api/v2/nlp/configurations/${id}`);
        commit(GET_MODEL.COMPLETED);

        return model;
      } catch (e) {
        commit(GET_MODEL.FAILED);
      }
    },
    async updateModel({ commit }, model) {
      try {
        commit(UPSERT_MODEL.STARTED);
        var model = await httpClient.put(`/api/v2/nlp/configurations/${model.id}`, model);
        commit(UPSERT_MODEL.COMPLETED, { model });

        return model;
      } catch (e) {
        commit(UPSERT_MODEL.FAILED);
        throw e;
      }
    },
    async createModel({ commit }, model) {
      try {
        commit(UPSERT_MODEL.STARTED);
        var newModel = await httpClient.post(`/api/v2/nlp/configurations`, model);
        commit(UPSERT_MODEL.COMPLETED, { model: newModel });

        return newModel;
      } catch (e) {
        commit(UPSERT_MODEL.FAILED);
        throw e;
      }
    },
    async deleteModel({ commit }, { id }) {
      try {
        commit(DELETE_MODEL.STARTED);
        await httpClient.delete(`/api/v2/nlp/configurations/${id}`);
        commit(DELETE_MODEL.COMPLETED, { id });
      } catch (e) {
        commit(DELETE_MODEL.FAILED);
        throw e;
      }
    }
  }
};
