<template>
  <p-create-application />
</template>

<script>
import CreateApplication from './../components/portfolio/CreateApplication.vue';

export default {
  components: {
    'p-create-application': CreateApplication
  }
};
</script>
