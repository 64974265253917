<template>
  <div class="accordion-item" @mouseover="upHere = true" @mouseleave="upHere = false">
    <div class="accordion-item-header">
      <div class="header-buttons">
        <Button class="button active" @click="toggleExpand">
          <md-icon :name="expanded ? 'chevron-up' : 'chevron-down'" />
        </Button>
      </div>
      <div class="title">
        <div class="prefix">C</div>
        <span>CHAT</span>
      </div>
      <div v-if="upHere && !isPromptStreaming" class="header-buttons">
        <div class="actions">
          <Button class="button" :class="{ active }" :disabled="isPromptStreaming" title="Delete" @click="deleteChat">
            <md-icon name="delete" />
          </Button>
        </div>
      </div>
    </div>
    <div v-if="expanded" class="accordion-item-content">
      <div class="settings">
        <Multiselect
          v-model="localSettings.model"
          label="Model"
          :options="modelsOptions"
          :disabled="isPromptStreaming"
          :get-label-callback="id => getLabel(id)"
          @input="onChange"
        />
        <TextField v-model="localSettings.temperature" :disabled="isPromptStreaming" label="Temperature" type="number" @input="onChange" />
        <TextField v-model="localSettings.max_tokens" :disabled="isPromptStreaming" label="Max tokens" type="number" @input="onChange" />
        <Checkbox v-model="localSettings.includeHistory" :disabled="isPromptStreaming" label="Include History" @input="onChange" />
      </div>
      <div class="prompt-body-content">
        <div v-for="(message, messageIndex) in localSettings.messages" :key="messageIndex" class="template-content">
          <div class="head">
            <span class="label">{{ message.role }}:</span>
          </div>
          <Code
            v-model="message.content"
            :read-only="!$hasPermission('prompts.write') || isPromptStreaming"
            :language="'plaintext'"
            class="editor"
            :auto-height="true"
            @input="onChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Button from '@/components/common/Button';
import MdIcon from '@/components/common/MdIcon';
import Code from '@/components/common/Code';
import Multiselect from '@/components/common/Multiselect';
import TextField from '@/components/common/TextField';
import Checkbox from '@/components/common/Checkbox';

export default {
  components: {
    Button,
    MdIcon,
    Code,
    Multiselect,
    TextField,
    Checkbox
  },
  props: {
    settings: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      localSettings: {
        ...this.settings,
        includeHistory: this.settings.includeHistory ?? false
      },
      expanded: false,
      upHere: false
    };
  },
  computed: {
    ...mapState({
      isPromptStreaming: state => state.prompts.sample.isRequestPending,
      active: state => !state.prompts.sample.isRequestPending,
      models: state => state.nlp.models || []
    }),
    modelsOptions() {
      return this.models.map(m => m.id);
    }
  },
  created() {
    this.localSettings.model = this.modelBackwardCompatibility(this.localSettings.model);
  },
  methods: {
    modelBackwardCompatibility(candidate) {
      var value = this.models.find(m => m.id == candidate);

      if (value) {
        return value.id;
      }

      for (const md of this.models) {
        if (!md.config) {
          continue;
        }
        var config = JSON.parse(md.config);
        if (config.deployment == candidate) {
          return md.id;
        }
      }

      return null;
    },
    getLabel(id) {
      return this.models.find(m => m.id === id)?.name;
    },
    onChange() {
      this.$emit('change', { ...this.localSettings });
    },
    toggleExpand() {
      this.expanded = !this.expanded;
    },
    deleteChat() {
      this.$emit('delete');
    }
  }
};
</script>

<style scoped lang="scss">
.accordion-item {
  .accordion-item-header {
    display: grid;
    grid-template-columns: max-content 1fr max-content;
    align-items: center;
    align-content: center;
    min-height: 50px;
    height: 100%;
    background-color: var(--theme-surface);
    border-bottom: 1px solid var(--theme-on-surface);
    padding: 0 10px;

    .header-buttons {
      display: flex;
      align-items: center;

      .actions {
        display: flex;
        padding-right: 5px;
      }
    }
  }

  .title {
    display: grid;
    gap: 5px;
    align-items: center;
    grid-template-columns: max-content 1fr;

    .prefix {
      color: var(--theme-surface);
      background-color: var(--theme-secondary);
      border: 1px solid;
      border-radius: 3px;
      font-family: 'Roboto';
      font-weight: 800;
      padding: 2px;
      user-select: none;
    }
  }

  .button {
    padding: 0px;
  }

  .active {
    cursor: pointer;
    :hover {
      background-color: var(--theme-on-surface);
      color: var(--theme-surface);
      border-radius: 2px;
    }
  }

  .accordion-item-content {
    padding: 10px;

    .settings {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 10px;
    }

    .prompt-body-content {
      padding: 10px;

      .template-content {
        margin: 10px 0;
        display: grid;
        gap: 10px;

        .label {
          font-weight: 500;
          font-size: 0.75rem;
          letter-spacing: 0.025em;
        }

        .editor {
          height: 200px;
        }

        .head {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
  }
}
</style>
