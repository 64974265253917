export function ensureArray(value) {
  return Array.isArray(value) ? value : value ? [value] : [];
}

export function ensureSingle(value) {
  return ensureArray(value)[0] || null;
}

export function ensureSingleValueCollection(value, splitBy = ',') {
  const single = ensureSingle(value);
  if (!single) {
    return [];
  }

  return single.split(splitBy);
}
