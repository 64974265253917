<template>
  <p-edit />
</template>

<script>
import EditDrawing from './../components/drawings/EditDrawing.vue';

export default {
  components: {
    'p-edit': EditDrawing
  }
};
</script>
