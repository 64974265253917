<template>
  <ul class="roadmap">
    <li v-for="step of roadmap" :key="step.name" class="step">
      <md-icon
        size="sm"
        :name="step.steps.length ? 'check-circle-outline' : 'close-circle-outline'"
        :color="step.steps.length ? 'success' : 'error'"
      />
    </li>
  </ul>
</template>
<script>
import MdIcon from '@/components/common/MdIcon';
export default {
  components: {
    MdIcon
  },
  props: {
    roadmap: {
      type: Array,
      default: () => []
    }
  }
};
</script>
<style lang="scss" scoped>
.roadmap {
  display: flex;
  align-items: center;
  .step {
    position: relative;
    margin-right: 10px;
    &:not(:last-child):after {
      content: '';
      display: block;
      width: 8px;
      position: absolute;
      right: -8px;
      top: 50%;
      border-bottom: 1px solid white;
    }
  }
}
</style>
