<template>
  <section class="create-application">
    <header>
      <h1>New Application</h1>
    </header>
    <form @submit.prevent="submit">
      <div class="form-row">
        <p-text-field v-model="value" :multiline="true" :rows="15"></p-text-field>
      </div>
      <div v-if="isCreateRequestFailed" class="error">
        Failed to save application. Check your input and try again.
      </div>
      <div class="form-row submit">
        <p-button type="button" @click.prevent="$router.back()">Cancel</p-button>
        <p-button color="primary" type="submit" :disabled="isCreateRequestPending || !isValid">Save</p-button>
      </div>
    </form>
    <footer></footer>
  </section>
</template>

<script>
import { mapState } from 'vuex';

import TextField from '@/components/common/TextField';
import Button from '@/components/common/Button';

export default {
  components: {
    'p-text-field': TextField,
    'p-button': Button
  },
  data() {
    return {
      value: '{ "id": "13472756", "tags": [ "ten", "eleven" ] }'
    };
  },
  computed: {
    ...mapState({
      isCreateRequestPending: s => s.portfolio.isCreateRequestPending,
      isCreateRequestFailed: s => s.portfolio.isCreateRequestFailed
    }),
    isValid() {
      try {
        const obj = JSON.parse(this.value);
        return obj.id && obj.id.length;
      } catch (e) {
        return false;
      }
    }
  },
  methods: {
    async submit() {
      if (!this.isValid) {
        return;
      }

      await this.$store.dispatch('portfolio/create', JSON.parse(this.value));
      this.$router.push({ path: `/portfolio` });
    },
    back() {
      this.$router.back();
    }
  }
};
</script>

<style lang="scss" scoped>
.create-application {
  width: 100%;
  header {
    width: 100%;
    padding: 1rem 2rem;
    border-bottom: 1px solid var(--theme-highlight);
    box-sizing: border-box;
  }

  h1 {
    color: var(--theme-on-surface);
    font-size: 1.5rem;
    font-weight: 700;
  }
  form {
    padding: 2rem;
    max-width: 768px;

    > * {
      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }
      &:last-child {
        display: flex;
        justify-content: flex-end;
      }
    }

    .error {
      font-size: 0.8rem;
      color: var(--theme-error);
      text-align: left;
      padding: 0.25rem 0;
      display: none;
    }
  }
}
</style>
