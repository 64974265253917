<template>
  <p-document-list v-if="$hasPermission('templates.read')" />
  <p-not-found v-else />
</template>

<script>
import List from './../components/documents/word/List.vue';
import NotFound from './../components/NotFound.vue';
export default {
  components: {
    'p-document-list': List,
    'p-not-found': NotFound
  },
  mounted() {}
};
</script>

<style lang="scss" scoped></style>
