<template>
  <section v-if="isGetRequestPending" class="workflows">
    <p-loading />
  </section>
  <div v-else class="workflows">
    <div class="top-line">
      <p-button v-if="$hasPermission('workflows')" color="primary" @click="createNewWorkflow">+ new</p-button>
      <p-button v-if="$hasPermission('workflows')" color="secondary" @click="onImport({})">Import workflow</p-button>
    </div>
    <workflow-item
      v-for="item in collection"
      :key="item.id"
      :item="item"
      :is-default="settings.defaultWorkflow === item.id"
      @setDefault="onItemSetDefault"
      @export="onExport(item)"
      @delete="onItemDelete"
      @clone="onItemClone(item)"
    />
    <clone-confirmation-popup v-if="cloneTarget" :target="cloneTarget" @confirm="onConfirm" @cancel="cloneTarget = null" />
    <export-modal v-if="importModal" @cancel="importModal = null" @import="onImported" />
  </div>
</template>
<script>
import { mapState } from 'vuex';
import Loading from './../common/Loading';
import WorkflowItem from './WorkflowItem';
import Button from '@/components/common/Button';
import ExportModal from './ImportWorkflowModel.vue';
import CloneConfirmationPopup from './CloneConfirmationPopup';

export default {
  components: {
    'p-loading': Loading,
    'p-button': Button,
    WorkflowItem,
    ExportModal,
    CloneConfirmationPopup
  },
  data() {
    return {
      cloneTarget: null,
      importModal: null
    };
  },
  computed: {
    ...mapState({
      collection: s => s.workflows.collection,
      total: s => s.workflows.total,
      isGetRequestPending: s => s.workflows.isGetRequestPending,
      settings: s => s.workflows.settings,
      isSettingsRequestPending: s => s.workflows.isSettingsRequestPending
    })
  },
  async created() {
    await Promise.all([this.$store.dispatch('workflows/getCollection'), this.$store.dispatch('workflows/getSettings')]);
  },
  methods: {
    async onItemDelete(id) {
      const confirmResult = await this.$confirm({
        title: 'Delete workflow?',
        message: `Are you sure you want to delete workflow?\n\nThis action can't be undone.`,
        confirm: 'Delete'
      });
      if (confirmResult) {
        try {
          await this.$store.dispatch('workflows/delete', id);
          this.$toast.success({
            title: 'Delete completed',
            message: `Workflow '${id}' was deleted.`
          });
        } catch (e) {
          const { message } = JSON.parse(await e.response.text());
          this.$toast.error({
            title: 'Delete failed',
            message: message ||`Please, try again later or contact our development team.`
          });
        }
      }
    },
    createNewWorkflow() {
      this.$router.push({ path: `/workflows/new` });
    },
    async onItemSetDefault(id) {
      const confirmResult = await this.$confirm({
        title: 'Set workflow default?',
        message: `Are you sure you want to set workflow ${id} default?.`,
        confirm: 'Set default'
      });
      if (confirmResult) {
        try {
          await this.$store.dispatch('workflows/setDefault', id);
          this.$toast.success({
            title: 'Success',
            message: `Workflow '${id}' was set default.`
          });
        } catch (e) {
          this.$toast.error({
            title: 'Set default failed',
            message: `Please, try again later or contact our development team.`
          });
        }
      }
    },
    onItemClone(item) {
      this.cloneTarget = item;
    },
    async onExport(item) {
      try {
        const exportWorkflow = await this.$store.dispatch('workflows/export', item.id);
        await navigator.clipboard.writeText(JSON.stringify(exportWorkflow, null, 2));
        this.$toast.success({
          message: `Import string copied to clipboard`
        });
      } catch (e) {
        this.$toast.error({
          title: 'Export failed',
          message: `Please, try again later or contact our development team.`
        });
      }
    },
    onImport(workflow) {
      this.importModal = workflow;
    },
    async onImported(str) {
      try {
        const data = JSON.parse(str);
        const templatesArray = [];
        if (data?.templates?.length) {
          for (const template of data.templates) {
            const { workflowId, createdAt, createdBy, updatedBy, updatedAt, id, ...other } = template;
            templatesArray.push(other);
          }
        }
        const payload = { workflow: { title: data.workflow.title, clientCodes: data.workflow.clientCodes }, templates: templatesArray };
        try {
          await this.$store.dispatch(`workflows/import`, payload);

          this.$toast.success({
            title: 'Import successful',
            message: `Workflow was imported`
          });
        } catch (e) {
          this.$toast.error({
            title: 'Import failed',
            message: 'Contact our developers or try again later.'
          });
        }
      } catch (e) {
        this.$toast.error({
          title: 'Import failed',
          message: `Reason: import string is invalid`
        });
      } finally {
        this.importModal = null;
        await this.$store.dispatch('workflows/getCollection');
      }
    },
    async onConfirm({ value, title }) {
      try {
        await this.$store.dispatch('workflows/clone', { id: this.cloneTarget.id, references: value, title });
        this.cloneTarget = null;
        await this.$store.dispatch('workflows/getCollection');
      } catch (e) {
        this.$toast.error({
          title: 'Clone failed',
          message: `Please, try again later or contact our development team.`
        });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.workflows {
  width: 100%;
  padding: 0 20px;
  .top-line {
    margin-bottom: 20px;
    * {
      margin-right: 20px;
    }
  }
}
</style>
