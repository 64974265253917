<template>
  <div class="workflow-item">
    <div class="actions">
      <p-button
        v-if="!isDefault && $hasPermission('workflows')"
        variant="text"
        title="Make this workflow default"
        style=""
        @click="$emit('setDefault', item.id)"
        >Make default</p-button
      >
      <p-button
        v-if="$hasPermission('workflows.read')"
        variant="text"
        title="Export this workflow"
        style="font-size: 1.5rem"
        @click="$emit('export')"
      >
        <md-icon size="md" name="application-export" />
      </p-button>
      <p-button v-if="$hasPermission('workflows.read')" variant="text" title="Edit this workflow" style="font-size: 1.5rem" @click="editWorkflow">
        &#9998;</p-button
      >
      <p-button
        v-if="$hasPermission('workflows')"
        variant="text"
        title="Clone this workflow"
        style="font-size: 2rem"
        @click="$emit('clone', item.id)"
      >
        &#x2398;</p-button
      >
      <p-button
        v-if="!isDefault && $hasPermission('workflows')"
        variant="text"
        title="Delete this workflow"
        style="font-size: 2rem"
        @click="$emit('delete', item.id)"
        >&times;</p-button
      >
    </div>
    <span v-if="isDefault" class="default-mark">Default</span>
    <div class="title">
      <router-link :to="`/workflows/${item.id}`"> {{ item.title }}</router-link>
    </div>
    <div class="description">
      <span>Created by: {{ item.createdBy }}</span>
      <span>Created at: {{ item.createdAt && new Date(item.createdAt).toLocaleString() }}</span>
    </div>
    <div class="client-list">
      <tag-collection v-if="item.clientCodes.length" :value="item.clientCodes" />
      <span v-else class="description">No clients assigned</span>
    </div>
  </div>
</template>
<script>
import TagCollection from '../common/TagCollection.vue';
import Button from '@/components/common/Button';
import MdIcon from '@/components/common/MdIcon';

export default {
  components: {
    TagCollection,
    'p-button': Button,
    MdIcon
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    isDefault: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    editWorkflow() {
      this.$router.push(`/workflows/${this.item.id}/edit`);
    }
  }
};
</script>
<style lang="scss" scoped>
.workflow-item {
  position: relative;
  padding: 20px 30px;
  margin-bottom: 20px;
  background-color: var(--theme-surface);
  .default-mark {
    color: var(--theme-primary);
    font-style: italic;
    font-size: 0.75rem;
  }
  &:hover {
    .actions {
      display: flex;
    }
  }
  .actions {
    position: absolute;
    right: 10px;
    top: 10px;
    display: none;
    align-items: center;
  }
  .title {
    font-size: 1.5rem;
  }
  .description {
    font-style: italic;
    font-size: 0.75rem;
    span {
      margin-right: 20px;
    }
  }
}
</style>
