import { cruduex } from './utils';

import httpClient, { jsonObjectToFormData } from '@/utils/httpClient';

export default {
  namespaced: true,
  ...cruduex('specification', {
    async getById(id) {
      const shape = await httpClient.get(`/api/shapes/${id}`);

      return {
        ...shape,
        thumbnailUrl: `/api/template-storage${shape.thumbnailUrl}`
      };
    },
    async getCollection() {
      const collection = await httpClient.get('/api/shapes/');

      return {
        ...collection,
        data: collection.data.map(item => ({
          ...item,
          thumbnailUrl: `/api/template-storage${item.thumbnailUrl}`
        }))
      };
    },
    async create() {
      throw new Error('Not implemented');
    },
    async update(id, data) {
      return httpClient.patch(`/api/shapes/${id}`, data);
    },
    async delete(id) {
      return httpClient.delete(`/api/shapes/${id}`);
    }
  })
};
