import { factory, am } from './utils';

import httpClient from '@/utils/httpClient';

const ADVANCED_SEARCH = am(`ADVANCED_SEARCH`);
const APPLY_QUERY = am(`APPLY_QUERY`);
const REMOVE_QUERY = am(`REMOVE_QUERY`);

export default {
  namespaced: true,
  ...factory({
    state: {
      search: null,
      isAdvancedSearchRequestPending: false,
      isAdvancedSearchRequestFailed: false,

      isApplyQueryPending: false,
      isRemoveQueryPending: false
    },
    mutations: {
      [ADVANCED_SEARCH.STARTED](state) {
        state.search = null;
        state.isAdvancedSearchRequestFailed = false;
        state.isAdvancedSearchRequestPending = true;
      },
      [ADVANCED_SEARCH.COMPLETED](state, { data, total, size, from }) {
        state.search = {
          data,
          total,
          size,
          from
        };
        state.isAdvancedSearchRequestPending = false;
      },
      [ADVANCED_SEARCH.FAILED](state) {
        state.isAdvancedSearchRequestFailed = true;
        state.isAdvancedSearchRequestPending = false;
      },
      [APPLY_QUERY.STARTED](state) {
        state.isApplyQueryPending = true;
      },
      [APPLY_QUERY.COMPLETED](state) {
        state.isApplyQueryPending = false;
      },
      [APPLY_QUERY.FAILED](state) {
        state.isApplyQueryPending = false;
      },
      [REMOVE_QUERY.STARTED](state) {
        state.isRemoveQueryPending = true;
      },
      [REMOVE_QUERY.COMPLETED](state) {
        state.isRemoveQueryPending = false;
      },
      [REMOVE_QUERY.FAILED](state) {
        state.isRemoveQueryPending = false;
      }
    },
    actions: {
      async search({ commit }, { body }) {
        try {
          commit(ADVANCED_SEARCH.STARTED);

          const { data, total } = await httpClient.post(`/api/search/advanced`, body);

          commit(ADVANCED_SEARCH.COMPLETED, {
            data,
            total,
            size: body.size || 10,
            from: body.from || 0
          });
        } catch (e) {
          commit(ADVANCED_SEARCH.FAILED);
          throw e;
        }
      },
      async apply({ commit }, { id }) {
        try {
          commit(APPLY_QUERY.STARTED);
          await httpClient.post(`/api/categorization/queries/${id}/apply`);

          commit(APPLY_QUERY.COMPLETED);
        } catch (e) {
          commit(APPLY_QUERY.FAILED);
          throw e;
        }
      },
      async remove({ commit }, { id }) {
        try {
          commit(REMOVE_QUERY.STARTED);
          await httpClient.post(`/api/categorization/queries/${id}/remove`);

          commit(REMOVE_QUERY.COMPLETED);
        } catch (e) {
          commit(REMOVE_QUERY.FAILED);
          throw e;
        }
      }
    }
  })('categorization', {
    async getCollection() {
      const data = await httpClient.get(`/api/categorization/queries`);

      return {
        data,
        total: data.length
      };
    },
    async create({ name, tags, text }) {
      return httpClient.post('/api/categorization/queries', {
        name,
        tags,
        text
      });
    },
    async delete(id) {
      return httpClient.delete(`/api/categorization/queries/${id}`);
    },
    async update(id, args) {
      return httpClient.patch(`/api/categorization/queries/${id}`, args);
    }
  })
};
