<template>
  <p-specification v-if="$hasPermission('specifications.read')" />
  <p-not-found v-else />
</template>

<script>
import Specification from './../components/specifications/Specification.vue';
import NotFound from './../components/NotFound.vue';

export default {
  components: {
    'p-specification': Specification,
    'p-not-found': NotFound
  }
};
</script>
