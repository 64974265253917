import Vue from 'vue';
import Toast from './../components/common/Toast';

import './ToastService.scss';

export class ToastService {
  constructor() {
    this.ComponentClass = Vue.extend(Toast);
  }

  get wrapper() {
    const wrapperId = 'toast-list-container';

    let wrapper = document.getElementById(wrapperId);
    if (wrapper) {
      return wrapper;
    }

    const el = document.createElement('ul');
    el.setAttribute('id', wrapperId);
    el.classList.add('toast-list');

    document.body.appendChild(el);

    return el;
  }
  success(props, duration = 3000) {
    this.show(
      {
        ...props,
        type: 'success'
      },
      duration
    );
  }

  error(props, duration = 5000) {
    this.show(
      {
        ...props,
        type: 'error'
      },
      duration
    );
  }

  show(props, duration) {
    const instance = new this.ComponentClass({
      propsData: props
    });
    instance.$mount();

    const li = document.createElement('li');
    li.classList.add('toast-list-item');
    li.appendChild(instance.$el);

    let timeout = null;
    const close = () => {
      this.wrapper.removeChild(li);
      instance.$destroy();
      timeout && clearTimeout(timeout);
    };

    if (duration) {
      timeout = setTimeout(close, duration);
    }
    instance.$on('close', close);

    this.wrapper.appendChild(li);
    setTimeout(() => li.classList.add('active'), 25);
  }
}

export default {
  install(Vue) {
    Vue.prototype.$toast = new ToastService(Vue);
  }
};
