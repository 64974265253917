import { cruduex } from './utils';

import httpClient, { jsonObjectToFormData } from '@/utils/httpClient';

export default {
  namespaced: true,
  ...cruduex('forms', {
    async getById(id) {
      return httpClient.get(`/api/forms/${id}`);
    },
    async getCollection() {
      return httpClient.get('/api/forms/');
    },
    async create(data) {
      return httpClient.request('/api/forms/', {
        json: false,
        method: 'POST',
        body: jsonObjectToFormData(data)
      });
    },
    async update(id, data) {
      return httpClient.request(`/api/forms/${id}`, {
        json: false,
        method: 'PATCH',
        body: jsonObjectToFormData(data)
      });
    },
    async delete(id) {
      return httpClient.delete(`/api/forms/${id}`);
    }
  })
};
