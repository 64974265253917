import Component from '../rete-components/Component';

export default (editor, eventSources, steps, start, miscellaneous) => {
  eventSources
    .filter(eventSource => eventSource.type === 'external')
    .forEach(eventSource => {
      const component = new Component(
        eventSource.source,
        eventSource.name,
        eventSource.events.map(e => ({ port: e.code, name: e.name })),
        eventSource.description,
        eventSource.source,
        true,
        start === eventSource.source,
        0,
        miscellaneous
      );
      editor.register(component);
    });

  const finishComponent = new Component('finish', 'FINISH', undefined, undefined, undefined, undefined, true);

  editor.register(finishComponent);

  const noWhereComponent = new Component('nowhere', 'nowhere', undefined, undefined, undefined, undefined, true);
  editor.register(noWhereComponent);

  steps.forEach(step => {
    const component = new Component(
      step.type,
      step.name,
      step.ports.map(port => ({ port, name: port })),
      step.description,
      'workflow',
      false,
      false,
      step.durationHours,
      miscellaneous
    );
    editor.register(component);
  });
};
