<template>
  <section class="home">
    <div class="logo-wrapper">
      <img src="./../assets/logo.svg" alt="Patent Hub logo" />
    </div>
    <div style="text-align:center; padding: 2rem; font-size: 1.5rem;">Hello, {{ username }}!</div>
  </section>
</template>

<script>
import { mapState } from 'vuex';

export default {
  components: {},
  computed: {
    ...mapState({
      email: s => s.identity.email,
      firstName: s => s.identity.firstName,
      lastName: s => s.identity.lastName
    }),
    username() {
      return [this.firstName, this.lastName].filter(s => !!s).join(' ') || this.email;
    }
  }
};
</script>

<style lang="scss" scoped>
.home {
  width: 100%;
  height: 100%;
  .logo-wrapper {
    text-align: center;
    padding: 5rem 0;
  }
  img {
    max-width: 300px;
    max-height: 300px;
  }
}
</style>
