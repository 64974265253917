<template>
  <p-bot-settings v-if="$hasPermission('users.read')" />
  <p-not-found v-else />
</template>

<script>
import UserSettings from '../components/users/UserSettings.vue';
import NotFound from './../components/NotFound.vue';

export default {
  components: {
    'p-bot-settings': UserSettings,
    'p-not-found': NotFound
  }
};
</script>
