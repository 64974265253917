<template>
  <p-edit-prompt v-if="$hasPermission('prompts.read')" />
  <p-not-found v-else />
</template>

<script>
import EditPrompt from './../components/prompts/EditPrompt';
import NotFound from './../components/NotFound.vue';

export default {
  components: {
    'p-edit-prompt': EditPrompt,
    'p-not-found': NotFound
  }
};
</script>
