<template>
  <p-teams v-if="$hasPermission('users.read')" />
  <p-not-found v-else />
</template>

<script>
import NotFound from './../components/NotFound.vue';
import Teams from './../components/users/Teams.vue';

export default {
  components: {
    'p-teams': Teams,
    'p-not-found': NotFound
  }
};
</script>
