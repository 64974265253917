import { factory, am } from './utils';

import httpClient from '@/utils/httpClient';

const GET_SETTINGS = am(`GET_SETTINGS`);
const SET_DEFAULT_WORKFLOW = am(`SET_DEFAULT_WORKFLOW`);

export default {
  namespaced: true,
  ...factory({
    state: {
      settings: {},
      isSettingsRequestPending: false,
      isDefaultRequestPending: false
    },
    mutations: {
      [GET_SETTINGS.STARTED](state) {
        state.isSettingsRequestPending = true;
      },
      [GET_SETTINGS.COMPLETED](state, settings) {
        state.isSettingsRequestPending = false;
        state.settings = settings;
      },
      [SET_DEFAULT_WORKFLOW.STARTED](state) {
        state.isDefaultRequestPending = true;
      },
      [SET_DEFAULT_WORKFLOW.COMPLETED](state, id) {
        state.settings.defaultWorkflow = id;
      }
    },
    actions: {
      async clone(obj, { id, references, title }) {
        await httpClient.post(`/api/workflows/v2/workflows/${id}/clone?${references.map(r => `references=${r}`).join('&')}&title=${title}`);
      },
      async import({ commit }, data) {
        return httpClient.post(`/api/workflows/v2/workflows/import`, data);
      },
      async export({ commit }, id) {
        return httpClient.get(`/api/workflows/v2/workflows/${id}/export`);
      },
      async getSettings({ commit }) {
        commit(GET_SETTINGS.STARTED);
        const settings = await httpClient.get('/api/workflows/v2/workflows/settings');
        commit(GET_SETTINGS.COMPLETED, settings || {});
      },
      async setDefault({ commit }, id) {
        commit(SET_DEFAULT_WORKFLOW.STARTED);
        await httpClient.post(`/api/workflows/v2/workflows/${id}/makeDefault`);
        commit(SET_DEFAULT_WORKFLOW.COMPLETED, id);
      }
    }
  })('workflows', {
    async getCollection() {
      const data = await httpClient.get(`/api/workflows/v2/workflows`);

      return data;
    },
    async getById(id) {
      const data = await httpClient.get(`/api/workflows/v2/workflows/${id}`);

      return data;
    },
    async create({ title, clientCodes }) {
      return httpClient.post('/api/workflows/v2/workflows', {
        title,
        clientCodes
      });
    },
    async delete(id) {
      return httpClient.delete(`/api/workflows/v2/workflows/${id}`);
    },
    async update(id, args) {
      return httpClient.put(`/api/workflows/v2/workflows/${id}`, args);
    }
  })
};
