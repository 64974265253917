<template>
  <div class="root">
    <FileSelect
      class="file"
      accept=".docx,.pptx,.pdf,.txt,.eml"
      title="From file"
      :disabled="processing || disabled"
      :multiple="multiline"
      @change="filesSelected"
    >
      <MdIcon size="md" :name="processing ? 'progress-clock' : 'file-document-outline'" />
    </FileSelect>
    <TextField v-model="localValue" :label="label" :disabled="processing || disabled" :multiline="true" @input="onChange" />
  </div>
</template>
<script>
import httpClient from '@/utils/httpClient';
import TextField from '@/components/common/TextField';
import MdIcon from '@/components/common/MdIcon';
import FileSelect from '@/components/common/FileSelect';

export default {
  components: {
    TextField,
    MdIcon,
    FileSelect
  },
  props: {
    value: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    multiline: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      localValue: this.value,
      processing: false,
      originalValue: true
    };
  },
  methods: {
    onChange() {
      this.$emit('input', this.localValue);
    },
    async filesSelected(files) {
      const file = files[0];
      if (!file) {
        return;
      }

      try {
        this.processing = true;
        const fileType = file.type;
        let textData = '';

        if (fileType === 'text/plain') {
          try {
            textData = await this.readFileAsText(file);
          } catch (error) {
            this.$toast.error({
              title: 'Failed',
              message: `Failed to read the .txt file.`
            });
            return;
          }
        } else if (
          fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
          fileType === 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
        ) {
          try {
            const resp = await this.extractTextFromOfficeFile(file);
            textData = Buffer.from(resp.base64Data, 'base64').toString('utf8');
          } catch (error) {
            this.$toast.error({
              title: 'Failed',
              message: `Failed to extract text from the office file.`
            });
            return;
          }
        } else if (fileType === 'application/pdf') {
          textData = await this.extractTextFromPdf(file);
        } else if (fileType === 'message/rfc822') {
          try {
            const resp = await this.extractTextFromEmlFile(file);
            textData = Buffer.from(resp.base64Data, 'base64').toString('utf8');
          } catch (error) {
            this.$toast.error({
              title: 'Failed',
              message: `Failed to extract text from the eml message file.`
            });
            return;
          }
        } else {
          this.$toast.warning({
            title: 'Failed',
            message: `Please upload a valid .docx, .pptx, .pdf, or .txt file.`
          });
          return;
        }

        if (this.originalValue) {
          this.localValue = textData;
          this.originalValue = false;
        } else {
          this.localValue += textData;
        }

        this.$emit('input', this.localValue);
      } finally {
        this.processing = false;
      }
    },
    async extractTextFromOfficeFile(file) {
      const officeFile = new FormData();
      officeFile.append('file', file);

      try {
        return await httpClient.post('/api/converters/office2txt', officeFile, false, { json: false });
      } catch (error) {
        throw new Error('Failed to convert office file.');
      }
    },
    async extractTextFromEmlFile(file){
      const eml = new FormData();
      eml.append('file', file);

      try {
        return await httpClient.post('/api/converters/eml2txt', eml, false, { json: false });
      } catch (error) {
        throw new Error('Failed to convert eml file.');
      }
    },
    async extractTextFromPdf(file) {
      const pdfFile = new FormData();
      pdfFile.append('files', file);

      const resultsIdResponse = await httpClient.post('/api/hub-pdf/ocr', pdfFile, false, { json: false });
      const jobId = resultsIdResponse.jobId;

      for (let iteration = 0; iteration < 60; iteration++) {
        await new Promise(resolve => setTimeout(resolve, 2 * 1000));

        const resultsResponse = await httpClient.get(`/api/hub-pdf/results/${jobId}`);

        if (resultsResponse === 'Accepted') {
          continue;
        }

        if (resultsResponse) {
          return resultsResponse[0].txtResults;
        }
      }

      return '';
    },
    async readFileAsText(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = error => {
          reject(error);
        };
        reader.readAsText(file);
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.root {
  display: grid;
  grid-template-columns: auto 1fr;

  .file {
    margin-top: 15px;
    padding: 0;
  }
}
</style>
