import ConnectionPlugin from 'rete-connection-plugin';
import VueRenderPlugin from 'rete-vue-render-plugin2';
import MinimapPlugin from 'rete-minimap-plugin';
import DockPlugin from 'rete-dock-plugin';
import ConnectionReroutePlugin from 'rete-connection-reroute-plugin';

export default editor => {
  editor.use(ConnectionPlugin);
  editor.use(VueRenderPlugin);
  editor.use(MinimapPlugin);
  editor.use(ConnectionReroutePlugin);
  editor.use(DockPlugin, {
    container: document.querySelector('.dock'),
    plugins: [VueRenderPlugin]
  });
};
