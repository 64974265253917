<template>
  <section v-if="!!item" class="with-claim-set">
    <div class="left">
      <section class="specification-content-wrapper">
        <div class="specification-content-title-wrapper">
          <h3>Template content:</h3>
          <div>
            <p-button
              variant="text"
              title="Toggle Help"
              class="toggle-help-button"
              :class="{ active: helpVisible }"
              @click="helpVisible = !helpVisible"
              ><p-icon name="help"></p-icon
            ></p-button>
            <p-button v-if="$hasPermission('specifications.write')" variant="text" color="primary" :disabled="isUpdateRequestPending" @click="save"
              >Save</p-button
            >
          </div>
        </div>
        <p-code
          :key="templateId"
          v-model="templateContent"
          :read-only="!$hasPermission('specifications.write')"
          :language="'handlebars'"
          :suggestions="suggestions"
        ></p-code>
      </section>
    </div>
    <div class="right">
      <section class="sample-input-wrapper">
        <div class="sample-input-title-wrapper">
          <h3>Context Object:</h3>

          <div>
            <p-file-select :disabled="!$hasPermission('specifications.write')" accept=".docx" @change="fileSelected">Upload document</p-file-select>
            <p-button variant="text" color="primary" :disabled="!contextObject || !document || isExecRequestPending" @click="exec">Run</p-button>
          </div>
        </div>
        <div class="sample-input-content-wrapper">
          <!-- <p-code v-if="!!contextObject" ref="contextObjectEd" language="json" :value="contextObject" :read-only="true" /> -->
          <p-json-view v-if="!!contextObject" :value="contextObject"></p-json-view>
          <div
            v-else
            style="
              border-radius: 2px;
              border: 1px solid var(--theme-on-background-dark);
              height: 100%;
              box-sizing: border-box;
              font-style: italic;
              display: flex;
              justify-content: center;
              align-items: center;
              pading: 1rem;
              color: var(--theme-on-background-dark);
              font-size: 0.8rem;
            "
          >
            Select a document to generate Context Object
          </div>
        </div>
      </section>
      <section class="sample-output-wrapper" :class="{ 'has-error': isExecRequestFailed }">
        <h3>Sample output:</h3>
        <textarea v-model="sampleResponse" disabled></textarea>
      </section>

      <section class="help" :class="{ active: helpVisible }">
        <p-specification-help :help="help" />
      </section>
    </div>
    <footer>
      <div v-if="isUpdateRequestPending || isExecRequestPending" class="overlay"></div>
    </footer>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import Button from '@/components/common/Button';
import Code from '@/components/common/Code';
import Icon from '@/components/common/Icon';
import Help from './Help';
import FileSelect from '@/components/common/FileSelect';
import JsonView from '@/components/common/JsonView';

export default {
  components: {
    'p-button': Button,
    'p-icon': Icon,
    'p-json-view': JsonView,
    'p-specification-help': Help,
    'p-file-select': FileSelect,
    'p-code': Code
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      templateContent: '',
      templateId: null,

      helpVisible: false,

      isReady: false
    };
  },
  computed: {
    ...mapState({
      isUpdateRequestPending: s => s.specifications.isUpdateRequestPending,
      isExecRequestPending: s => s.specifications.sample.isRequestPending,
      isExecRequestFailed: s => s.specifications.sample.isRequestFailed,
      sampleResponse: s => s.specifications.sample.response,
      help: s => s.specifications.help,
      contextObject: s => s.specifications.sample.contextObject,
      document: s => s.specifications.sample.document,
      isGetContextObjectRequestPending: s => s.specifications.sample.isGetContextObjectRequestPending,
      suggestions: s => {
        return s.specifications.help && Array.isArray(s.specifications.help.helperFunctions)
          ? s.specifications.help.helperFunctions.map(s => ({
              label: s.name,
              documentation: s.description,
              arguments: s.arguments,
              example: s.example
            }))
          : [];
      }
    })
  },
  async created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      await Promise.all([this.$store.dispatch('specifications/sample/initialize'), this.$store.dispatch('specifications/getHelp').catch(e => {})]);

      this.templateId = this.item.id;
      this.templateContent = this.item.template;

      this.isReady = true;
    },
    async save() {
      await this.$emit('submit', {
        id: this.templateId,
        template: this.templateContent
      });
    },
    async exec() {
      const lock = this.$lock();
      try {
        await this.$store.dispatch('specifications/sample/exec', {
          name: this.item.name,
          template: this.templateContent,
          document: this.document
        });
      } catch (e) {
        this.$toast.error({
          title: 'Failed to generate sample',
          message: `Please, try again later or contact our development team.`
        });
      } finally {
        lock.release();
      }
    },
    fileSelected([file]) {
      const reader = new FileReader();

      reader.addEventListener(
        'load',
        async () => {
          const lock = this.$lock();
          try {
            await this.$store.dispatch('specifications/sample/getContextObject', {
              name: file.name,
              template: this.templateContent,
              document: reader.result.split(',')[1]
            });
          } catch (e) {
            this.$toast.error({
              title: 'Failed to generate Context Object',
              message: `Please, try again later or contact our development team.`
            });
          } finally {
            lock.release();
          }
        },
        false
      );

      reader.readAsDataURL(file);
    }
  }
};
</script>

<style lang="scss" scoped>
.with-claim-set {
  width: 100%;
  height: 100%;
  margin: 0 0.5rem;
  overflow: hidden;
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  grid-template-rows: minmax(0, 1fr);
  grid-gap: 0.5rem;

  .left,
  .right {
    height: 100%;
    display: grid;
  }

  .right {
    grid-template-rows: minmax(0, 1fr) minmax(0, 1fr);
    position: relative;
    overflow: hidden;

    .help {
      position: absolute;
      height: 100%;
      width: 100%;
      left: 100%;
      transition: 0.2s transform ease-in-out;
      background: var(--theme-surface);
      box-sizing: border-box;

      &.active {
        transform: translateX(-100%);
      }
    }
  }

  > footer {
    .overlay {
      position: fixed;
      background: var(--theme-highlight);
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      cursor: wait;
    }
  }

  .specification-content-wrapper,
  .sample-input-wrapper,
  .sample-output-wrapper {
    display: grid;
    grid-template-rows: max-content minmax(0, 1fr);
    grid-gap: 0.5rem;
    padding: 0.5rem;
    h3 {
      text-transform: uppercase;
      font-size: 0.85rem;
      font-weight: 500;
      align-self: center;
      padding: 0.05rem 0.5rem;
    }
  }

  .specification-content-title-wrapper,
  .sample-input-title-wrapper {
    display: grid;
    grid-template-columns: minmax(0, 1fr) max-content;
    justify-content: baseline;
    height: 20px;

    > div {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }

  .toggle-help-button {
    &.active {
      background: var(--theme-surface);
      color: var(--theme-on-background-dark);
    }
  }

  .sample-input-content-wrapper {
    overflow-y: hidden;

    iframe {
      width: 100%;
      height: 100%;
      border: 0 none;
      overflow: hidden;
      margin: 0;
      padding: 0;
    }
  }

  textarea {
    resize: none;
    padding: 8px 12px;
    font-size: 0.85rem;
    font-weight: 400;
    border-radius: 2px;
    border: 1px solid var(--theme-on-background-dark);
    color: var(--theme-on-background);
    background: var(--theme-background);
    outline: none;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    white-space: pre-wrap;
    &:not([disabled]):hover {
      border-color: var(--theme-on-background);
      color: var(--theme-on-background);
    }

    &:not([disabled]):focus,
    &:not([disabled]):active {
      border-color: var(--theme-on-background);
      color: var(--theme-on-background);
      outline: none;
    }

    &[disabled] {
      opacity: 0.7;
    }
  }

  .sample-output-wrapper {
    &.has-error {
      h3 {
        color: var(--theme-error);
      }
      textarea {
        border-color: var(--theme-error);
        color: var(--theme-error);
      }
    }
  }
}
</style>
