<template>
  <div>
    <div v-if="index !== valueLength - 1" class="predicates-wrapper">
      <span class="predicate-title">
        <Button class="condition-group-remove-button" @click="$emit('remove')"><md-icon name="close" /></Button>
        <span>When:</span>
      </span>

      <Predicates :value="value" :enable-text-mode="true" :code="code" :source="source"
        :additional-variables="additionalPredicateVariables" @input="($e) => $emit('input', $e)" />
    </div>
    <div v-else-if="valueLength > 1">
      <span>Else:</span>
    </div>
    <slot />
    <Button v-if="index === valueLength - 1" @click="$emit('add')"><md-icon name="plus" /> condition</Button>
  </div>
</template>

<script>
import Button from '@/components/common/Button';

import Predicates from '../../workflow/wizard-parts/Predicates.vue';
import MdIcon from '@/components/common/MdIcon';
export default {
  components: {
    Button,
    MdIcon,
    Predicates
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    index: {
      type: Number,
      required: true
    },
    valueLength: {
      type: Number,
      required: true
    },
    code: {
      type: String,
      required: true
    },
    source: {
      type: String,
      required: true
    },
    predicates: {
      type: Array,
      default: () => []
    },
    additionalPredicateVariables: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style lang="scss" scoped>
.predicates-wrapper {
  padding: 10px 0;
  position: relative;

  .predicate-title {
    display: flex;
    align-items: center;
  }
}
</style>
