import Rete from 'rete';
import StepView from './StepView.vue';

export const typeStatusesMap = {
  finish: [{ port: 'finish', name: 'Finish' }],
  nowhere: [{ port: 'nowhere', name: 'nowhere' }]
};

const anyTypeSocket = new Rete.Socket('Any type');

export default class Component extends Rete.Component {
  constructor(type, title, statuses, description, source, isEventSource, notDeletable, durationHours, miscellaneous) {
    super(type);
    this.data.render = 'vue';
    this.data.type = type;
    this.data.title = title;
    this.data.description = description;
    this.data.component = StepView;
    this.data.statuses = statuses;

    this.data.props = {
      type,
      title,
      description,
      source,
      isEventSource,
      transitions: [],
      notDeletable: notDeletable,
      durationHours,
      isAddedToRoadMap: false,
      options: {
        notifications: {
          email: null
        },
        instructions: ''
      },
      isCriticalDate: false,
      miscellaneous
    };
  }

  builder(node) {
    const statuses = typeStatusesMap[this.data.type] || this.data.statuses;
    node.data.props = cloneRecursively({ ...this.data.props, ...node.data.props });
    statuses.forEach(status => {
      node.addInput(new Rete.Input(`${status.port}____input`, status.name, anyTypeSocket, true));
      node.addOutput(new Rete.Output(`${status.port}____output`, status.name, anyTypeSocket, true));
    });
  }
}

const cloneRecursively = obj => {
  if (typeof obj === 'string') {
    return obj;
  }
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (Array.isArray(value)) {
      acc[key] = value.map(a => cloneRecursively(a));
    } else if (value && typeof value === 'object') {
      acc[key] = cloneRecursively(value);
    } else {
      acc[key] = value;
    }
    return acc;
  }, {});
};
