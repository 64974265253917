<template>
  <p-modal :visible="true">
    <template slot="title">
      Select a specification
    </template>
    <template slot="footer">
      <p-button v-if="showCreateButton && $hasPermission('specifications.write')" @click="$router.push('/specifications/create')"
        >Create new</p-button
      >
      <p-button v-if="showCancelButton" color="secondary" @click="$emit('close')">Close</p-button>
    </template>
    <div v-if="isGetCollectionRequestPending" class="specification-list-wrapper">
      <p-loading />
    </div>
    <div v-else class="specification-list-wrapper">
      <ul class="specification-list">
        <li class="specification-list-item header">
          <th>Name</th>
          <th>Type</th>
          <!-- <th>Id</th> -->
          <th>&nbsp;</th>
        </li>
        <li v-for="specification of specifications" :key="specification.id" class="specification-list-item" @click="open(specification.id)">
          <div>{{ specification.name }}</div>
          <div>{{ specification.type }}</div>
          <!-- <td>{{ specification.id }}</td> -->
          <div>
            <div class="action-wrapper">
              <p-button variant="text" color="primary" @click.stop="editSpecification(specification.id)">&#9998;</p-button>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </p-modal>
</template>

<script>
import { mapState } from 'vuex';

import Loading from '@/components/common/Loading';
import Button from '@/components/common/Button';
import Modal from '@/components/common/Modal';

export default {
  components: {
    'p-button': Button,
    'p-modal': Modal,
    'p-loading': Loading
  },
  props: {
    showCancelButton: {
      type: Boolean,
      default: true
    },
    showCreateButton: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState({
      specifications: s => s.specifications.collection,
      isGetCollectionRequestPending: s => s.specifications.isGetCollectionRequestPending
    })
  },
  created() {
    this.$store.dispatch('specifications/getCollection');
  },
  methods: {
    open(id) {
      this.$router.push({ path: `/specifications/${id}` });
    },
    editSpecification(id) {
      this.$router.push({ path: `/specifications/${id}/edit` });
    }
  }
};
</script>

<style lang="scss" scoped>
.specification-list-wrapper {
  .specification-list {
    margin: 0;
    padding: 0;
    list-style: none;
    overflow-y: scroll;
    height: 100%;
    box-sizing: border-box;

    .specification-list-item {
      display: grid;
      grid-template-columns: minmax(0, 1fr) 100px 50px;
      grid-gap: 0;
      border-bottom: 1px solid var(--theme-highlight);
      font-size: 0.75rem;
      min-height: 1.75rem;
      align-items: center;
      padding: 0.25rem 0rem 0.25rem 1.5rem;
      .action-wrapper {
        display: none;
        justify-content: flex-end;
        font-size: 2rem;

        > * {
          margin-right: 0.5rem;
        }
      }

      &:hover {
        background-color: var(--theme-highlight);
        cursor: pointer;

        .action-wrapper {
          display: flex;
        }
      }

      &.header {
        color: var(--theme-on-background-dark);
        font-size: 0.9rem;

        color: var(--theme-on-background-dark);
      }
    }
  }
}
</style>
