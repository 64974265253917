<template>
  <ul v-if="total > size" class="pagination-list">
    <li class="pagination-list-item">
      <button :disabled="!(from > 0)" @click.prevent="$emit('navigate', { from: 0 })">
        <span>««</span>
      </button>
    </li>
    <li class="pagination-list-item">
      <button :disabled="!(previous >= 0)" @click.prevent="$emit('navigate', { from: previous })">
        <span>« Previous</span>
      </button>
    </li>
    <li class="pagination-list-item">
      <span>Page {{ page + 1 }}</span>
    </li>
    <li class="pagination-list-item">
      <button :disabled="!(next < total)" @click.prevent="$emit('navigate', { from: next })">
        <span>Next »</span>
      </button>
    </li>
    <li class="pagination-list-item">
      <button :disabled="!(from < last)" @click.prevent="$emit('navigate', { from: last })">
        <span>»»</span>
      </button>
    </li>
  </ul>
  <div v-else></div>
</template>

<script>
export default {
  props: {
    total: {
      type: Number,
      default: 0
    },
    from: {
      type: Number,
      default: 0
    },
    size: {
      type: Number,
      default: 0
    }
  },
  computed: {
    page() {
      return Math.ceil(this.from / this.size);
    },
    previous() {
      return this.page * this.size - this.size;
    },
    next() {
      return this.page * this.size + this.size;
    },
    last() {
      return (Math.ceil(this.total / this.size) - 1) * this.size;
    }
  }
};
</script>

<style lang="scss" scoped>
.pagination-list {
  list-style: none;
  margin: 0.5rem 0;
  padding: 0;
  display: flex;

  align-items: center;

  .pagination-list-item {
    font-size: 0.8rem;
    &:not(:last-child) {
      margin-right: 1rem;
    }

    button {
      cursor: pointer;
      display: inline-block;
      border: 0 none;
      background: transparent;
      outline: none;
      color: var(--theme-primary);
      text-decoration: none;
      font-weight: 500;
      padding: 0;
      margin: 0;

      &.disabled,
      &[disabled] {
        opacity: 0.7;
        cursor: not-allowed;
        &:hover {
          color: var(--theme-primary);
        }
      }

      &:hover {
        color: var(--theme-primary-dark);
      }
    }

    span {
      font-size: 0.75rem;
      display: inline-block;
      vertical-align: sub;
    }
  }

  i {
    font-style: italic;
  }
}
</style>
