<template>
  <section class="page">
    <header>
      <h1>Emails connector</h1>
    </header>
    <div class="content">
      <div class="emails-sync">
        <h2>Synchronize User Email Boxes</h2>
        <div class="first-row">
          <p-multiselect
            v-model="users"
            label="Emails"
            placeholder="Type to search for emails"
            :search-change-callback="suggestEmails"
            tag-placeholder=""
            :taggable="true"
            :multiple="true"
            :multiselect="true"
          />
          <p-date v-model="from" label="Start from" />
          <p-button :disabled="!users.length || !from || scheduled" color="primary" type="submit" class="submit-button" @click="submit">{{
            scheduled ? 'Done' : 'Sync'
          }}</p-button>
        </div>
        <div class="warning">
          Don't synchronize to many user email boxes and for a very distant date in the past. Consider doing synchronization at non business hours.
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { parse } from 'date-fns';
import httpClient from '@/utils/httpClient';
import Multiselect from '@/components/common/Multiselect';
import DateFiled from '@/components/common/DateField';
import Button from '@/components/common/Button';
import format from 'date-fns/fp/format/index.js';

export default {
  components: {
    'p-multiselect': Multiselect,
    'p-date': DateFiled,
    'p-button': Button
  },
  data() {
    return {
      from: '',
      users: [],
      scheduled: false
    };
  },
  methods: {
    async suggestEmails(s) {
      if (s?.length) {
        return httpClient.get('/api/auth/suggest/users?q=' + s);
      } else {
        return [];
      }
    },
    async submit() {
      if (!this.users.length || !this.from) {
        return;
      }

      this.scheduled = true;
      const from = parse(this.from, 'MMddyyyy', new Date());

      await httpClient.post('/api/ms-graph-email-connector/emails/users', {
        users: this.users,
        from: format('yyyy-MM-dd', from)
      });
    }
  }
};
</script>
W

<style lang="scss" scoped>
.page {
  display: grid;
  padding: 0 0.5rem 0 1rem;
  grid-template-columns: 1fr;
  grid-template-rows: max-content minmax(0, 1fr);

  width: 100%;
  height: 100%;
  overflow: hidden;

  > header {
    padding: 0.5rem 0.5rem 0;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    h1 {
      color: var(--theme-on-surface);
      font-size: 1.5rem;
      font-weight: 700;
      margin-right: 0.25rem;
    }
  }

  h2 {
    color: var(--theme-on-surface-dark);

    font-size: 1.2rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
  }

  .content {
    margin-top: 1rem;
    width: 100%;
    height: 100%;
    background-color: var(--theme-surface);
    display: grid;
    grid-template-rows: auto 1fr;
    padding: 1rem;

    .emails-sync {
      display: grid;
      grid-template-rows: auto auto;
      grid-gap: 0.5rem;

      width: 75%;
      height: 100%;

      .first-row {
        display: grid;
        grid-template-columns: 1fr 0.5fr auto;
        align-items: flex-end;
        grid-gap: 0.5rem;

        .submit-button {
          width: 75px;
        }
      }

      .warning {
        color: var(--theme-on-surface-dark);

        font-size: 0.9rem;
        font-weight: 700;
        margin-bottom: 0.5rem;
        font-style: italic;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}
</style>
