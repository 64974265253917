<template>
  <p-edit v-if="$hasPermission('templates.read')" />
  <p-not-found v-else />
</template>

<script>
import NotFound from './../components/NotFound.vue';
import Edit from './../components/documents/visio/EditDocument.vue';

export default {
  components: {
    'p-not-found': NotFound,
    'p-edit': Edit
  }
};
</script>
