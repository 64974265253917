<template>
  <p-prompt v-if="$hasPermission('prompts.read')" ref="prompt" />
  <p-not-found v-else />
</template>

<script>
import Prompt from './../components/prompts/Prompt.vue';
import NotFound from './../components/NotFound.vue';

export default {
  components: {
    'p-prompt': Prompt,
    'p-not-found': NotFound
  },
  async beforeRouteLeave(to, from, next) {
    if (await this.$refs.prompt.readyToLeave()) {
      next();
    } else {
      next(false);
    }
  }
};
</script>
