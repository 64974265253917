import { factory, am } from './utils';

import httpClient from '@/utils/httpClient';
const GET_MY_COLLECTION = am(`GET_MY_COLLECTION`);

export default {
  namespaced: true,
  ...factory({
    state: {
      my: null,
      isGetMyCollectionRequestPending: false,
      isGetMyCollectionRequestFailed: false
    },
    mutations: {
      [GET_MY_COLLECTION.STARTED](state) {
        state.my = null;
        state.isGetMyCollectionRequestFailed = false;
        state.isGetMyCollectionRequestPending = true;
      },
      [GET_MY_COLLECTION.COMPLETED](state, { data, total, size, from }) {
        state.my = {
          data,
          total,
          size,
          from
        };

        state.isGetMyCollectionRequestPending = false;
      },
      [GET_MY_COLLECTION.FAILED](state) {
        state.isGetMyCollectionRequestFailed = true;
        state.isGetMyCollectionRequestPending = false;
      }
    }
  })('inventions', {
    async getCollection({ text } = {}) {
      const size = 25;
      const response = await httpClient.get(`/api/inventions/search?qs=${text}&size=${size}`);

      return response.data;
    }
  })
};
