<template>
  <p-categorization v-if="$hasPermission('portfolio.read')" />
  <p-not-found v-else />
</template>

<script>
import Categorization from './../components/portfolio/Categorization.vue';
import NotFound from './../components/NotFound.vue';
export default {
  components: {
    'p-not-found': NotFound,
    'p-categorization': Categorization
  }
};
</script>
