<template>
  <section class="create-model">
    <p-edit-model @submit="create" />
  </section>
</template>

<script>
import { mapState } from 'vuex';
import EditForm from './_CreateOrEdit';

export default {
  components: {
    'p-edit-model': EditForm
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      models: s => s.administration.modelsConfigurations
    })
  },
  methods: {
    async create(model) {
      try {
        await this.$store.dispatch('nlp/createModel', model);
        this.$router.push({ path: `/administration` });

        this.$toast.success({
          title: 'Created',
          message: `Model '${model.name}' succesfully created`
        });
      } catch (error) {
        const { message } = JSON.parse(await error.response.text());
        this.$toast.error({
          title: 'Failed to create model',
          message: message
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.create-model {
  display: grid;
  grid-gap: 0.5rem;
  grid-template-rows: minmax(0, 1fr);
  grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);
  width: 100%;
  height: 100%;
  padding: 0 0.5rem 0 1rem;
}
</style>
