<template>
  <p-modal :visible="true">
    <span slot="title">
      <span v-if="isAnd">Edit "AND" conditions</span>
      <span v-else-if="isOr">Edit "OR" conditions</span>
      <span v-else> {{ action }} connection </span>
    </span>
    <section class="add-step-modal">
      <div>
        <div v-if="localConnections.length > 1 && !isAnd" class="tabs">
          <template v-for="(tab, index) in localConnections">
            <div :key="index" :class="{ active: activeTab === index }" class="tab" @click="onTabChange(index)">
              <span>
                {{ `${connections[tab.index].output.node.data.props.title}(${connections[tab.index].output.name})` }}
                <span v-if="tab.status === 'changed'">(!)</span>
              </span>

              <p-button variant="text" style="font-size: 2rem" @click.stop="deleteConnection(index)"><md-icon name="close"/></p-button>
            </div>
          </template>
        </div>
        <template v-if="isReady && !isAnd">
          <div class="row">
            <predicates
              :key="activeTab"
              v-model="localConnections[activeTab].predicates"
              :disabled="localConnections[activeTab].changes"
              :code="localConnections[activeTab].eventSource.code"
              :source="localConnections[activeTab].eventSource.source"
              :exclude-contracts="excludedContracts"
              :additional-variables="additionalPredicateVariables(activeTab)"
            />
          </div>
          <conditional-control-group
            v-if="!isOr && !isAnd"
            v-model="localConnections[activeTab]"
            :excluded-contracts="excludedContracts"
            :connections="connections[activeTab]"
            :additional-predicate-variables="additionalPredicateVariables(activeTab)"
          />
        </template>
        <div v-if="isAnd">
          <div v-for="(tab, index) in localConnections" :key="index">
            <div class="title" @click="onTabChange(index)">
              <span>{{ activeTab === index ? '-' : '+' }}</span>
              <span>
                {{ `${connections[tab.index].output.node.data.props.title}(${connections[tab.index].output.name})` }}
              </span>
              <p-button variant="contained" @click.stop="deleteConnection(index)">
                <MdIcon name="close" />
              </p-button>
            </div>
            <predicates
              v-if="activeTab === index"
              :key="activeTab"
              v-model="localConnections[activeTab].predicates"
              :disabled="localConnections[activeTab].changes"
              :code="localConnections[activeTab].eventSource.code"
              :source="localConnections[activeTab].eventSource.source"
              :exclude-contracts="excludedContracts"
              :additional-variables="additionalPredicateVariables(activeTab)"
            />
          </div>
        </div>
      </div>
    </section>
    <div slot="footer">
      <p-button @click="$emit('cancel')">Cancel</p-button>
      <p-button color="secondary" @click="onConfirm">{{ action }} connection</p-button>
    </div>
  </p-modal>
</template>

<script>
import Button from '@/components/common/Button';
import { mapState } from 'vuex';
import Modal from '@/components/common/Modal';
import Predicates from '../workflow/wizard-parts/Predicates.vue';
import MdIcon from '@/components/common/MdIcon';

import ConditionalControlGroup from './parts/ConditionalControlGroup.vue';

export default {
  components: {
    'p-button': Button,
    'p-modal': Modal,
    Predicates,
    MdIcon,
    ConditionalControlGroup
  },
  props: {
    connections: {
      type: Array,
      default: () => []
    },
    value: {
      type: Object,
      default: () => ({})
    },
    action: {
      type: String,
      validator: function(value) {
        return ['create', 'update'].indexOf(value) !== -1;
      },
      default: 'create'
    }
  },

  data() {
    const isAnd = this.connections[0].input.name === 'AND';
    const isOr = this.connections[0].input.name === 'OR';
    return {
      isAnd,
      isOr,
      localConnections: this.connections.map((connection, index) => {
        let changes;
        if (connection.data.status === 'changed' && connection.data.changes.fieldName === 'to') {
          const fieldChanges = connection.data.changes;
          if (fieldChanges) {
            changes = {};
            const { curr, next } = fieldChanges;

            if (curr.action.title !== next.action.title) {
              changes.title = {
                old: curr.action.title,
                new: next.action.title
              };
            }

            if (curr.action.notes !== next.action.notes) {
              changes.notes = {
                old: curr.action.notes,
                new: next.action.notes
              };
            }

            if (
              curr.action.assignees.length !== next.action.assignees.length ||
              !curr.action.assignees.every(a => next.action.assignees.includes(a))
            ) {
              changes.assignees = {
                old: curr.action.assignees,
                new: next.action.assignees
              };
            }

            if (
              curr.action.attachments.length !== next.action.attachments.length ||
              !curr.action.attachments.every(a => next.action.attachments.includes(a))
            ) {
              changes.attachments = {
                old: curr.action.attachments,
                new: next.action.attachments
              };
            }
            if (
              curr.action.dueAt.amount !== next.action.dueAt.amount ||
              curr.action.dueAt.date !== next.action.dueAt.date ||
              curr.action.dueAt.measures !== next.action.dueAt.measures
            ) {
              changes.dueAt = {
                old: curr.action.dueAt,
                new: next.action.dueAt
              };
            }
          }
        }
        return {
          index,
          changes,
          title: connection.data.to ? connection.data.to.title : [],
          notes: connection.data.to ? connection.data.to.notes : '',
          eventSource: connection.output.node.data.props.isEventSource
            ? {
                source: ['milestone-start', 'workflow-form', connection.output.node.data.props.source],
                code: ['workflow.milestone.created', 'workflow.next', connection.output.name]
              }
            : {
                source: ['workflow', 'workflow-form'],
                code: ['workflow.next', 'workflow.next']
              },
          form: connection.data.from,
          predicates: (connection.data.to && connection.data.to.predicates) || [],
          dueAt: connection.data.to
            ? connection.data.to.dueAt
            : {
                date: '',
                amount: 0,
                measures: 'Days'
              },
          startAt: connection.data.to
            ? connection.data.to.startAt
            : {
                date: '',
                amount: 0,
                measures: 'Days'
              },

          assignees: connection.data.to ? connection.data.to.assignees : [],
          attachments: connection.data.to ? connection.data.to.attachments : [],
          emailAttachments: connection.data.to ? connection.data.to.emailAttachments : [],
          status: connection.data.status
        };
      }),
      activeTab: 0,
      isReady: true
    };
  },

  computed: {
    ...mapState({
      contracts: s => s.milestones.contracts,
      milestone: s => s.milestones.item
    }),
    additionalPredicateVariables: function() {
      return activeTabIndex => {
        const milestoneVariables = this.milestone.variables
          ? this.milestone.variables.map(p => ({
              type: p.type,
              name: `milestone.variables.${p.property}`
            }))
          : [];

        const formVariables = this.connections[activeTabIndex].data.from
          ? this.connections[activeTabIndex].data.from.properties.map(p => {
              return {
                type: p.type,
                name: `form.${p.property}`
              };
            })
          : [];
        return [...milestoneVariables, ...formVariables];
      };
    },
    source() {
      return this.connections[this.activeTab].output.node.data.props.source;
    },
    code() {
      return this.connections[this.activeTab].output.name;
    },
    excludedContracts() {
      const response = [];
      if (this.connections[this.activeTab].output.name === 'AND') {
        response.push('previousTask');
      }
      if (this.connections[this.activeTab].input.name === 'OR') {
        response.push('thisTask');
      }
      // debugger;
      return response;
    }
  },
  methods: {
    onConfirm() {
      this.$emit(
        'confirm',
        this.localConnections.map(({ form, ...other }) => ({ ...other }))
      );
    },
    deleteConnection(index) {
      this.localConnections.splice(index, 1);
      if (this.isAnd && this.activeTab === index) {
        this.activeTab = null;
        return;
      }
      this.activeTab = 0;
    },
    onTabChange(index) {
      if (this.isAnd && this.activeTab === index) {
        this.activeTab = null;
        return;
      }
      this.isReady = false;
      this.activeTab = index;
      // rerender controls to update values
      this.$nextTick(() => {
        this.isReady = true;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.strike-through {
  text-decoration: line-through;
}
.add-step-modal {
  padding: 20px;
  header {
    h2 {
      font-size: 1.2rem;
      font-weight: 500;
      text-transform: uppercase;
      padding: 1.75rem 2rem 0.75rem 2rem;
    }
  }
  .row {
    margin-bottom: 20px;
    border: 1px solid #fff;
    padding: 20px;
    .title {
      margin-bottom: 10px;
    }
  }
  .title {
    display: grid;
    grid-template-columns: max-content 1fr max-content;
    grid-gap: 10px;
  }
  .tabs {
    display: flex;
    width: 100%;
    margin-bottom: 20px;
    .tab {
      padding: 10px;
      flex: 1;
      border: 1px solid #fff;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &.active {
        border-bottom-width: 0;
        cursor: default;
      }
    }
  }
}
</style>
