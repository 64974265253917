<template>
  <form class="create-or-edit-model-form" @submit.prevent.stop="submit">
    <div class="form-row">
      <p-text-field v-model.trim="name" :disabled="!$hasPermission('administration')" :label="'Name'" />
    </div>
    <div class="form-row">
      <p-multiselect
        v-model.trim="provider"
        :disabled="!$hasPermission('administration')"
        :options="providerOptions"
        :label="'Provider'"
        :searchable="false"
        placeholder=""
      />
    </div>
    <div class="form-row">
      <p-multiselect
        v-model="capability"
        :options="providerCapabilities"
        :disabled="!$hasPermission('administration')"
        label="Capability"
        :searchable="false"
      ></p-multiselect>
    </div>
    <div class="form-row">
      <p-text-field v-model="description" :disabled="!$hasPermission('administration')" :label="'Description'" :multiline="true" :rows="3" />
    </div>
    <div class="form-row code">
      <label>Configuration</label>
      <p-code v-model="config" :read-only="!$hasPermission('administration')" :language="'json'"></p-code>
    </div>
    <div>
      <p-button type="button" @click.prevent="$router.back()">Cancel</p-button>
      <p-button v-if="$hasPermission('administration')" color="primary" type="submit">Save</p-button>
    </div>
  </form>
</template>

<script>
import { mapState } from 'vuex';

import Multiselect from '@/components/common/Multiselect';
import TextField from '@/components/common/TextField';
import Button from '@/components/common/Button';
import Code from '@/components/common/Code';

export default {
  components: {
    'p-text-field': TextField,
    'p-multiselect': Multiselect,
    'p-button': Button,
    'p-code': Code
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    if (this.item) {
      return {
        provider: this.item.provider,
        name: this.item.name,
        capability: this.item.capability,
        description: this.item.description,
        config: (this.item.config && JSON.stringify(JSON.parse(this.item.config), null, 2)) || ''
      };
    } else {
      return {
        provider: '',
        name: '',
        capability: '',
        description: '',
        config: JSON.stringify({ property: 'value' }, null, 2)
      };
    }
  },
  computed: {
    ...mapState({
      providers: s => s.nlp.providers || {}
    }),
    providerOptions() {
      return this.providers.map(p => p.name);
    },
    modelProvider() {
      return this.providers.find(p => p.name == this.provider);
    },
    providerCapabilities() {
      return this.modelProvider?.capabilities.map(c => c.type);
    }
  },
  async created() {
    if (!this.providers?.length) {
      await this.$store.dispatch('nlp/getConfigurations');
    }
  },
  methods: {
    async submit() {
      this.$emit('submit', {
        ...this.item,
        provider: this.provider,
        name: this.name,
        capability: this.capability,
        description: this.description,
        config: JSON.stringify(JSON.parse(this.config))
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.create-or-edit-model-form {
  padding: 2rem;
  background: var(--theme-surface);
  overflow-y: auto;

  label {
    font-weight: 500;
    font-size: 0.75rem;
    letter-spacing: 0.025em;
  }

  .code {
    min-height: 300px;
  }

  > * {
    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }
    &:last-child {
      display: flex;
      justify-content: flex-end;
    }
  }
}
</style>
