<template>
  <section v-if="isGetRequestPending" class="edit-specification">
    <p-loading />
  </section>
  <section v-else class="edit-specification">
    <header>
      <h1>Edit Specification</h1>
      <div class="action-wrapper">
        <p-button v-if="$hasPermission('specifications.write')" color="secondary" @click="confirmDelete">Delete</p-button>
      </div>
    </header>
    <p-edit-specification-form
      :item="item"
      :is-request-pending="isUpdateRequestPending"
      :is-request-failed="isUpdateRequestFailed"
      @submit="submit"
    />
    <footer></footer>
  </section>
</template>

<script>
import { mapState } from 'vuex';

import Loading from '@/components/common/Loading';
import Button from '@/components/common/Button';
import EditForm from './_CreateOrEdit';

export default {
  components: {
    'p-loading': Loading,
    'p-button': Button,
    'p-edit-specification-form': EditForm
  },
  computed: {
    ...mapState({
      item: s => s.specifications.item,
      isGetRequestPending: s => s.specifications.isGetRequestPending,
      isUpdateRequestPending: s => s.specifications.isUpdateRequestPending,
      isUpdateRequestFailed: s => s.specifications.isUpdateRequestFailed
    })
  },
  watch: {
    '$route.params': {
      handler() {
        this.$store.dispatch('specifications/getById', this.$route.params.id);
      },
      immediate: true
    }
  },
  methods: {
    async submit(props) {
      await this.$store.dispatch('specifications/update', {
        id: this.$route.params.id,
        ...props
      });
      this.$router.push({ path: `/specifications/${this.$route.params.id}` });
    },
    async confirmDelete() {
      const { id, name } = this.item;

      const confirmResult = await this.$confirm({
        title: 'Delete specification?',
        message: `Are you sure you want to delete specification '${name}'?\n\nThis action can't be undone.`,
        confirm: 'Delete'
      });

      if (!confirmResult) {
        return;
      }

      const lock = this.$lock();
      try {
        await this.$store.dispatch('specifications/delete', id);

        this.$toast.success({
          title: 'Specification deleted',
          message: `Specification '${name}' (${id}) was successfully deleted.`
        });
        this.$router.push({ path: `/specifications` });
      } catch (e) {
        this.$toast.error({
          title: 'Failed to delete specification',
          message: `Please, try again later or contact our development team.`
        });
      } finally {
        lock.release();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.edit-specification {
  display: grid;
  grid-gap: 0.5rem;
  grid-template-rows: minmax(0, max-content) minmax(0, 1fr);
  width: 100%;
  height: 100%;
  padding: 0 0.5rem 0 1rem;

  header {
    width: 100%;
    padding: 0.5rem 0.5rem 0 0;
    box-sizing: border-box;
    position: relative;
  }

  h1 {
    color: var(--theme-on-surface);
    font-size: 1.5rem;
    font-weight: 700;
  }

  .action-wrapper {
    position: absolute;
    right: 0rem;
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>
