<script>
import { Splitpanes, Pane } from 'splitpanes';
import 'splitpanes/dist/splitpanes.css';
import './Pane.scss';

import { lw } from '@/utils/lw';

const LOCAL_STORAGE_KEY_TEMPLATE = 'BACKOFFICE_LAYOUT_';

export default {
  props: {
    name: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isReady: false
    };
  },
  created() {
    setTimeout(() => (this.isReady = true), 250);
  },
  render(createElement) {
    const key = LOCAL_STORAGE_KEY_TEMPLATE + this.name.toUpperCase();

    const args = lw.get(key);
    return createElement(
      Splitpanes,
      {
        class: 'default-theme',
        on: {
          resize(args) {
            lw.set(key, args);
          }
        },
        style: { opacity: this.isReady ? '1' : '0' }
      },
      Array.from({ length: this.$slots.default.length }).map((_, index) =>
        createElement(
          Pane,
          {
            props: {
              ...this.$slots.default[index].data.attrs,
              ...(args && args[index] && args[index].size ? { size: args[index].size } : {})
            },
            methods: {}
          },
          [this.$slots.default[index]]
        )
      )
    );
  }
};
</script>
