<template>
  <div class="socket" :class="[type, socket.name, isConnected ? 'connected' : 'unconnected'] | kebab" :title="socket.name"></div>
</template>

<script>
export default {
  props: ['type', 'socket', 'isConnected']
};
</script>

<style lang="sass" scoped>
$node-color: rgba(110,136,255,0.8)
$node-color-selected: #ffd92c
$group-color: rgba(15,80,255,0.2)
$group-handler-size: 40px
$group-handler-offset: -10px
$socket-size: 14px
$socket-margin: 2px
$socket-color: #96b38a
$node-width: 180px

.socket
  display: inline-block
  cursor: pointer
  border: 1px solid white
  border-radius: $socket-size/2.0
  width: $socket-size
  height: $socket-size
  margin: $socket-margin
  vertical-align: middle
  background: var(--theme-on-background)
  z-index: 2
  box-sizing: border-box
  margin-left: 4px
  &:hover
    border-width: 4px
  &.multiple
    border-color: yellow
  &.output
    margin-right: - $socket-size / 2
  &.input
    margin-left: - $socket-size / 2
  &.connected
    background-color: var(--theme-primary)
</style>
