<template>
  <span class="checkbox" :class="{ disabled }">
    <input :id="id" type="checkbox" :checked="value" :disabled="disabled" :value="value" @change="toggle" />
    <label :for="id">
      <span class="checkbox-image">
        <span v-if="value">&#10003;</span>
      </span>
      <span v-if="!!label">{{ label }} </span>
    </label>
  </span>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: () => Math.random().toString()
    },
    label: {
      type: String,
      default: ''
    },
    value: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    toggle() {
      if (this.value) {
        this.uncheck();
      } else {
        this.check();
      }
    },
    check() {
      this.$emit('input', true);
      this.$emit('change', true);
    },
    uncheck() {
      this.$emit('input', false);
      this.$emit('change', false);
    }
  }
};
</script>

<style scoped lang="scss">
.checkbox {
  display: inline-flex;

  &.disabled {
    opacity: 0.6;
  }

  input {
    width: 0;
    height: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;

    &:focus ~ label {
      .checkbox-image {
        box-shadow: 0px 0px 2px 2px var(--theme-on-background-dark);
      }
    }
  }

  label {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;

    .checkbox-image {
      display: inline-flex;
      border: 1px solid;
      border-radius: 2px;
      width: 0.8rem;
      height: 0.8rem;
      align-items: center;
      justify-content: center;
      position: relative;
      > span {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 0.8rem;
        height: 0.8rem;
        transform: translate(-50%, -50%);
        justify-content: center;
        align-items: center;
        display: inline-flex;
        font-size: 0.75rem;
      }

      & ~ span {
        margin-left: 5px;
        font-weight: 500;
        font-size: 0.75rem;
        letter-spacing: 0.025em;
      }
    }
  }
}
</style>
