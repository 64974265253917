<template>
  <form :class="{ dirty: $v.$anyDirty }" autocomplete="off" class="create-or-edit-workflow-form" @submit.prevent="submit">
    <div class="right">
      <div class="form-row template">
        <p-text-field v-model="title" :disabled="!$hasPermission('workflows')" :label="'Workflow title'"></p-text-field>
      </div>
      <div class="form-row tags">
        <p-multiselect
          v-model="clientCodes"
          :multiple="true"
          :taggable="true"
          :disabled="!$hasPermission('workflows')"
          label="Clients"
          placeholder=""
          tag-placeholder=""
          @tag="clientAdded"
        ></p-multiselect>
      </div>
      <div v-if="isRequestFailed" class="error">Failed to save a workflow. Check your input and try again.</div>
      <div class="form-row submit">
        <p-button color="secondary" @click="onCancel">Cancel</p-button>
        <p-button v-if="$hasPermission('workflows')" color="primary" type="submit" :disabled="isRequestPending || ($v.$anyDirty && $v.$invalid)"
          >Save</p-button
        >
      </div>
    </div>
  </form>
</template>

<script>
import { required } from 'vuelidate/lib/validators';

import TextField from '@/components/common/TextField';
import Button from '@/components/common/Button';
import Multiselect from '@/components/common/Multiselect';

export default {
  components: {
    'p-text-field': TextField,
    'p-button': Button,
    'p-multiselect': Multiselect
  },
  props: {
    item: {
      type: Object,
      default: null
    },
    isRequestPending: {
      type: Boolean,
      default: false
    },
    isRequestFailed: {
      type: Boolean,
      default: false
    }
  },
  data() {
    if (this.item) {
      return {
        title: this.item.title,
        clientCodes: this.item.clientCodes
      };
    }
    return {
      title: '',
      clientCodes: []
    };
  },
  validations: {
    title: {
      required
    }
  },
  methods: {
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.$emit('submit', {
        title: this.title && this.title.trim(),
        clientCodes: this.clientCodes
      });

      this.$v.$reset();
    },
    clientAdded(tag) {
      if (!Array.isArray(this.clientCodes)) {
        this.tags = [];
      }

      if (this.clientCodes.includes(tag)) {
        return;
      }
      this.clientCodes.push(clientCodes);
    },
    onCancel() {
      this.$router.push('/workflows');
    }
  }
};
</script>

<style lang="scss" scoped>
.create-or-edit-workflow-form {
  grid-gap: 0.5rem;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  display: grid;

  > div {
    min-width: 0;
    min-height: 0;
  }
  .left {
    grid-column: 2/3;
    grid-row: 1/2;

    background: var(--theme-surface);
    padding: 0.1rem;
    overflow: hidden;

    .code {
      height: 100%;
    }
  }
  .right {
    grid-column: 1/2;
    grid-row: 1/2;
    background: var(--theme-surface);
    padding: 2rem;

    > div {
      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }
      &:last-child {
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  .submit {
    grid-column: 1/3;
    grid-row: 2/3;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    button {
      margin-right: 20px;
    }
  }
  // > * {
  //   &:not(:last-child) {
  //     margin-bottom: 1.5rem;
  //   }
  //   &:last-child {
  //     display: flex;
  //     justify-content: flex-end;
  //   }
  // }

  // .form-row {
  //   &.template {
  //     grid-column: 1/2;
  //     grid-row: 1/2;
  //   }
  //   &.title {
  //     grid-column: 1/2;
  //     grid-row: 2/3;
  //   }
  //   &.description {
  //     grid-column: 1/2;
  //     grid-row: 3/4;
  //   }
  //   &.tags {
  //     grid-column: 1/2;
  //     grid-row: 4/5;
  //   }

  //   &.code {
  //     grid-column: 2/3;
  //     grid-row: 1/5;
  //   }
  //   &.submit {
  //     grid-column: 1/3;
  //     grid-row: 5/6;
  //   }
  // }

  .error {
    font-size: 0.8rem;
    color: var(--theme-error);
    text-align: left;
    padding: 0.25rem 0;
    display: none;
  }
  &.dirty {
    .error {
      display: block;
    }
  }
}
</style>
