<template>
  <div class="formpicker-wrapper">
    <Multiselect
      :multiple="true"
      :taggable="true"
      label="Tags"
      :value="tags"
      :options="tagsOptions"
      placeholder="Select tags"
      @input="$emit('input', $event)"
    />
  </div>
</template>

<script>
import Multiselect from '@/components/common/Multiselect';
import { mapState } from 'vuex';
export default {
  components: {
    Multiselect
  },
  props: {
    tags: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapState({
      aggs: s => s.forms.aggs
    }),
    tagsOptions() {
      if (!this.aggs) {
        return [];
      }
      return Object.keys(this.aggs.tags).filter(t => t);
    }
  },
  async created() {
    await this.$store.dispatch('forms/getCollection');
  }
};
</script>
