<template>
  <section v-if="isReady" class="categorization">
    <form :disabled="isAdvancedSearchRequestPending" @submit.prevent.stop>
      <p-layout layout="vertical" name="categorization">
        <div min-size="25" max-size="75" size="25" class="left">
          <div class="form-row name">
            <p-text-field v-model="item.name" label="Name" placeholder="Text to somehow identify a query"></p-text-field>
          </div>
          <div class="form-row code">
            <p-code :key="item.id" v-model="item.text"></p-code>
          </div>
          <div class="form-row submit">
            <p-button
              color="primary"
              :disabled="isAdvancedSearchRequestPending || isUpdateRequestPending || !canApply || !isDirty"
              @click.prevent.stop="saveQuery"
              >Save</p-button
            >
            <p-button
              color="secondary"
              :disabled="isAdvancedSearchRequestPending || isUpdateRequestPending || !canExecute"
              type="button"
              @click="download()"
              ><span class="action-icon">⤓</span>
            </p-button>
            <p-button
              color="secondary"
              :disabled="isAdvancedSearchRequestPending || isUpdateRequestPending || !canExecute"
              type="button"
              @click="submit()"
              ><span class="action-icon" style="transform: translate(2px, 0)">&#9658;</span></p-button
            >
          </div>
        </div>
        <div class="right">
          <div class="form-row tags">
            <p-multiselect
              v-model="item.tags"
              :multiple="true"
              :taggable="true"
              label="Tag"
              placeholder="Tag to apply"
              tag-placeholder=""
              :search-change-callback="query => suggestAsync('tags', query)"
              @tag="addTag"
            ></p-multiselect>
          </div>
          <div class="form-row browse">
            <p-modal :visible="modalVisible">
              <template slot="title"> Select a query </template>
              <template slot="footer">
                <p-button @click="createQuery">Create new</p-button>
                <p-button color="secondary" @click="modalVisible = false">Close</p-button>
              </template>
              <div class="queries-list-wrapper">
                <ul class="query-list">
                  <li class="query-list-item header">
                    <div>Name</div>
                    <div>Tags</div>
                    <div>Updated</div>
                    <div></div>
                  </li>
                </ul>
                <ul class="query-list">
                  <li v-for="query in queries" :key="query.id" class="query-list-item" @click="selectQuery(query)">
                    <div>{{ query.name }}</div>
                    <div>{{ query.tags && query.tags.join(', ') }}</div>
                    <div v-if="query.updatedAt">{{ new Date(query.updatedAt).toLocaleString() }} by {{ query.updatedBy }}</div>
                    <div v-else>{{ new Date(query.createdAt).toLocaleString() }} by {{ query.createdBy }}</div>
                    <div>
                      <p-button v-if="queries.length > 1" variant="text" color="secondary" @click.stop="deleteQuery(query.id)">&times;</p-button>
                    </div>
                  </li>
                </ul>
              </div>
            </p-modal>
            <p-button variant="text" type="button" class="open" @click="modalVisible = true"> &#9906; </p-button>
          </div>

          <div class="form-row apply">
            <p-button
              color="secondary"
              variant="text"
              :disabled="isDirty || isAdvancedSearchRequestPending || isAdvancedSearchRequestFailed || isApplyQueryPending || isRemoveQueryPending"
              @click.prevent.stop="remove"
              ><p-icon name="delete"></p-icon>&nbsp;Cleanup</p-button
            >
            <div>
              <p-button
                color="primary"
                :disabled="isDirty || isAdvancedSearchRequestPending || isAdvancedSearchRequestFailed || isApplyQueryPending || isRemoveQueryPending"
                type="button"
                @click.prevent.stop="apply"
                >Add tag(s)</p-button
              >
            </div>
          </div>
          <div v-if="isAdvancedSearchRequestFailed" class="form-row list">
            <div class="error">
              Search failed. Please, check your request and try again. If this problem persists, please contact our development team.
            </div>
          </div>
          <div v-else-if="isAdvancedSearchRequestPending || !collection" class="form-row list">
            <p-loading></p-loading>
          </div>

          <div v-else class="form-row list">
            <p-application-list :applications="collection" :total="total">
              <template slot="lw">
                <p-pagination :total="total" :from="from" :size="size" @navigate="submit" />
              </template>
            </p-application-list>
          </div>
        </div>
      </p-layout>
    </form>
  </section>
</template>

<script>
import { mapState } from 'vuex';

import Button from '@/components/common/Button';
import Modal from '@/components/common/Modal';
import Code from '@/components/common/Code';
import TextField from '@/components/common/TextField';
import Loading from '@/components/common/Loading';
import Icon from '@/components/common/Icon';
import Multiselect from '@/components/common/Multiselect';
import Pagination from '@/components/common/Pagination';
import Layout from '@/components/layout/Layout';

import List from './List';

import { lw } from '@/utils/lw';
import httpClient from '@/utils/httpClient';

export default {
  components: {
    'p-code': Code,
    'p-button': Button,
    'p-modal': Modal,
    'p-loading': Loading,
    'p-text-field': TextField,
    'p-multiselect': Multiselect,
    'p-icon': Icon,
    'p-application-list': List,
    'p-pagination': Pagination,
    'p-layout': Layout
  },
  data() {
    return {
      item: {
        id: '',
        name: '',
        tags: [],
        text: ''
      },

      isDirty: false,
      modalVisible: false
    };
  },
  computed: {
    ...mapState({
      total: s => s.categorization.search && s.categorization.search.total,
      from: s => s.categorization.search && s.categorization.search.from,
      size: s => s.categorization.search && s.categorization.search.size,
      collection: s => s.categorization.search && s.categorization.search.data,
      isAdvancedSearchRequestPending: s => s.categorization.isAdvancedSearchRequestPending,
      isAdvancedSearchRequestFailed: s => s.categorization.isAdvancedSearchRequestFailed,
      isApplyQueryPending: s => s.categorization.isApplyQueryPending,
      isRemoveQueryPending: s => s.categorization.isRemoveQueryPending,
      queries: s => s.categorization.collection,
      isReady: s => !s.categorization.isGetCollectionRequestPending && !s.categorization.isGetCollectionRequestFailed,
      isUpdateRequestPending: s => s.categorization.isUpdateRequestPending
    }),
    canExecute() {
      try {
        JSON.parse(this.item.text);
        return true;
      } catch (e) {
        return false;
      }
    },
    canApply() {
      return this.canExecute && this.item.name && this.item.name.length && Array.isArray(this.item.tags) && this.item.tags.length;
    }
  },
  watch: {
    item: {
      handler() {
        this.isDirty = true;
      },
      deep: true
    }
  },
  async created() {
    await this.$store.dispatch('categorization/getCollection');

    if (!this.isReady) {
      return;
    }
    if (this.queries && this.queries.length === 0) {
      this.createQuery();
    } else {
      this.selectQuery(this.queries.find(({ id }) => id === lw.get('LOCAL_STORAGE_SELECTED_QUERY_ID')) || this.queries[0]);
    }
  },
  methods: {
    selectQuery(query) {
      this.item.id = query.id;
      this.item.name = query.name;
      this.item.tags = query.tags;
      this.item.text = query.text;

      this.modalVisible = false;
      this.submit();

      lw.set('LOCAL_STORAGE_SELECTED_QUERY_ID', query.id);

      this.$nextTick(() => {
        this.isDirty = false;
      });
    },
    async deleteQuery(id) {
      try {
        const lock = this.$lock();
        await this.$store.dispatch('categorization/delete', id);
        lock.release();

        this.$toast.success({
          title: 'Delete completed',
          message: `Query '${id}' was deleted.`
        });
        if (this.item.id === id) {
          this.selectQuery(this.queries[0]);
        }
      } catch (e) {
        this.$toast.error({
          title: 'Delete failed',
          message: `Please, try again later or contact our development team.`
        });
      }
    },
    async createQuery() {
      try {
        const lock = this.$lock();
        const query = await this.$store.dispatch('categorization/create', {
          name: 'Untitled',
          tags: [],
          text: JSON.stringify(
            {
              query: {
                query_string: {
                  query: 'untitled OR unnamed',
                  fields: ['title', 'abstract']
                }
              },
              highlight: {
                fields: {
                  '*': {
                    fragment_size: 100,
                    number_of_fragments: 2,
                    no_match_size: 100
                  }
                }
              }
            },
            null,
            2
          )
        });
        this.selectQuery(query);
        lock.release();
      } catch (e) {
        this.$toast.error({
          title: 'Create query failed',
          message: `Please, try again later or contact our development team.`
        });
      }
    },
    async saveQuery() {
      if (!this.canApply) {
        return;
      }
      try {
        await this.$store.dispatch('categorization/update', {
          id: this.item.id,
          name: this.item.name,
          tags: this.item.tags,
          text: this.item.text
        });
        this.$toast.success({
          title: 'Update query completed',
          message: `Query '${this.item.id}' was updated.`
        });
        this.isDirty = false;
      } catch (e) {
        this.$toast.error({
          title: 'Update query failed',
          message: `Please, try again later or contact our development team.`
        });
      }
    },
    submit(args) {
      let body;
      try {
        body = JSON.parse(this.item.text);
      } catch (e) {
        return false;
      }

      this.$store.dispatch('categorization/search', {
        body: {
          ...body,
          size: (args && args.size) || 10,
          from: (args && args.from) || 0
        }
      });
    },
    async download() {
      const lock = this.$lock();
      try {
        const { downloadUrl } = await httpClient.post(`/api/search/advanced/csv`, JSON.parse(this.item.text));
        window.open(`/api${downloadUrl}`, '_blank');
      } catch (e) {
        this.$toast.error({
          title: 'Failed to generate CSV file',
          message: `Please, try again later or contact our development team.`
        });
      } finally {
        lock.release();
      }
    },
    async remove() {
      try {
        if (!this.canApply || this.isDirty) {
          return;
        }

        const confirmResult = await this.$confirm({
          title: 'Remove tag(s)?',
          message: `Are you sure you want to remove tag(s) ${this.item.tags.map(t => `'${t}'`).join(', ')}? This action can't be undone.`,
          confirm: 'Delete'
        });

        if (!confirmResult) {
          return;
        }

        const lock = this.$lock();
        await this.$store.dispatch('categorization/remove', { id: this.item.id });
        lock.release();

        this.$toast.success({
          title: 'Remove triggered',
          message: `Tag(s) ${this.item.tags
            .map(t => `'${t}'`)
            .join(', ')} were removed from the corresponding dataset. It can take a while before these changes are reflected in search.`
        });
      } catch (e) {
        this.$toast.error({
          title: 'Apply failed',
          message: `Please, try again later or contact our development team.`
        });
      }
    },
    async apply() {
      try {
        if (!this.canApply || this.isDirty) {
          return;
        }
        const lock = this.$lock();
        await this.$store.dispatch('categorization/apply', { id: this.item.id });
        lock.release();

        this.$toast.success({
          title: 'Apply triggered',
          message: `Tag(s) ${this.item.tags
            .map(t => `'${t}'`)
            .join(', ')} were applied to the corresponding dataset. It can take a while before these changes are reflected in search.`
        });
      } catch (e) {
        this.$toast.error({
          title: 'Apply failed',
          message: `Please, try again later or contact our development team.`
        });
      }
    },
    addTag(tag) {
      if (this.item.tags.includes(tag)) {
        return;
      }
      this.item.tags.push(tag);
    },
    suggestAsync(fieldName, query) {
      return httpClient.get(`/api/suggest/${fieldName}?q=${query}`);
    }
  }
};
</script>

<style scoped lang="scss">
.categorization {
  width: 100%;
  height: 100%;

  padding: 0 0.5rem 0 1rem;

  form {
    width: 100%;
    height: 100%;

    .left {
      display: grid;
      width: 100%;
      height: 100%;
      min-width: 0;
      min-height: 0;
      grid-template-rows: max-content minmax(0, 1fr) max-content;
      grid-template-columns: minmax(0, 1fr);

      .form-row {
        &.name {
          padding: 1rem;
        }
        &.code {
          overflow: hidden;
        }
        &.submit {
          grid-column: 1/2;
          grid-row: 3/4;
          padding: 1rem;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          & > button {
            &:not(:last-child) {
              margin-right: 0.5rem;
            }
          }
        }
      }
    }

    .right {
      display: grid;
      width: 100%;
      height: 100%;
      min-width: 0;
      min-height: 0;
      grid-template-rows: max-content minmax(0, 1fr) max-content;
      grid-template-columns: minmax(0, 1fr);
      .form-row {
        &.tags {
          padding: 1rem;
          grid-column: 1/2;
          grid-row: 1/2;
          z-index: 1;
          max-height: 54px;
        }

        &.browse {
          grid-column: 2/3;
          grid-row: 1/2;
          position: relative;
          .open {
            transform: rotate(45deg) translate(50%, 75%);
            font-size: 1.5rem;
          }
        }

        &.apply {
          grid-column: 1/3;
          grid-row: 3/4;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 1rem;
        }
        &.list {
          grid-column: 1/3;
          grid-row: 2/3;
          position: relative;
          overflow: hidden;
        }
      }
    }
    .form-row {
      padding: 0 1rem;
      background: var(--theme-surface);
      label {
        font-size: 0.8rem;
      }
    }

    .action-icon {
      font-size: 15px;
      line-height: 1;
      font-weight: bold;
    }
  }

  form {
    min-width: 0;
  }

  .list {
    position: relative;
    .error {
      color: var(--theme-error);
      position: absolute;
      top: 45%;
      margin: auto;
      font-size: 0.9rem;
      width: 100%;
      text-align: center;
      left: 0;
      right: 0;
    }
  }
}
</style>

<style lang="scss" scoped>
.queries-list-wrapper {
  width: 768px;

  .query-list {
    margin: 0;
    list-style: none;

    .query-list-item {
      display: grid;
      grid-template-columns: minmax(0, 2fr) minmax(0, 2fr) minmax(0, 2fr) 50px;
      grid-gap: 0;
      align-items: center;
      border-bottom: 1px solid var(--theme-highlight);
      font-size: 0.75rem;
      color: var(--theme-on-background);

      label {
        font-weight: 700;
      }

      > div {
        padding: 0.5rem 0.5rem;

        &:first-child {
          padding-left: 2rem;
        }
        &:last-child {
          padding-right: 2rem;
        }
      }

      &:not(.header) {
        &:hover {
          background-color: var(--theme-highlight);
          cursor: pointer;
        }
      }

      &.header {
        font-size: 0.75rem;
        color: var(--theme-on-background-accent);
        > * {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          padding: 0.25rem 0.5rem;
        }
      }
    }

    &:not(:last-child) {
      margin-right: 6px;
    }

    &:last-child {
      overflow-y: scroll;
    }
  }
}
</style>

<style lang="scss">
.multipane.layout-v .multipane-resizer {
  margin: 0;
  left: 0; /* reset default styling */
  width: 10px;
  position: relative;

  &:before {
    display: block;
    content: '';
    width: 3px;
    height: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -20px;
    margin-left: -2.5px;
    border-left: 1px solid rgb(204, 204, 204);
    border-right: 1px solid rgb(204, 204, 204);
  }
}
</style>
