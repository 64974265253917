<template>
  <p-text-field :value="value" label="Template title" @change="onChange" />
</template>
<script>
import TextField from '@/components/common/TextField.vue';

export default {
  components: {
    'p-text-field': TextField
  },
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  methods: {
    onChange(e) {
      this.$emit('input', e);
    }
  }
};
</script>
