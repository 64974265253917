export default {
  install(Vue) {
    Vue.prototype.$hasPermission = function(permission) {
      if (!Array.isArray(this.$store.state.identity.permissions)) {
        return false;
      }

      const [mainPermission] = permission.split('.');
      if (this.$store.state.identity.permissions.includes(mainPermission)) {
        return true;
      }

      return this.$store.state.identity.permissions.includes(permission);
    };
  }
};
