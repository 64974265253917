<template>
  <div class="json-view"></div>
</template>
<script>
import JSONEditor from 'jsoneditor';
import 'jsoneditor/dist/jsoneditor.css';

export default {
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      ed: null
    };
  },
  watch: {
    value: {
      handler(value) {
        this.ed && this.ed.set(value);
      },
      immediate: true
    }
  },
  mounted() {
    this.ed = new JSONEditor(this.$el, {
      mode: 'view',
      mainMenuBar: false,
      statusBar: false,
      navigationBar: false
    });
    this.ed.set(this.value);
  }
};
</script>

<style>
.json-view {
  height: 100%;
  overflow-y: scroll;
}

div.jsoneditor,
div.jsoneditor-menu {
  border-color: var(--theme-on-background-accent);
  border-radius: 2px;
}
div.jsoneditor-menu {
  background-color: var(--theme-on-background-accent);
}
div.jsoneditor-tree,
div.jsoneditor textarea.jsoneditor-text {
  color: var(--theme-on-background);
  background: var(--theme-background);
}
div.jsoneditor-field,
div.jsoneditor-value {
  color: var(--theme-on-background);
}

tr.jsoneditor-highlight,
tr.jsoneditor-selected {
  background-color: #808080;
}

div.jsoneditor-field[contenteditable='true']:focus,
div.jsoneditor-field[contenteditable='true']:hover,
div.jsoneditor-value[contenteditable='true']:focus,
div.jsoneditor-value[contenteditable='true']:hover,
div.jsoneditor-field.jsoneditor-highlight,
div.jsoneditor-value.jsoneditor-highlight {
  background-color: var(--theme-on-background-accent);
  border-color: var(--theme-on-background-accent);
}

div.jsoneditor-field.highlight-active,
div.jsoneditor-field.highlight-active:focus,
div.jsoneditor-field.highlight-active:hover,
div.jsoneditor-value.highlight-active,
div.jsoneditor-value.highlight-active:focus,
div.jsoneditor-value.highlight-active:hover {
  background-color: var(--theme-on-background-accent);
  border-color: var(--theme-on-background-accent);
}

div.jsoneditor-tree button:focus,
div.jsoneditor-tree button.jsoneditor-button:focus {
  background-color: var(--theme-highlight);
  border-color: transparent;
  outline: none;
}

/* coloring of JSON in tree mode */
div.jsoneditor-readonly {
  color: var(--theme-on-background-accent);
}
div.jsoneditor td.jsoneditor-separator {
  color: var(--theme-on-background-accent);
}
div.jsoneditor-value.jsoneditor-string {
  color: var(--theme-primary);
}
div.jsoneditor-value.jsoneditor-object,
div.jsoneditor-value.jsoneditor-array {
  color: var(--theme-on-background-accent);
}
div.jsoneditor-value.jsoneditor-number {
  color: var(--theme-primary);
}
div.jsoneditor-value.jsoneditor-boolean {
  color: var(--theme-primary);
}
div.jsoneditor-value.jsoneditor-null {
  color: var(--theme-on-background-accent);
}
div.jsoneditor-value.jsoneditor-invalid {
  color: var(--theme-on-background);
}

div.jsoneditor-tree div.jsoneditor-show-more {
  background-color: var(--theme-highlight);
}
div.jsoneditor-tree div.jsoneditor-show-more a:hover,
div.jsoneditor-tree div.jsoneditor-show-more a:focus {
  color: var(--theme-warning);
}

div.jsoneditor-field,
div.jsoneditor-value,
div.jsoneditor td,
div.jsoneditor th,
div.jsoneditor textarea,
pre.jsoneditor-preview,
.jsoneditor-schema-error,
.jsoneditor-popover {
  font-size: 0.8rem;
}
</style>
