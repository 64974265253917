var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isReady || _vm.isGetRequestPending)?_c('section',{staticClass:"forms"},[_c('p-loading')],1):_c('section',{staticClass:"forms"},[_c('header',[_c('h1',[_vm._v("Forms")]),_c('section',{staticClass:"action-wrapper"},[(_vm.$hasPermission('forms'))?_c('router-link',{attrs:{"to":"/forms/create"}},[_vm._v("+ new")]):_vm._e()],1)]),_c('p-list',{attrs:{"name":"forms","data":_vm.collection,"total":_vm.total,"metadata":{
      name: { label: 'Name', width: 'minmax(0, 3fr)' },
      description: { label: 'Description', width: 'minmax(0, 3fr)' },
      fileNameTemplate: { label: 'Filename Template', width: 'minmax(0, 3fr)' },
      foreach: { label: 'Foreach', width: '75px' },
      clients: { label: 'Clients', width: 'minmax(100px, 2fr)' },
      tags: { label: 'Tags', width: 'minmax(100px, 2fr)' },
      modifiedAt: { label: 'Last modification', width: '175px' }
    }},on:{"edit":_vm.edit},scopedSlots:_vm._u([{key:"name",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+"."+_vm._s(item.type))]}},{key:"__actions",fn:function(ref){
    var item = ref.item;
return [_c('p-button',{attrs:{"color":"primary","variant":"text","title":"Download form template"},on:{"click":function($event){return _vm.downloadFile(item.formUrl)}}},[_vm._v("⤓")]),(['xfa', 'xfah', 'xfdf'].some(function (a) { return a === item.type; }))?_c('p-button',{attrs:{"variant":"text","title":"Download model template"},on:{"click":function($event){return _vm.downloadFile(item.modelUrl)}}},[_vm._v("⤓")]):_vm._e(),_c('p-button',{attrs:{"variant":"text","title":"Edit this form"},on:{"click":function($event){return _vm.edit(item.id)}}},[_vm._v(" ✎")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }