import { factory, am } from './utils';
import httpClient from '@/utils/httpClient';

const GET_CONTRACTS = am(`GET_CONTRACTS`);
const GET_TEMPLATES = am(`GET_TEMPLATES`);
const GET_DRAFT = am(`GET_DRAFT`);
const GET_REVISIONS = am(`GET_REVISIONS`);
const DELETE_DRAFT = am(`DELETE_DRAFT`);
const DELETE_ITEM = am(`DELETE_ITEM`);
const UPSERT_DRAFT = am(`UPSERT_DRAFT`);
const ACTIVATE = am('ACTIVATE');

export default {
  namespaced: true,
  ...factory({
    state: {
      contracts: [],
      isContractsRequestPending: false,
      isTemplatesRequestPending: false,
      isDraftRequestPending: false,
      isDeleteRequestPending: false,
      isGetRevisionsPending: false,
      isActivationRequestPending: false,
      templates: {},
      revisions: [],
      activeTemplate: 'template',
      editedTemplate: null,
      draft: null
    },
    getters: {
      templateHistory(state) {
        const history = [];
        if (state.draft) {
          history.push({
            createdAt: state.draft.createdAt,
            updatedAt: state.draft.updatedAt,
            author: state.draft.updatedBy || state.draft.createdBy,
            id: state.draft.id,
            isDraft: true,
            type: 'draft',
            commitMessage: state.draft.commitMessage
          });
        }
        if (state.item?.id !== state.draft?.id) {
          history.push({
            createdAt: state.item.createdAt,
            updatedAt: state.item.updatedAt,
            author: state.item.updatedBy,
            id: state.item.id,
            isDraft: state.item.isDraft,
            type: 'template',
            commitMessage: state.item.commitMessage,
            active: state.item.active,
            revision: state.item.revision
          });
        }

        if (state.revisions && state.revisions.length) {
          const remaining = state.revisions.filter(r => r.revision !== state.item.revision);

          history.push(
            ...remaining.map(revision => ({
              createdAt: revision.createdAt,
              updatedAt: revision.updatedAt,
              author: revision.createdBy,
              id: revision.id,
              type: revision.revision,
              commitMessage: revision.commitMessage,
              revision: revision.revision
            }))
          );
        }

        return history.sort((a, b) => b.revision - a.revision);
      }
    },
    mutations: {
      [GET_CONTRACTS.STARTED](state) {
        state.isContractsRequestPending = true;
      },
      [GET_CONTRACTS.COMPLETED](state, contracts) {
        state.isContractsRequestPending = false;
        state.contracts = contracts;
      },
      [GET_TEMPLATES.STARTED](state) {
        state.isTemplatesRequestPending = true;
      },
      [GET_TEMPLATES.COMPLETED](state, templates) {
        state.templates = templates;
        state.isTemplatesRequestPending = false;
      },
      [GET_REVISIONS.STARTED](state) {
        state.isGetRevisionsPending = true;
      },
      [GET_REVISIONS.COMPLETED](state, revisions) {
        state.revisions = revisions;
        state.isGetRevisionsPending = false;
      },
      [GET_DRAFT.STARTED](state) {
        state.isDraftRequestPending = true;
      },
      [GET_DRAFT.COMPLETED](state, draft) {
        state.draft = draft;
        state.activeTemplate = 'draft';
        state.isDraftRequestPending = false;
      },
      [UPSERT_DRAFT.STARTED](state) {
        state.isDraftRequestPending = true;
      },
      [UPSERT_DRAFT.COMPLETED](state, draft) {
        state.draft = draft;
        const index = state.collection.findIndex(t => t.id === draft.id);
        if (index > -1) {
          state.collection[index] = draft;
        }
        state.isDraftRequestPending = false;
      },
      [DELETE_DRAFT.STARTED](state) {
        state.isDeleteRequestPending = true;
      },

      [DELETE_DRAFT.COMPLETED](state, id) {
        state.isDeleteRequestPending = false;

        const index = state.collection.findIndex(t => t.id === id);
        if (index > -1) {
          state.collection.splice(index, 1);
        }

        state.draft = null;
      },
      [DELETE_ITEM.STARTED](state) {
        state.isDeleteRequestPending = true;
      },
      [DELETE_ITEM.COMPLETED](state, id) {
        state.isDeleteRequestPending = false;
        const index = state.collection.findIndex(t => t.id === id);
        if (index > -1) {
          state.collection.splice(index, 1);
        }
        if (state.item && state.item.id === id) {
          state.item = null;
          state.draft = null;
          state.revisions = null;
        }
      },
      [ACTIVATE.STARTED](state, { milestoneId }) {
        const milestone = state.collection.find(t => t.id === milestoneId);

        if (!milestone) {
          return;
        }

        milestone.pending = true;
      },
      [ACTIVATE.COMPLETED](state, { milestoneId, isActive }) {
        const milestone = state.collection.find(t => t.id === milestoneId);

        if (milestone) {
          milestone.pending = false;
          milestone.active = isActive;
        }

        if (state.editedTemplate?.id === milestoneId) {
          state.editedTemplate.active = isActive;
        }

        if (state.item?.id === milestoneId) {
          state.item.active = isActive;
        }
      },
      [ACTIVATE.FAILED](state) {
        state.collection.forEach(m => {
          m.pending = false;
        });
      },
      VARIABLES_CHANGED(state, { variables, source }) {
        const milestone = state.editedTemplate;

        const existing = milestone.variables || [];

        if (source) {
          const filtered = existing.filter(variable => {
            if (!variable.source) {
              return true;
            }
            const exists = variable.source.port === source.port && variable.source.step === source.step;
            return !exists;
          });
          const result = { ...milestone, variables: [...filtered, ...variables] };
          state.editedTemplate = result;
        } else {
          const result = { ...milestone, variables };
          state.editedTemplate = result;
        }
      },
      PROPERTIES_CHANGED(state, { data }) {
        state.editedTemplate.title = data.title;
        state.editedTemplate.events = data.events;
        state.editedTemplate.uniqueness = data.uniqueness;
        state.editedTemplate.tags = data.tags;
        state.editedTemplate.hidden = data.hidden;
      },
      SET_ACTIVE_TEMPLATE(state, template) {
        if (template === 'none') {
          state.activeTemplate = '';
          state.editedTemplate = null;
        } else {
          if (typeof template === 'object') {
            state.editedTemplate = template;
            return;
          }
          state.activeTemplate = template;
          if (template === 'draft') {
            state.editedTemplate = JSON.parse(JSON.stringify({ ...state.draft, type: 'draft' }));
          } else if (template === 'template') {
            state.editedTemplate = JSON.parse(JSON.stringify({ ...state.item, type: 'template' }));
          } else {
            const revisionTemplate = state.revisions.find(r => r.revision === template);
            state.editedTemplate = JSON.parse(JSON.stringify({ ...revisionTemplate, type: 'revision' }));
          }
        }
      },
      SET_ACTIVE_ROADMAP(state, roadmap) {
        state.editedTemplate.roadmap = roadmap;
      },
      SET_ACTIVE_HAS_DRAFT(state) {
        state.editedTemplate.hasDraft = false;
      }
    },
    actions: {
      async getContracts({ commit }) {
        commit(GET_CONTRACTS.STARTED);
        const data = await httpClient.get(`/api/workflows/v2/workflows/settings/contracts`);
        commit(GET_CONTRACTS.COMPLETED, data);
      },
      async getTemplates({ commit }) {
        commit(GET_TEMPLATES.STARTED);
        const data = await httpClient.get(`/api/workflows/v2/workflows/settings/templates`);
        commit(GET_TEMPLATES.COMPLETED, data);
      },
      async getTemplateTags({ commit }) {
        const data = await httpClient.get(`/api/workflows/v2/workflows/tags`);
        return data;
      },
      async getStepTasksCount(tech, { workflowId, milestoneId, stepId }) {
        try {
          return httpClient.get(`/api/workflows/v2/workflows/${workflowId}/milestones/${milestoneId}/steps/${stepId}/statistics`);
        } catch (e) {
          return {};
        }
      },
      async getWorkflowByReferences(tech, references) {
        let workflow;
        try {
          workflow = await httpClient.get(`/api/workflows/v2/workflows/by/references?${references.map(r => 'references=' + r).join('&')}`);
        } catch (e) {}
        if (!workflow) {
          workflow = await httpClient.get(`/api/workflows/v2/workflows/by/default`);
        }
        return httpClient.get(`/api/workflows/v2/workflows/${workflow.id}/milestones`);
      },
      async createInstance(tech, payload) {
        return httpClient.post(`/api/workflows/milestones`, payload);
      },
      async discardDraft({ commit }, { draftId }) {
        commit(DELETE_DRAFT.STARTED);
        await httpClient.delete(`/api/workflows/v2/workflows/milestones/${draftId}/draft/discard`);
        commit(DELETE_DRAFT.COMPLETED, draftId);
      },
      async deleteDraft({ commit }, { draftId }) {
        commit(DELETE_DRAFT.STARTED);
        await httpClient.delete(`/api/workflows/v2/workflows/milestones/${draftId}/draft/template`);
        commit(DELETE_DRAFT.COMPLETED, draftId);
      },
      async deleteTemplate({ commit }, { id }) {
        commit(DELETE_ITEM.STARTED);
        await httpClient.delete(`/api/workflows/v2/workflows/milestones/${id}`);
        commit(DELETE_ITEM.COMPLETED, id);
      },
      async upsertDraft({ commit }, { workflowId, draftId, data }) {
        commit(UPSERT_DRAFT.STARTED);
        const draft = await httpClient.put(`/api/workflows/v2/workflows/${workflowId}/milestones/${draftId}/draft/template`, data);
        commit(UPSERT_DRAFT.COMPLETED, draft);
      },
      async upsertDraftForTemplate({ commit }, { workflowId, milestoneId, data }) {
        commit(UPSERT_DRAFT.STARTED);
        const draft = await httpClient.put(`/api/workflows/v2/workflows/${workflowId}/milestones/${milestoneId}/draft`, data);
        commit(UPSERT_DRAFT.COMPLETED, draft);
      },
      async getDraftForTemplate({ commit }, { milestoneId }) {
        commit(GET_DRAFT.STARTED);
        const draft = await httpClient.get(`/api/workflows/v2/workflows/milestones/${milestoneId}/draft`);
        commit(GET_DRAFT.COMPLETED, draft);
      },
      async getDraftById({ commit }, { draftId }) {
        commit(GET_DRAFT.STARTED);
        const draft = await httpClient.get(`/api/workflows/v2/workflows/milestones/${draftId}/draft/template`);
        commit(GET_DRAFT.COMPLETED, draft);
      },
      async getRevisionsForTemplate({ commit }, { milestoneId }) {
        commit(GET_REVISIONS.STARTED);
        const revisions = await httpClient.get(`/api/workflows/v2/workflows/milestones/${milestoneId}/revisions`);
        commit(GET_REVISIONS.COMPLETED, revisions);
      },
      async activateRevision({ commit }, { workflowId, milestoneId, data }) {
        await httpClient.put(`/api/workflows/v2/workflows/${workflowId}/milestones/${milestoneId}/activate/revision`, data);
      },
      async activate({ commit }, { workflowId, milestoneId }) {
        try {
          commit(ACTIVATE.STARTED, { milestoneId });
          await httpClient.post(`/api/workflows/v2/workflows/${workflowId}/milestones/${milestoneId}/activate`, {});
          commit(ACTIVATE.COMPLETED, { milestoneId, isActive: true });
        } catch (error) {
          commit(ACTIVATE.FAILED);
        }
      },
      async deactivate({ commit }, { workflowId, milestoneId }) {
        try {
          commit(ACTIVATE.STARTED, { milestoneId });
          await httpClient.post(`/api/workflows/v2/workflows/${workflowId}/milestones/${milestoneId}/deactivate`, {});
          commit(ACTIVATE.COMPLETED, { milestoneId, isActive: false });
        } catch (error) {
          commit(ACTIVATE.FAILED);
        }
      }
    }
  })('milestones', {
    async getById({ workflowId, milestoneId }) {
      const collection = await httpClient.get(`/api/workflows/v2/workflows/${workflowId}/milestones`);
      const milestone = collection.find(milestone => milestone.id === milestoneId);
      return milestone;
    },
    async getCollection(workflowId) {
      return httpClient.get(`/api/workflows/v2/workflows/${workflowId}/milestones`);
    },
    async create({ workflowId, data }) {
      return await httpClient.post(`/api/workflows/v2/workflows/${workflowId}/milestones/draft/template`, data);
    },
    async update(smthng, { workflowId, milestoneId, data }) {
      return httpClient.put(`/api/workflows/v2/workflows/${workflowId}/milestones/${milestoneId}`, data);
    },
    async delete(id) {}
  })
};
