export const STATUS = {
  ['queued']: {
    label: 'Queued',
    color: '#fff'
  },
  ['requeued']: {
    label: 'Requeued',
    color: '#fff9c4'
  },
  ['inprogress']: {
    label: 'In Progress',
    color: '#f9a825'
  },
  ['failed']: {
    label: 'Failed',
    color: '#da2d2d'
  },
  ['completed']: {
    label: 'Completed',
    color: '#2e815b'
  }
};
