<template>
  <section v-if="isGetRequestPending" class="edit-drawing">
    <p-loading />
  </section>
  <section v-else class="edit-drawing">
    <header>
      <h1>Edit Drawing</h1>
      <div class="action-wrapper">
        <p-button v-if="$hasPermission('templates.write')" color="secondary" @click="confirmDelete">Delete</p-button>
      </div>
    </header>
    <p-edit-form :is-request-pending="isUpdateRequestPending" :is-request-failed="isUpdateRequestFailed" :item="item" @submit="update" />
    <footer></footer>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import CreateOrEditForm from './_CreateOrEdit';
import Loading from '@/components/common/Loading';
import Button from '@/components/common/Button';

export default {
  components: {
    'p-edit-form': CreateOrEditForm,
    'p-loading': Loading,
    'p-button': Button
  },
  computed: {
    ...mapState({
      item: s => s.drawings.item,
      isGetRequestPending: s => s.drawings.isGetRequestPending,
      isUpdateRequestPending: s => s.drawings.isUpdateRequestPending,
      isUpdateRequestFailed: s => s.drawings.isUpdateRequestFailed,

      isDeleteRequestFailed: s => s.drawings.isDeleteRequestFailed
    })
  },
  watch: {
    '$route.params': {
      handler() {
        this.$store.dispatch('drawings/getById', this.$route.params.id);
      },
      immediate: true
    }
  },
  methods: {
    async update(data) {
      try {
        await this.$store.dispatch('drawings/update', { ...data, id: this.$route.params.id });

        this.$toast.success({
          title: 'Update completed',
          message: `Drawing was updated.`
        });
        this.$router.push({ path: `/drawings/` });
      } catch (e) {
        const { message } = JSON.parse(await e.response.text());
        this.$toast.error({
          title: 'Update failed',
          message: message || `Please, try again later or contact our development team.`
        });
      }
    },
    async confirmDelete() {
      const { id, name } = this.item;

      const confirmResult = await this.$confirm({
        title: 'Delete drawing?',
        message: `Are you sure you want to delete drawing '${name}'?\n\nThis action can't be undone.`,
        confirm: 'Delete'
      });

      if (!confirmResult) {
        return;
      }

      const lock = this.$lock();

      try {
        this.$toast.success({
          title: 'Drawing deleted',
          message: `Drawing '${name}' (${id}) was successfully deleted.`
        });
        this.$router.push({ path: `/drawings` });
      } catch (e) {
        this.$toast.error({
          title: 'Failed to delete drawing',
          message: `Please, try again later or contact our development team.`
        });
      } finally {
        lock.release();
      }
      await this.$store.dispatch('drawings/delete', id);
    }
  }
};
</script>

<style lang="scss" scoped>
.edit-drawing {
  display: grid;
  grid-gap: 0.5rem;
  grid-template-rows: minmax(0, max-content) minmax(0, 1fr);
  width: 100%;
  height: 100%;
  padding: 0 0.5rem 0 1rem;

  header {
    width: 100%;
    padding: 0.5rem 0.5rem 0 0;
    box-sizing: border-box;
    position: relative;
  }

  h1 {
    color: var(--theme-on-surface);
    font-size: 1.5rem;
    font-weight: 700;
  }

  .action-wrapper {
    position: absolute;
    right: 0rem;
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>
