import Vue from 'vue';
import Vuex from 'vuex';

import identity from './identity';
import specifications from './specifications';
import portfolio from './portfolio';
import users from './users';
import teams from './teams';
import processing from './processing';
import uspto from './uspto';
import fip from './fip';
import categorization from './categorization';
import visio from './visio';
import word from './word';
import drawings from './drawings';
import shapes from './shapes';
import forms from './forms';
import messages from './messages';
import rules from './rules';
import administration from './administration';
import authentication from './authentication';
import organizations from './organizations';
import workflows from './workflows';
import milestones from './milestones';
import docketingTasks from './docketingTasks';
import bots from './bots';
import prompts from './prompts';
import nlp from './nlp';
import inventions from './inventions';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    identity,
    specifications,
    portfolio,
    users,
    teams,
    processing,
    uspto,
    fip,
    categorization,
    visio,
    word,
    drawings,
    shapes,
    forms,
    messages,
    administration,
    authentication,
    organizations,
    rules,
    workflows,
    milestones,
    docketingTasks,
    bots,
    prompts,
    nlp,
    inventions
  },
  state: {},
  mutations: {},
  actions: {}
});
