<template>
  <p-portfolio v-if="$hasPermission('portfolio.read')" />
  <p-not-found v-else />
</template>

<script>
import Portfolio from '@/components/portfolio/Portfolio';
import NotFound from './../components/NotFound.vue';

export default {
  components: {
    'p-portfolio': Portfolio,
    'p-not-found': NotFound
  }
};
</script>
