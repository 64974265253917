import { cruduex } from './utils';

import httpClient, { jsonObjectToFormData } from '@/utils/httpClient';

export default {
  namespaced: true,
  ...cruduex('word', {
    async getById(id) {
      return httpClient.get(`/api/docs/word/${id}`);
    },
    async getCollection() {
      return httpClient.get('/api/docs/word/');
    },
    async create(data) {
      return httpClient.request('/api/docs/word/', {
        json: false,
        method: 'POST',
        body: jsonObjectToFormData(data)
      });
    },
    async update(id, data) {
      return httpClient.request(`/api/docs/word/${id}`, {
        json: false,
        method: 'PATCH',
        body: jsonObjectToFormData(data)
      });
    },
    async delete(id) {
      return httpClient.delete(`/api/docs/word/${id}`);
    }
  })
};
