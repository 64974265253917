export const lw = {
  get(key) {
    try {
      const item = localStorage.getItem(key);
      return JSON.parse(item);
    } catch {
      return null;
    }
  },
  set(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  }
};
