<template>
  <div>
    <img v-if="visible" alt="Patent Hub logo" src="./../../assets/logo.svg" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      timeout: null,
      visible: false
    };
  },
  created() {
    this.visible = false;
    this.timeout = setTimeout(() => {
      this.visible = true;
      this.timeout = null;
    }, 100);
  },
  destroyed() {
    this.timeout && clearTimeout(this.timeout);
  }
};
</script>

<style lang="scss" scoped>
div {
  display: inline-flex;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -100%);

  @keyframes pulse {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0.1;
    }
  }

  img {
    animation: pulse 2s infinite linear;
    max-height: 50px;
    height: 50px;
  }
}
</style>
