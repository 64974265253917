<template>
  <p-create-configuration v-if="$hasPermission('administration')" />
  <p-not-found v-else />
</template>

<script>
import CreateConfiguration from '@/components/llm-configurations/CreateModelConfiguration.vue';
import NotFound from '@/components/NotFound.vue';

export default {
  components: {
    'p-create-configuration': CreateConfiguration,
    'p-not-found': NotFound
  }
};
</script>
