import { am } from './utils';

import httpClient from '@/utils/httpClient';

const logger = console;

const INITIALIZE = am(`INITIALIZE`);
const SYNC_MARK = am(`SYNC_MARK`);
const SYNC_DELETE = am(`SYNC_DELETE`);
const GET_COLLECTION = am(`GET_USPTO_MIRROR_COLLECTION`);
const CREATE = am(`CREATE_USPTO_MIRROR_PROCESSING`);
const DELETE = am(`DELETE_USPTO_MIRROR_PROCESSING`);

const UPDATE_PROCESSING = am(`UPDATE_USPTO_MIRROR`);

const state = {
  isGetCollectionRequestPending: false,
  isGetCollectionRequestFailed: false,

  isCreateRequestPending: false,
  isCreateRequestFailed: false,

  isDeleteRequestPending: false,
  isDeleteRequestFailed: false,

  isSyncRequestPending: false,
  isSyncRequestFailed: false,

  isSyncDeleteRequestPending: false,
  isSyncDeleteRequestFailed: false,

  collection: [],
  total: 0,
  size: 0,
  from: 0,
  aggs: null,

  isReady: false,
  active: null,
  toDelete: []
};

const mutations = {
  [INITIALIZE.STARTED](state) {
    // state.isReady = false;
    // state.active = null;
    state.jobs = null;
  },
  [INITIALIZE.COMPLETED](state, { active, jobs, toDelete }) {
    state.isReady = true;
    state.active = active;
    state.jobs = jobs;
    state.toDelete = toDelete;
  },
  [SYNC_MARK.STARTED](state) {
    state.isSyncRequestPending = true;
    state.isSyncRequestFailed = false;
  },
  [SYNC_MARK.COMPLETED](state) {
    state.isSyncRequestPending = false;
    state.isSyncRequestFailed = false;
  },
  [SYNC_MARK.FAILED](state) {
    state.isSyncRequestPending = false;
    state.isSyncRequestFailed = true;
  },
  [SYNC_DELETE.STARTED](state) {
    state.isSyncRequestPending = true;
    state.isSyncRequestFailed = false;
  },
  [SYNC_DELETE.COMPLETED](state) {
    state.isSyncRequestPending = false;
    state.isSyncRequestFailed = false;
  },
  [SYNC_DELETE.FAILED](state) {
    state.isSyncRequestPending = false;
    state.isSyncRequestFailed = true;
  },
  [GET_COLLECTION.STARTED](state) {
    state.isGetCollectionRequestPending = true;
    state.isGetCollectionRequestFailed = false;
  },
  [GET_COLLECTION.FAILED](state) {
    state.isGetCollectionRequestPending = false;
    state.isGetCollectionRequestFailed = true;
  },
  [GET_COLLECTION.COMPLETED](state, collection) {
    state.isGetCollectionRequestPending = false;
    state.collection = [...collection.data];
    state.aggs = collection.aggs;
    state.total = collection.total;
    state.size = collection.size;
    state.from = collection.from;
  },

  [CREATE.STARTED](state) {
    state.isCreateRequestPending = true;
    state.isCreateRequestFailed = false;
  },
  [CREATE.FAILED](state) {
    state.isCreateRequestPending = false;
    state.isCreateRequestFailed = true;
  },
  [CREATE.COMPLETED](state, collection) {
    state.isCreateRequestPending = false;
    for (const item of collection) {
      const existing = state.collection.find(e => e.id === item.id);
      if (existing) {
        existing.status = item.status;
        existing.updatedAt = item.updatedAt;
        existing.message = item.message;
      }
    }
  },

  [DELETE.STARTED](state) {
    state.isDeleteRequestPending = true;
    state.isDeleteRequestFailed = false;
  },
  [DELETE.FAILED](state) {
    state.isDeleteRequestPending = false;
    state.isDeleteRequestFailed = true;
  },
  [DELETE.COMPLETED](state, id) {
    state.isDeleteRequestPending = false;
    const index = state.collection.findIndex(t => t.id === id);
    if (index) {
      state.collection.splice(index, 1);
    }
    if (state.item && state.item.id === id) {
      state.item = null;
    }
  },

  [UPDATE_PROCESSING.COMPLETED](state, { active }) {
    state.active = active;
  }
};

const actions = {
  async initialize({ commit }) {
    commit(INITIALIZE.STARTED);
    try {
      const result = await Promise.all([httpClient.get(`/api/uspto/downloads/status`), httpClient.get(`/api/uspto/sync`)]);
      const response = result[0];
      const itemsToDelete = result[1];

      commit(INITIALIZE.COMPLETED, { ...response, toDelete: itemsToDelete });
    } catch (e) {
      logger.error(e);
    }
  },
  async syncDelete({ commit }) {
    commit(SYNC_DELETE.STARTED);
    try {
      const result = await httpClient.post(`/api/uspto/sync/delete`);
      commit(SYNC_DELETE.COMPLETED);
      return result;
    } catch (e) {
      logger.error(e);
      commit(SYNC_DELETE.FAILED);
    }

    return {};
  },
  async syncMark({ commit }) {
    commit(SYNC_MARK.STARTED);
    try {
      const result = await httpClient.post(`/api/uspto/sync/mark`);
      commit(SYNC_MARK.COMPLETED);
      return result;
    } catch (e) {
      logger.error(e);
      commit(SYNC_MARK.FAILED);
    }

    return {};
  },
  async create({ commit }, { applications }) {
    commit(CREATE.STARTED);
    try {
      const response = await httpClient.post(`/api/uspto/downloads/`, { collection: applications });
      commit(CREATE.COMPLETED, response);

      return response;
    } catch (e) {
      commit(CREATE.FAILED);
      logger.error(e);
    }
  },

  async delete({ commit }, id) {
    commit(DELETE.STARTED);
    try {
      await httpClient.delete(`/api/uspto/downloads/${encodeURIComponent(id)}`);
      commit(DELETE.COMPLETED, id);
    } catch (e) {
      commit(DELETE.FAILED);
      logger.error(e);
    }
  },

  async getCollection({ commit }, payload) {
    commit(GET_COLLECTION.STARTED);
    try {
      const { data, total, aggs } = await httpClient.post(`/api/uspto/downloads/sorted`, payload);

      commit(GET_COLLECTION.COMPLETED, {
        data,
        total,
        aggs
      });
    } catch (e) {
      commit(GET_COLLECTION.FAILED);
      logger.error(e);
    }
  },

  async pause({ commit }) {
    try {
      await httpClient.post(`/api/uspto/downloads/pause`);
      commit(UPDATE_PROCESSING.COMPLETED, { active: false });
    } catch (e) {
      logger.error(e);
    }
  },
  async resume({ commit }) {
    try {
      await httpClient.post(`/api/uspto/downloads/resume`);
      commit(UPDATE_PROCESSING.COMPLETED, { active: true });
    } catch (e) {
      logger.error(e);
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
