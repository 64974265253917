<template>
  <div v-if="visible" class="modal-overlay">
    <section class="modal">
      <header>
        <h2>
          <slot name="title"></slot>
        </h2>
      </header>
      <div>
        <slot />
      </div>
      <footer>
        <slot name="footer"></slot>
      </footer>
    </section>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  watch: {
    visible: {
      handler() {},
      immediate: true
    }
  }
};
</script>

<style lang="scss">
.modal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 6;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.6);
  transition: 0.35s cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: opacity;
  will-change: opacity;
}
.modal {
  header {
    h2 {
      font-size: 1.2rem;
      font-weight: 500;
      text-transform: uppercase;
      padding: 1.75rem 2rem 0.75rem 2rem;
    }
  }
  > div {
    overflow-y: auto;
    overflow-x: auto;
    max-height: 80vh;
  }

  footer {
    padding: 0.75rem 2rem 0.75rem 2rem;
    display: flex;
    justify-content: flex-end;
  }
  display: grid;
  grid-template-rows: max-content minmax(300px, auto) max-content;
  max-width: 90%;
  min-width: 50%;
  background: var(--theme-background);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: center center;
  transition: opacity 0.15s cubic-bezier(0.25, 0.8, 0.25, 1), transform 0.2s cubic-bezier(0.25, 0.8, 0.25, 1);
  z-index: 7;
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
  border-radius: 2px;
}
</style>
