<template>
  <section class="create-user">
    <header>
      <h1>New User</h1>
    </header>
    <form :class="{ dirty: $v.$anyDirty }" autocomplete="off" @submit.prevent="submit">
      <div class="form-row">
        <p-text-field v-model="firstName" :label="'First name'" autocomplete="off" />
        <div v-if="!$v.firstName.required" class="error">First name is required</div>
      </div>
      <div class="form-row">
        <p-text-field v-model="lastName" :label="'Last name'" autocomplete="off" />
        <div v-if="!$v.lastName.required" class="error">Last name is required</div>
      </div>
      <div class="form-row">
        <p-text-field v-model="email" :label="'Email'" autocomplete="off" />
        <div v-if="!$v.email.required" class="error">Email is required</div>
        <div v-if="!$v.email.email" class="error">Email has invalid format</div>
      </div>
      <div class="form-row">
        <div style="display: grid; grid-template-columns: 1fr max-content">
          <p-text-field v-model="password" :label="'Password'" type="text" autocomplete="new-password" />
          <p-button type="button" style="place-self: end" title="Randomize password" @click.prevent="randomizePassword">
            <p-md-icon name="form-textbox-password"></p-md-icon>
          </p-button>
        </div>
        <div v-if="!$v.password.required" class="error">Password is required</div>
        <div v-if="!$v.password.minLength" class="error">Password must be at least {{ $v.password.$params.minLength.min }} characters long.</div>
      </div>
      <div class="form-row">
        <p-tags v-model="assistants" :disabled="!$hasPermission('users.write')" label="Assistants" placeholder="" :options="suggestMember" />
      </div>
      <div class="form-row bot-row">
        <p-checkbox v-model="isBot" label="Is Bot"></p-checkbox>
        <div v-if="isBot" class="script-buttons">
          <p-button v-if="newScript" type="contained" @click="removeScript" @click.prevent><p-icon name="remove"/></p-button>
          <p-file-select :class="{ newScript }" :accept="'.json,.js'" :title="title" @change="importBackup"><p-icon name="script"/></p-file-select>
        </div>
      </div>
      <div v-if="isCreateRequestFailed" class="error">Failed to create a new user. Check your input and try again.</div>
      <div class="form-row submit">
        <p-button type="button" @click.prevent="$router.back()">Cancel</p-button>
        <p-button color="primary" type="submit" :disabled="isCreateRequestPending || ($v.$anyDirty && $v.$invalid)">Save</p-button>
      </div>
    </form>

    <footer></footer>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import httpClient from '@/utils/httpClient';
import { required, minLength, email } from 'vuelidate/lib/validators';

import TextField from '@/components/common/TextField';
import Button from '@/components/common/Button';
import MdIcon from '@/components/common/MdIcon';
import Tags from '@/components/common/Tags';
import CheckBox from '@/components/common/Checkbox.vue';
import FileSelect from '@/components/common/FileSelect';
import Icon from '@/components/common/Icon';

export default {
  components: {
    'p-text-field': TextField,
    'p-button': Button,
    'p-md-icon': MdIcon,
    'p-tags': Tags,
    'p-checkbox': CheckBox,
    'p-file-select': FileSelect,
    'p-icon': Icon
  },
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      isBot: false,
      assistants: [],
      newScript: null
    };
  },
  computed: {
    ...mapState({
      isCreateRequestPending: s => s.users.isCreateRequestPending,
      isCreateRequestFailed: s => s.users.isCreateRequestFailed,
      practitioners: s => s.portfolio.practitioners.collection
    }),
    title() {
      return this.newScript ? `'${this.newScript.name}' ready to upload` : `Select bot script`;
    }
  },
  validations: {
    email: {
      required,
      email
    },
    password: {
      required,
      minLength: minLength(6)
    },
    firstName: {
      required
    },
    lastName: {
      required
    }
  },
  async created() {
    await this.$store.dispatch('portfolio/practitioners/getCollection');
  },
  methods: {
    importBackup([file] = []) {
      this.newScript = file;
    },
    removeScript() {
      this.newScript = null;
    },
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      try {
        const user = await this.$store.dispatch('users/create', {
          firstName: this.firstName.trim(),
          lastName: this.lastName.trim(),
          email: this.email.trim(),
          password: this.password,
          isBot: this.isBot,
          assistants: this.assistants
        });

        if (this.isBot && this.newScript) {
          await this.$store.dispatch('bots/uploadScript', {
            email: user.email,
            script: this.newScript
          });
        }

        this.$router.push({ path: `/people` });
      } catch (error) {
        const { message } = JSON.parse(await error.response.text());
        this.$toast.error({
          title: 'Create failed',
          message: message || `Please, try again later or contact our development team.`
        });
      }
    },
    back() {
      this.$router.back();
    },
    randomizePassword() {
      this.password = Math.random()
        .toString(36)
        .substring(2);
    },
    async suggestMember(s) {
      if (s?.length) {
        return httpClient.get('/api/auth/suggest/users?q=' + s);
      } else {
        return [];
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.create-user {
  width: 100%;
  header {
    width: 100%;
    padding: 1rem 2rem;
    border-bottom: 1px solid var(--theme-highlight);
    box-sizing: border-box;
  }

  h1 {
    color: var(--theme-on-surface);
    font-size: 1.5rem;
    font-weight: 700;
  }
  .submit {
    margin-top: 25px;
  }
  .bot-row {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    height: 40px;

    .script-buttons {
      display: flex;
      justify-content: flex-end;

      .newScript {
        background: var(--theme-highlight);
        border-radius: 5px;
      }

      form {
        padding: 0;
      }
    }
  }

  form {
    padding: 2rem;
    max-width: 480px;

    > * {
      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }
      &:last-child {
        display: flex;
        justify-content: flex-end;
      }
    }

    .error {
      font-size: 0.8rem;
      color: var(--theme-error);
      text-align: left;
      padding: 0.25rem 0;
      display: none;
    }
    &.dirty {
      .error {
        display: block;
      }
    }
  }
}
</style>
