import Confirm from './../components/common/Confirm';

export default {
  install(Vue) {
    Vue.prototype.$confirm = props => {
      return new Promise(resolve => {
        var ComponentClass = Vue.extend(Confirm);
        var instance = new ComponentClass({
          propsData: props
        });
        instance.$mount();
        document.body.appendChild(instance.$el);

        const closeWithResult = result => {
          document.body.removeChild(instance.$el);
          instance.$destroy();
          resolve(result);
        };
        instance.$on('confirm', () => closeWithResult(true));
        instance.$on('cancel', () => closeWithResult());
      });
    };
  }
};
