<template>
  <p-edit v-if="$hasPermission('forms.read')" />
  <p-not-found v-else />
</template>

<script>
import Edit from './../components/forms/EditForm.vue';
import NotFound from './../components/NotFound.vue';

export default {
  components: {
    'p-not-found': NotFound,
    'p-edit': Edit
  }
};
</script>
