<template>
  <div>
    <label v-if="!!label" :for="id">{{ label }}</label>

    <input :id="id" type="text" :placeholder="placeholder" :class="{ error }" @blur="complete" @keypress.enter="complete" />
  </div>
</template>

<script>
import Cleave from 'cleave.js';

export default {
  props: {
    id: {
      type: String,
      default: () => (+new Date()).toString()
    },
    value: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: 'MM-DD-YYYY'
    },
    error: {
      type: Boolean
    }
  },
  data() {
    return {
      instance: null
    };
  },
  watch: {
    value() {
      this.instance && this.instance.setRawValue(this.value);
    }
  },
  mounted() {
    this.instance = new Cleave(this.$el.querySelector(`input`), {
      date: true,
      delimiter: '-',
      datePattern: ['m', 'd', 'Y']
    });
    this.instance.setRawValue(this.value);
  },
  destroyed() {
    this.instance && this.instance.destroy();
  },
  methods: {
    complete() {
      let value = this.instance.getRawValue();

      if (/\d{8}/.test(value) || value.length === 0) {
        this.$emit('change', value && value.length ? value : undefined);
        this.$emit('input', value && value.length ? value : undefined);
      } else {
        this.instance.setRawValue(null);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
div {
  display: grid;
  grid-template-rows: max-content max-content;
  grid-gap: 5px;

  > * {
    min-width: 0;
    min-height: 0;
  }

  label {
    font-weight: 500;
    font-size: 0.75rem;
    letter-spacing: 0.025em;
  }

  input,
  textarea {
    padding: 8px 12px;
    font-size: 0.85rem;
    font-weight: 400;
    border-radius: 2px;
    border: 1px solid var(--theme-on-background-dark);
    color: var(--theme-on-background-dark);
    background: var(--theme-background);
    outline: none;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    &.error {
      border-color: var(--theme-error);
    }

    &:hover {
      border-color: var(--theme-on-background);
      color: var(--theme-on-background);
    }

    &:focus,
    &:active {
      border-color: var(--theme-on-background);
      color: var(--theme-on-background);
      outline: none;
    }
  }

  textarea {
    resize: none;
    height: 62px;
  }
}
</style>
