<template>
  <Editor v-if="!isLoading" />
  <Loading v-else />
</template>
<script>
import { mapState } from 'vuex';
import Editor from './Editor';
import Loading from '@/components/common/Loading';

export default {
  components: {
    Editor,
    Loading
  },
  computed: {
    ...mapState({
      milestone: s => s.milestones.item,
      draft: s => s.milestones.draft,
      revisions: s => s.milestones.revisions,
      isLoading: s =>
        s.milestones.isGetRequestPending ||
        s.milestones.isContractsRequestPending ||
        s.milestones.isTemplatesRequestPending ||
        s.milestones.isGetRevisionsPending
    })
  },
  async created() {
    const { workflowId, milestoneId } = this.$route.params;
    await Promise.all([
      this.$store.dispatch('milestones/getById', { workflowId, milestoneId }),
      this.$store.dispatch('milestones/getDraftForTemplate', { milestoneId }),
      this.$store.dispatch('milestones/getRevisionsForTemplate', { milestoneId }),
      this.$store.dispatch('milestones/getContracts'),
      this.$store.dispatch('milestones/getTemplates')
    ]);
  }
};
</script>
