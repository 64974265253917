import { cruduex, am } from './utils';

import httpClient from '@/utils/httpClient';

export default {
  namespaced: true,
  ...cruduex('organizations', {
    async getById(id) {
      return await httpClient.get(`/api/organizations/${id}`);
    },
    async getCollection() {
      return await httpClient.get(`/api/organizations/`);
    },
    async create(obj) {
      return httpClient.post(`/api/organizations/`, obj);
    },
    async update(id, data) {
      return httpClient.patch(`/api/organizations/${id}`, data);
    },
    async delete(id) {
      return httpClient.delete(`/api/organizations/${id}`);
    }
  })
};
