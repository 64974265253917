import { render, staticRenderFns } from "./MilsestoneTags.vue?vue&type=template&id=34475985&"
import script from "./MilsestoneTags.vue?vue&type=script&lang=js&"
export * from "./MilsestoneTags.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports