<template>
  <section class="create-message">
    <header>
      <h1>New Message</h1>
    </header>
    <p-create-message-form :is-request-pending="isCreateRequestPending" :is-request-failed="isCreateRequestFailed" @submit="create" />
    <footer></footer>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import CreateOrEditMessageForm from './_CreateOrEdit';

export default {
  components: {
    'p-create-message-form': CreateOrEditMessageForm
  },
  computed: {
    ...mapState({
      isCreateRequestPending: s => s.messages.isCreateRequestPending,
      isCreateRequestFailed: s => s.messages.isCreateRequestFailed
    })
  },
  methods: {
    async create(data) {
      try {
        await this.$store.dispatch('messages/create', data);

        this.$toast.success({
          title: 'Create completed',
          message: `Message was created.`
        });
        this.$router.push({ path: `/messages/` });
      } catch (e) {
        this.$toast.error({
          title: 'Create failed',
          message: `Please, try again later or contact our development team.`
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.create-message {
  display: grid;
  grid-gap: 0.5rem;
  grid-template-rows: minmax(0, max-content) minmax(0, 1fr);
  width: 100%;
  height: 100%;
  padding: 0 0.5rem 0 1rem;

  header {
    width: 100%;
    padding: 0.5rem 0.5rem 0 0;
    box-sizing: border-box;
  }

  h1 {
    color: var(--theme-on-surface);
    font-size: 1.5rem;
    font-weight: 700;
  }
}
</style>
