var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isReady || _vm.isGetCollectionRequestPending)?_c('section',{staticClass:"specifications"},[_c('p-loading')],1):_c('section',{staticClass:"specifications"},[_c('header',[_c('h1',[_vm._v("Specifications")]),_c('section',{staticClass:"action-wrapper"},[(_vm.$hasPermission('specifications.write'))?_c('router-link',{attrs:{"to":"/specifications/create"}},[_vm._v("+ new")]):_vm._e()],1)]),_c('section',[_c('p-list',{attrs:{"name":"specifications","data":_vm.filtered,"total":_vm.total,"metadata":{
        name: { label: 'Name', width: 'minmax(0, 3fr)' },
        description: { label: 'Description', width: 'minmax(0, 3fr)' },
        tags: { label: 'Tags', width: 'minmax(100px, 2fr)' },
        type: { label: 'Type', width: '100px' },
        modifiedAt: { label: 'Last modification', width: '175px' }
      }},on:{"edit":_vm.edit},scopedSlots:_vm._u([{key:"filter",fn:function(){return [_c('p-checkbox',{attrs:{"label":"Abstract"},model:{value:(_vm.filter.abstract),callback:function ($$v) {_vm.$set(_vm.filter, "abstract", $$v)},expression:"filter.abstract"}}),_c('p-checkbox',{attrs:{"label":"Flowchart"},model:{value:(_vm.filter.flowchart),callback:function ($$v) {_vm.$set(_vm.filter, "flowchart", $$v)},expression:"filter.flowchart"}}),_c('p-checkbox',{attrs:{"label":"Summary"},model:{value:(_vm.filter.summary),callback:function ($$v) {_vm.$set(_vm.filter, "summary", $$v)},expression:"filter.summary"}}),_c('p-checkbox',{attrs:{"label":"Claims"},model:{value:(_vm.filter.claims),callback:function ($$v) {_vm.$set(_vm.filter, "claims", $$v)},expression:"filter.claims"}}),_c('p-checkbox',{attrs:{"label":"Boilerplate"},model:{value:(_vm.filter.boilerplate),callback:function ($$v) {_vm.$set(_vm.filter, "boilerplate", $$v)},expression:"filter.boilerplate"}})]},proxy:true},{key:"name",fn:function(ref){
      var value = ref.value;
      var item = ref.item;
return [_c('router-link',{attrs:{"to":("/specifications/" + (item.id))}},[_vm._v(_vm._s(value))])]}},{key:"__actions",fn:function(ref){
      var item = ref.item;
return [_c('p-button',{attrs:{"variant":"text","title":"Edit this specification"},on:{"click":function($event){return _vm.edit(item.id)}}},[_vm._v(" ✎")])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }