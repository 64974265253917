<template>
  <p-modal :visible="true">
    <span slot="title">Step notifications options</span>
    <div class="notifications-modal">
      <p-multiselect v-model="value" :disabled="disabled" :options="['', 'always', 'never']" />
    </div>

    <div class="footer" slot="footer">
      <p-button @click="$emit('cancel')">Cancel</p-button>
      <p-button color="secondary" @click="onConfirm">Ok</p-button>
    </div>
  </p-modal>
</template>

<script>
import Multiselect from '@/components/common/Multiselect';
import Button from '@/components/common/Button';
import Modal from '@/components/common/Modal';

export default {
  components: {
    'p-button': Button,
    'p-multiselect': Multiselect,
    'p-modal': Modal
  },
  props: {
    stepProperties: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      value: this.stepProperties.options.notifications.email,
      disabled: this.stepProperties.compareMode
    };
  },
  methods: {
    onConfirm() {
      this.$emit('confirm', { email: this.value || null });
    }
  }
};
</script>
<style lang="scss" scoped>
.notifications-modal {
  padding: 20px;
}
</style>
