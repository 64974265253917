<template>
  <form :class="{ dirty: $v.$anyDirty }" autocomplete="off" class="create-or-edit-message-form" @submit.prevent="submit">
    <div class="left">
      <div class="form-row">
        <p-text-field v-model="name" :disabled="!$hasPermission('messages')" :label="'Name'" autocomplete="off" />
        <ul v-if="$v.name">
          <li v-if="!$v.name.required" class="error">Name is required</li>
        </ul>
      </div>
      <div class="form-row">
        <p-text-field v-model="description" :disabled="!$hasPermission('messages')" :label="'Description'" autocomplete="off" />
      </div>
      <div class="form-row">
        <p-multiselect
          v-model="tags"
          :multiple="true"
          :taggable="true"
          label="Tags"
          placeholder=""
          tag-placeholder=""
          :disabled="!$hasPermission('messages')"
          @tag="tagAdded"
        ></p-multiselect>
      </div>
      <hr />
      <div class="form-row">
        <p-text-field v-model="subject" :disabled="!$hasPermission('messages')" :label="'Subject'" autocomplete="off" />
      </div>
      <div class="form-row">
        <p-tags
          v-model="to"
          :disabled="!$hasPermission('messages')"
          label="To"
          placeholder=""
          :validation="[validateRecipient]"
          :options="getOptions"
        />
      </div>
      <div class="form-row">
        <p-tags
          v-model="cc"
          :disabled="!$hasPermission('messages')"
          label="CC"
          placeholder=""
          :validation="[validateRecipient]"
          :options="getOptions"
        />
      </div>
      <div class="form-row">
        <p-tags
          v-model="bcc"
          :disabled="!$hasPermission('messages')"
          label="BCC"
          placeholder=""
          :validation="[validateRecipient]"
          :options="getOptions"
        />
      </div>

      <div v-if="isRequestFailed" class="error">
        Failed to save a message. Check your input and try again.
      </div>
      <div class="form-row submit">
        <p-button type="button" @click.prevent="$router.back()">Cancel</p-button>
        <p-button v-if="$hasPermission('messages')" color="primary" type="submit" :disabled="isRequestPending || ($v.$anyDirty && $v.$invalid)"
          >Save</p-button
        >
      </div>
    </div>
    <div class="right">
      <div class="form-row code">
        <p-code v-model="body" :read-only="!$hasPermission('messages')" :language="'handlebars'"></p-code>
      </div>
    </div>
  </form>
</template>

<script>
import { required } from 'vuelidate/lib/validators';

import TextField from '@/components/common/TextField';
import Button from '@/components/common/Button';
import Multiselect from '@/components/common/Multiselect';
import Tags from '@/components/common/Tags';
import Code from '@/components/common/Code';

import httpClient from '@/utils/httpClient';

export default {
  components: {
    'p-text-field': TextField,
    'p-button': Button,
    'p-multiselect': Multiselect,
    'p-code': Code,
    'p-tags': Tags
  },
  props: {
    item: {
      type: Object,
      default: null
    },
    isRequestPending: {
      type: Boolean,
      default: false
    },
    isRequestFailed: {
      type: Boolean,
      default: false
    }
  },
  data() {
    if (this.item) {
      return {
        name: this.item.name,
        description: this.item.description,
        subject: this.item.subject,
        body: this.item.body,
        to: this.item.to,
        cc: this.item.cc,
        bcc: this.item.bcc,
        tags: this.item.tags
      };
    }
    return {
      name: '',
      description: '',
      subject: '',
      body: '',
      to: [],
      cc: [],
      bcc: [],
      tags: []
    };
  },
  computed: {
    vars() {
      return [
        '@assignees',
        '@assignees.work',
        '@assignees.mailing',
        '@assignees.home',
        '@assignees.primary',
        '@assignees.secondary',
        '@applicants',
        '@applicants.work',
        '@applicants.mailing',
        '@applicants.home',
        '@applicants.primary',
        '@applicants.secondary',
        '@clients',
        '@clients.work',
        '@clients.mailing',
        '@clients.home',
        '@clients.primary',
        '@clients.secondary',
        '@foreignAssociates',
        '@foreignAssociates.work',
        '@foreignAssociates.mailing',
        '@foreignAssociates.home',
        '@inventors',
        '@inventors.work',
        '@inventors.mailing',
        '@inventors.home',
        '@inHouseAttorneys',
        '@inHouseAttorneys.work',
        '@inHouseAttorneys.mailing',
        '@inHouseAttorneys.home',
        '@inHouseParalegals',
        '@inHouseParalegals.work',
        '@inHouseParalegals.mailing',
        '@inHouseParalegals.home',
        '@legalAssistants',
        '@legalAssistants.work',
        '@legalAssistants.mailing',
        '@legalAssistants.home',
        '@patentAgents',
        '@patentAgents.work',
        '@patentAgents.mailing',
        '@patentAgents.home',
        '@paralegals',
        '@paralegals.work',
        '@paralegals.mailing',
        '@paralegals.home',
        '@responsibleAttorneys',
        '@responsibleAttorneys.work',
        '@responsibleAttorneys.mailing',
        '@responsibleAttorneys.home',
        '@secretaries',
        '@secretaries.work',
        '@secretaries.mailing',
        '@secretaries.home'
      ];
    }
  },
  validations() {
    return {
      ...(this.item === null ? { name: { required } } : {})
    };
  },
  methods: {
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.$emit('submit', {
        name: this.name.trim(),
        description: this.description,
        subject: this.subject,
        body: this.body,
        to: this.to,
        cc: this.cc,
        bcc: this.bcc,
        tags: this.tags
      });

      this.$v.$reset();
    },
    tagAdded(tag) {
      if (!Array.isArray(this.tags)) {
        this.tags = [];
      }

      if (this.tags.includes(tag)) {
        return;
      }
      this.tags.push(tag);
    },

    validateRecipient({ text }) {
      if (!text || !text.length) {
        return true;
      }

      const regexp = /[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*/;

      if (regexp.exec(text)) {
        return false;
      }

      return this.vars.indexOf(text) === -1;
    },
    async getOptions(q) {
      if (q?.startsWith('@') || q.length === 0) {
        return this.vars.filter(i => i.toLowerCase().indexOf(q.toLowerCase()) !== -1);
      }
      return httpClient.get(`/api/messages/suggest/recipients?q=${q}`);
    }
  }
};
</script>

<style lang="scss" scoped>
.create-or-edit-message-form {
  grid-gap: 0.5rem;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  display: grid;

  > div {
    min-width: 0;
    min-height: 0;
  }
  .left {
    grid-column: 1/2;
    grid-row: 1/2;
    background: var(--theme-surface);
    padding: 2rem;
    overflow-y: auto;

    > div {
      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }
      &:last-child {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
  .right {
    grid-column: 2/3;
    grid-row: 1/2;
    background: var(--theme-surface);
    padding: 2rem;

    .code {
      height: 100%;
    }
  }

  .submit {
    grid-column: 1/3;
    grid-row: 2/3;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
  // > * {
  //   &:not(:last-child) {
  //     margin-bottom: 1.5rem;
  //   }
  //   &:last-child {
  //     display: flex;
  //     justify-content: flex-end;
  //   }
  // }

  // .form-row {
  //   &.template {
  //     grid-column: 1/2;
  //     grid-row: 1/2;
  //   }
  //   &.name {
  //     grid-column: 1/2;
  //     grid-row: 2/3;
  //   }
  //   &.description {
  //     grid-column: 1/2;
  //     grid-row: 3/4;
  //   }
  //   &.tags {
  //     grid-column: 1/2;
  //     grid-row: 4/5;
  //   }

  //   &.code {
  //     grid-column: 2/3;
  //     grid-row: 1/5;
  //   }
  //   &.submit {
  //     grid-column: 1/3;
  //     grid-row: 5/6;
  //   }
  // }

  .error {
    font-size: 0.8rem;
    color: var(--theme-error);
    text-align: left;
    padding: 0.25rem 0;
    display: none;
  }
  &.dirty {
    .error {
      display: block;
    }
  }
}
</style>
