<template>
  <p-modal :visible="true">
    <span slot="title">{{ roadmap.length ? 'Edit' : 'Create' }} roadmap</span>
    <div class="roadmap-modal">
      <div v-for="(stage, index) in localStages" :key="index" class="stage">
        <div class="row">
          <p-button @click="removeStage(index)"><md-icon name="window-close"></md-icon></p-button>
          <p-text-field v-model="stage.name" label="Stage name" />
          <p-button v-if="index !== 0" @click="swap(index, -1)"><md-icon name="arrow-up"></md-icon></p-button>
          <p-button v-if="index !== localStages.length - 1" @click="swap(index, 1)"><md-icon name="arrow-down"></md-icon></p-button>
        </div>
        <div>
          <div v-for="(step, stepIndex) in stage.steps" :key="step" class="subRow">
            <p-button @click="removeStep(index, stepIndex)"><md-icon name="window-close"></md-icon></p-button>
            <span>
              {{ stepIdToName(step) }}
            </span>
          </div>
        </div>
      </div>
      <p-button color="primary" @click="addStage">Add stage</p-button>
    </div>
    <div class="footer" slot="footer">
      <p-button @click="$emit('close')">Cancel</p-button>
      <p-button color="secondary" @click="onConfirm">Ok</p-button>
    </div>
  </p-modal>
</template>

<script>
import Modal from '@/components/common/Modal';
import Button from '@/components/common/Button';
import TextField from '@/components/common/TextField';
import MdIcon from '@/components/common/MdIcon';
// import Multiselect from '@/components/common/Multiselect';

export default {
  components: {
    'p-button': Button,
    'p-modal': Modal,
    'p-text-field': TextField,
    // 'p-multiselect': Multiselect,
    MdIcon
  },
  props: {
    roadmap: {
      type: Array,
      default: () => []
    },
    steps: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      localStages: this.roadmap.map(s => ({ ...s }))
    };
  },
  computed: {
    // allAdded() {
    //   return this.localStages.reduce((acc, curr) => {
    //     acc.push(...curr.steps);
    //     return acc;
    //   }, []);
    // },
    // options() {
    //   return this.steps.filter(s => !this.allAdded.includes(s.id));
    // }
  },
  methods: {
    onConfirm() {
      this.$emit('save', this.localStages);
    },
    addStage() {
      this.localStages.push({
        name: '',
        steps: []
      });
    },
    swap(index, diff) {
      const arr = [...this.localStages];
      const swapped = arr[index];
      arr[index] = arr[index + diff];
      arr[index + diff] = swapped;

      this.localStages = arr;
    },
    removeStage(index) {
      this.localStages.splice(index, 1);
    },
    stepIdToName(id) {
      const step = this.steps.find(s => s.id === id);
      if (step) {
        return step.title;
      }
    },
    removeStep(index, stepIndex) {
      this.localStages[index].steps.splice(stepIndex, 1);
    }
  }
};
</script>
<style lang="scss" scoped>
.roadmap-modal {
  padding: 20px;
  margin-bottom: 10px;
  .stage {
    margin-bottom: 20px;
    .row {
      display: grid;
      grid-template-columns: 25px 1fr 25px 25px;
      gap: 20px;
      align-items: flex-end;
      margin-bottom: 20px;
    }
    .subRow {
      padding-left: 70px;
      display: grid;
      grid-template-columns: 20px 1fr;
      font-size: 0.75rem;
      gap: 20px;
      align-items: center;
      position: relative;
      &:before {
        position: absolute;
        content: '';
        width: 10px;
        left: 45px;
        height: 100%;
        border: 1px solid white;
        border-right-width: 0;
        border-top-width: 0;
        top: -50%;
      }
    }
  }
}
</style>
