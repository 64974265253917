<template>
  <section v-if="isGetRequestPending">
    <p-loading />
  </section>
  <section v-else-if="item.name" class="prompts">
    <header>
      <div class="prompt-name-wrapper">
        <Button class="button" @click="$router.push({ path: '/prompts' })">
          <MdIcon name="arrow-left" />
          <label class="prompt-name">{{ item && item.name }}</label>
        </Button>
        <div v-if="organizationsCollection.length" class="prompt-organization">
          <div v-if="item && item.isGeneric" class="organization public">
            GENERIC
          </div>
          <div v-for="organization of organizationsNames" :key="organization" class="organization">
            {{ organization }}
          </div>
        </div>
      </div>
    </header>
    <PromptContent ref="promptContent" :item="item" @submit="save" />
  </section>
</template>

<script>
import { mapState } from 'vuex';

import Loading from '@/components/common/Loading';
import Button from '@/components/common/Button';
import MdIcon from '@/components/common/MdIcon';

import PromptContent from './PromptContent.vue';
export default {
  components: {
    'p-loading': Loading,
    PromptContent,
    Button,
    MdIcon
  },
  computed: {
    ...mapState({
      item: s => s.prompts.item,
      isGetRequestPending: s => s.prompts.isGetRequestPending,
      organizationsCollection: s => s.organizations.collection ?? []
    }),
    organizationsNames() {
      if (!this.item || !this.organizationsCollection) {
        return [];
      }

      const organizations = this.item.organizations
        ? this.item.organizations.map(id => this.organizationsCollection.find(o => o.id === id)?.name ?? id)
        : [];

      if (!organizations.length) {
        return [];
      }

      return organizations;
    }
  },
  watch: {
    '$route.params'() {
      this.initialize();
    }
  },
  async created() {
    this.initialize();
  },
  methods: {
    async readyToLeave() {
      if (!this.$refs.promptContent.hasChanges) {
        return true;
      }

      return await this.$confirm({
        title: 'You have unsaved changes.',
        message: `Are you sure you want to leave page? All changes will be lost.`,
        confirm: 'Leave anyway'
      });
    },
    async initialize() {
      await Promise.all([
        this.$store.dispatch('nlp/getConfigurations'),
        this.$store.dispatch('prompts/getById', this.$route.params.id),
        this.$store.dispatch('organizations/getCollection')
      ]);
    },
    selectTemplate(id) {
      this.$router.push({ path: `/prompts/${id}` });
    },
    async save({ id, template }) {
      await this.$store.dispatch('prompts/update', {
        id,
        template
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.prompts-wrapper {
  width: 100%;
  height: 100%;
}

.prompts {
  width: 100%;
  margin: 0 0.5rem;
  overflow: hidden;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: max-content minmax(0, 1fr);
  grid-gap: 0.5rem;

  > header {
    grid-column: 1/3;
    background: var(--theme-surface);
    padding: 0.2rem 0.5rem;
    justify-content: space-between;
    align-items: center;
    display: flex;

    .prompt-name-wrapper {
      grid-template-rows: auto auto;
      padding: 7px;
      align-items: flex-start;

      .prompt-name {
        text-transform: uppercase;
        font-weight: 600;
        color: var(--theme-on-background-dark);
        padding: 0 5px;
        font-size: 1rem;
        cursor: pointer;
      }

      .button {
        padding: 0;
      }
    }

    .prompt-organization {
      display: flex;
      flex-wrap: wrap;
      padding-left: 25px;

      .organization {
        display: flex;
        align-items: center;
        background-color: var(--theme-on-surface);
        color: black;
        margin-top: 2px;
        padding: 2px;
        padding-left: 5px;
        padding-right: 5px;
        font-size: 0.65rem;
        margin-right: 5px;
        margin-bottom: 2px;
        border-radius: 2px;
        font-weight: 600;
        letter-spacing: 0.02rem;
        user-select: none;
        cursor: auto;

        &.public {
          background-color: var(--theme-primary);
          color: var(--theme-on-primary);
        }
      }
    }
  }
}
</style>
