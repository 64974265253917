<template>
  <p-uspto v-if="$hasPermission('uspto.read')" />
  <p-not-found v-else />
</template>

<script>
import NotFound from './../components/NotFound.vue';
import Uspto from '../components/uspto/Uspto.vue';

export default {
  components: {
    'p-uspto': Uspto,
    'p-not-found': NotFound
  }
};
</script>
