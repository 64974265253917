<template>
  <section class="create-document">
    <header>
      <h1>New Document</h1>
    </header>
    <p-create-document :is-request-pending="isCreateRequestPending" :is-request-failed="isCreateRequestFailed" @submit="create" />
    <footer></footer>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import CreateOrEditDocument from './_CreateOrEdit';

export default {
  components: {
    'p-create-document': CreateOrEditDocument
  },
  computed: {
    ...mapState({
      isCreateRequestPending: s => s.visio.isCreateRequestPending,
      isCreateRequestFailed: s => s.visio.isCreateRequestFailed
    })
  },
  methods: {
    async create(data) {
      try {
        await this.$store.dispatch('visio/create', data);

        this.$toast.success({
          title: 'Create completed',
          message: `Document was created.`
        });
        this.$router.push({ path: `/documents/visio/` });
      } catch (e) {
        const { message } = JSON.parse(await e.response.text());
        this.$toast.error({
          title: 'Create failed',
          message: message || `Please, try again later or contact our development team.`
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.create-document {
  display: grid;
  grid-gap: 0.5rem;
  grid-template-rows: minmax(0, max-content) minmax(0, 1fr);
  width: 100%;
  height: 100%;
  padding: 0 0.5rem 0 1rem;

  header {
    width: 100%;
    padding: 0.5rem 0.5rem 0 0;
    box-sizing: border-box;
  }

  h1 {
    color: var(--theme-on-surface);
    font-size: 1.5rem;
    font-weight: 700;
  }
}
</style>
