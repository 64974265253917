import { render, staticRenderFns } from "./FacetedSearch.vue?vue&type=template&id=b2ae4132&scoped=true&"
import script from "./FacetedSearch.vue?vue&type=script&lang=js&"
export * from "./FacetedSearch.vue?vue&type=script&lang=js&"
import style0 from "./FacetedSearch.vue?vue&type=style&index=0&id=b2ae4132&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b2ae4132",
  null
  
)

export default component.exports