<template>
  <section v-if="isGetRequestPending" class="manage-permissions">
    <p-loading />
  </section>
  <section v-else class="manage-permissions">
    <header>
      <h1>Manage Permissions</h1>
    </header>
    <form autocomplete="off" @submit.prevent="submit">
      <div class="form-row">
        <div>Key</div>
        <span>Read and write</span>
        <span>Read</span>
      </div>
      <div v-for="item of collection" :key="item.key" class="form-row">
        <div>{{ item.key }}</div>
        <p-checkbox :disabled="!$hasPermission('users.write')" :value="item.write" @change="onChange(item.key, 'write')" />
        <p-checkbox :disabled="!$hasPermission('users.write')" :value="item.read" @change="onChange(item.key, 'read')" />
      </div>

      <div v-if="isUpdateRequestFailed" class="error">
        Failed to change permissions for user. Check your input and try again. If an error still occurs, please, contact our developers.
      </div>
      <div class="form-row submit">
        <p-button type="button" @click.prevent="$router.back()">Cancel</p-button>
        <p-button v-if="$hasPermission('users.write')" color="primary" type="submit" :disabled="isUpdateRequestPending">Update</p-button>
      </div>
    </form>

    <footer></footer>
  </section>
</template>

<script>
import { mapState } from 'vuex';

import Loading from '@/components/common/Loading';
import Button from '@/components/common/Button';
import Checkbox from '@/components/common/Checkbox';

function createPermission(permissions = [], permission) {
  const hasOverallPermission = permissions.includes(`${permission}`);
  const hasReadPermission = permissions.includes(`${permission}.read`);

  return {
    key: permission,
    write: hasOverallPermission,
    read: hasReadPermission
  };
}

export default {
  components: {
    'p-loading': Loading,
    'p-button': Button,
    'p-checkbox': Checkbox
  },
  data() {
    return {
      collection: []
    };
  },
  computed: {
    ...mapState({
      identity: s => s.identity,
      item: s => s.users.item,
      permissions: s => s.users.permissions.collection,
      isGetRequestPending: s => s.users.isGetRequestPending || s.users.permissions.isRequestPending,
      isUpdateRequestPending: s => s.users.isUpdateRequestPending,
      isUpdateRequestFailed: s => s.users.isUpdateRequestFailed
    })
  },
  watch: {
    '$route.params': {
      async handler() {
        await Promise.all([
          this.$store.dispatch('users/getById', this.$route.params.id),
          this.$store.dispatch('users/permissions/getCollection', this.$route.params.id)
        ]);

        this.collection = [];

        for (const permission of this.permissions) {
          this.collection.push(createPermission(this.item.permissions, permission));
        }
      },
      immediate: true
    }
  },
  methods: {
    async submit() {
      const permissions = this.collection.reduce((acc, curr) => {
        if (curr.write) {
          acc.push(curr.key);
        } else if (curr.read) {
          acc.push(`${curr.key}.read`);
        }
        return acc;
      }, []);

      await this.$store.dispatch('users/update', {
        id: this.$route.params.id,
        permissions
      });
      if (!this.isUpdateRequestFailed) {
        this.$router.push({ path: `/people` });
        if (this.identity.email === this.item.email) {
          window.location.reload();
        }
      }
    },
    back() {
      this.$router.back();
    },
    onChange(key, subKey) {
      const current = this.collection.find(c => c.key === key);
      if (!current.read && !current.write) {
        current[subKey] = true;
        return;
      }
      if (subKey === 'write') {
        current.read = false;
        current.write = !current.write;
      }
      if (subKey === 'read') {
        current.write = false;
        current.read = !current.read;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.manage-permissions {
  width: 100%;
  header {
    width: 100%;
    padding: 1rem 2rem;
    border-bottom: 1px solid var(--theme-highlight);
    box-sizing: border-box;

    a {
      font-size: 0.8rem;
    }
  }

  h1 {
    color: var(--theme-on-surface);
    font-size: 1.5rem;
    font-weight: 700;
  }
  form {
    padding: 2rem;
    max-width: 480px;

    > * {
      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }
      &:last-child {
        display: flex;
        justify-content: flex-end;
      }
    }

    .form-row {
      display: grid;
      grid-template-columns: 200px 100px 100px;
      align-items: center;
      margin: 0;
      padding: 0.35rem;
      &:hover {
        background-color: var(--theme-surface);
      }
      span {
        display: flex;
        justify-content: center;
        text-align: center;
      }

      &.submit {
        margin-top: 1.5rem;
        &:hover {
          background-color: transparent;
        }
      }
    }

    .error {
      font-size: 0.8rem;
      color: var(--theme-error);
      text-align: left;
      padding: 0.25rem 0;
      display: none;
    }
    &.dirty {
      .error {
        display: block;
      }
    }
  }
}
</style>
