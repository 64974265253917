<template>
  <i :class="[color, size, ...mdi]"> </i>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: null,
      validator(value) {
        return value && value.length;
      }
    },
    color: {
      type: String,
      default: '',
      validator(value) {
        return ['', 'success', 'error', 'warning'].includes(value);
      }
    },
    size: {
      type: String,
      default: 'md',
      validator(value) {
        return ['sm', 'md', 'lg', 'xlg', 'xxlg', 'xxxlg'].includes(value);
      }
    },
    spin: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    mdi() {
      const classes = ['mdi', 'mdi-' + this.name];

      if (!!this.spin) {
        classes.push('mdi-spin');
      }
      return classes;
    }
  }
};
</script>

<style lang="scss" scoped="true">
i {
  display: inline-flex;
  width: 20px;
  height: 20px;
  margin: 0;
  padding: 0;
  align-items: center;
  justify-content: center;
  color: currentColor;
  color: inherit;

  &.success {
    color: var(--theme-success);
  }

  &.error {
    color: var(--theme-error);
  }

  &.warning {
    color: var(--theme-warning);
  }

  &.sm {
    width: 16px;
    height: 16px;
    font-size: 16px;
  }

  &.md {
    width: 20px;
    height: 20px;
    font-size: 20px;
  }

  &.lg {
    width: 28px;
    height: 28px;
    font-size: 28px;
  }

  &.xlg {
    font-size: 36px;
    width: 36px;
    height: 36px;
  }

  &.xxlg {
    font-size: 48px;
    width: 48px;
    height: 48px;
  }

  &.xxxlg {
    font-size: 64px;
    width: 64px;
    height: 64px;
  }
}
</style>
