<template>
  <div
    class="milestone"
    :class="{
      'off-line': !milestone.active && !milestone.isDraft,
      draft: milestone.isDraft
    }"
  >
    <div class="template-name">
      <router-link :to="`/workflows/${milestone.workflowId}/milestones/${milestone.id}`">
        {{ milestone.title }}
      </router-link>
    </div>
    <div class="tags-wrapper">
      <tag-collection :value="milestone.tags"/>
    </div>
    <div class="template-footer">
      <div>
        <label v-if="milestone.hasDraft" variant="text" class="label">Pending changes</label>
        <label v-if="milestone.isDraft" variant="text" class="label">Draft</label>
      </div>
      <Button
        v-if="!milestone.isDraft"
        :title="milestone.active ? 'Suspend' : 'Activate'"
        class="activate-toggle"
        variant="text"
        @click="onActivate()"
      >
        <MdIcon :name="milestone.active ? 'pause' : 'play'" :color="milestone.active ? 'error' : 'success'" />
      </Button>
    </div>
  </div>
</template>
<script>
import Button from '@/components/common/Button';
import MdIcon from '@/components/common/MdIcon';
import TagCollection from '../common/TagCollection.vue';
export default {
  components: {
    Button,
    MdIcon,
    TagCollection
  },
  props: {
    milestone: {
      type: Object,
      default: () => {}
    },
    order: {
      type: Number,
      default: 1
    }
  },
  methods: {
    async onActivate() {
      if (this.milestone.isDraft || !this.$hasPermission('workflows')) {
        return;
      }

      const action = this.milestone.active ? 'Deactivate' : 'Activate';
      const message = this.milestone.active
        ? `Are you sure you want to deactivate '${this.milestone.title.toUpperCase()}'?\r\n\r\nDeactivating this tempate will suspend automatic milestone creation.`
        : `Are you sure you want to activate '${this.milestone.title.toUpperCase()}'?\r\n\r\nActivating this tempate will resume automatic milestone creation.`;
      const confirmResult = await this.$confirm({
        title: `${action} template`,
        message: message,
        confirm: action
      });

      if (!confirmResult) {
        return;
      }

      if (this.milestone.active) {
        await this.$store.dispatch('milestones/deactivate', {
          workflowId: this.$route.params.id,
          milestoneId: this.milestone.id
        });
      } else {
        await this.$store.dispatch('milestones/activate', {
          workflowId: this.$route.params.id,
          milestoneId: this.milestone.id
        });
      }

      this.$toast.success({
        message: `'${this.milestone.title}' ${this.milestone.active ? 'activated' : 'deactivated'}`
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.milestone {
  border: 1px solid #fff;
  border-left: 6px solid var(--theme-primary);
  width: 300px;
  margin-bottom: 10px;
  padding: 5px;
  position: relative;
  min-height: 70px;
  display: grid;
  grid-template-rows: 1fr max-content;
  a {
    color: #fff;
    font-weight: 400;
  }

  &.off-line {
    border-left-color: var(--theme-error);
  }

  &.draft {
    border-left-color: var(--theme-warning);
  }
  .activate-toggle {
    display: none;
    cursor: pointer;
  }
  &:hover {
    .activate-toggle {
      display: block;
    }
  }

  .template-footer {
    display: grid;
    grid-template-columns: 1fr max-content;
    align-items: center;
    height: 25px;
  }

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      visibility: hidden;
    }
  }
  .label {
    font-weight: 500;
    font-size: 0.75rem;
    letter-spacing: 0.025em;
    color: var(--theme-secondary);
  }

  .activation-button {
    cursor: pointer;

    &:hover {
      color: var(--theme-surface);
      background-color: var(--theme-secondary-light);
    }
  }
}
</style>
