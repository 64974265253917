<template>
  <section v-if="isGetRequestPending" class="edit-team">
    <p-loading />
  </section>
  <section v-else-if="!item" class="edit-team">
    <p-not-found />
  </section>
  <section v-else class="edit-team">
    <header>
      <h1>Edit Team</h1>
      <div>
        <div class="action-wrapper">
          <p-button type="button" @click.prevent="$router.back()">Back</p-button>
          <p-button v-if="$hasPermission('users.write')" color="secondary" :disabled="item.email === identity.email" @click="confirmDelete"
            >Delete</p-button
          >
        </div>
      </div>
      <div></div>
    </header>
    <div>
      <div class="form-row">
        <p-text-field :value="item.name" :label="'Name'" autocomplete="off" :disabled="true" />
      </div>
      <div class="form-row">
        <p-checkbox :value="item.assistantsTeam" label="Assistants team" :disabled="true" />
      </div>
    </div>

    <section class="users">
      <div class="user-list-wrapper">
        <div class="header-wrapper">
          <div><label>Total:</label>&nbsp;{{ item.members.length }}</div>
        </div>
        <ul class="user-list">
          <li class="user-list-item header">
            <div>Email</div>

            <div class="action-link-wrapper"></div>
          </li>
        </ul>
        <ul class="user-list">
          <li v-for="email in item.members" :key="email" class="user-list-item">
            <div>{{ email }}</div>
            <div class="action-link-wrapper">
              <p-button v-if="$hasPermission('users.write')" variant="text" @click="removeMember(email)">&times;</p-button>
            </div>
          </li>
          <li v-if="!item.members.length" class="user-list-item">
            <div>No members yet</div>
            <div class="action-link-wrapper"></div>
          </li>
        </ul>
      </div>
    </section>
    <section>
      <h3>Add a new Member</h3>
      <form :class="{ dirty: $v.$anyDirty }" autocomplete="off" @submit.prevent="submit">
        <div class="form-row">
          <!-- <p-text-field v-model="emailToAdd" :label="'Email'" autocomplete="off" /> -->
          <p-multiselect
            v-model="emailToAdd"
            :disabled="!$hasPermission('users.write')"
            :label="'Email'"
            placeholder=""
            :search-change-callback="suggestMember"
            :taggable="true"
            tag-placeholder=""
            @tag="tag"
          />
          <div v-if="!$v.emailToAdd.required" class="error">Email is required</div>
          <div v-if="!$v.emailToAdd.email" class="error">Email is not well-formed</div>
          <div v-if="!$v.emailToAdd.unique" class="error">Email should be unique</div>
        </div>
        <div v-if="isAddMemberFailed" class="error">
          Failed to add a member. Check your input and try again. If an error still occurs, please, contact our developers.
        </div>
        <div class="form-row submit">
          <p-button v-if="$hasPermission('users.write')" color="primary" type="submit" :disabled="isAddMemberPending || ($v.$anyDirty && $v.$invalid)"
            >Add</p-button
          >
        </div>
      </form>
    </section>
    <footer></footer>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import { required, email } from 'vuelidate/lib/validators';

import Loading from '@/components/common/Loading';
import TextField from '@/components/common/TextField';
import Multiselect from '@/components/common/Multiselect';
import Button from '@/components/common/Button';
import NotFound from '@/components/NotFound';

import httpClient from './../../utils/httpClient';
import Checkbox from '../common/Checkbox.vue';

export default {
  components: {
    'p-loading': Loading,
    'p-text-field': TextField,
    'p-button': Button,
    'p-multiselect': Multiselect,
    'p-not-found': NotFound,
    'p-checkbox': Checkbox
  },
  data() {
    return {
      emailToAdd: ''
    };
  },
  computed: {
    ...mapState({
      identity: s => s.identity,
      item: s => s.teams.item,
      isGetRequestPending: s => s.teams.isGetRequestPending,
      isAddMemberPending: s => s.teams.isAddMemberPending,
      isAddMemberFailed: s => s.teams.isAddMemberFailed
    })
  },
  validations: {
    emailToAdd: {
      required,
      email,
      unique: (value, vm) => {
        return vm.item?.members?.indexOf(value) === -1;
      }
    }
  },
  watch: {
    '$route.params': {
      async handler() {
        await this.$store.dispatch('teams/getById', this.$route.params.id);
      },
      immediate: true
    }
  },
  methods: {
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      const lock = this.$lock();
      try {
        const email = this.emailToAdd.trim();
        await this.$store.dispatch('teams/addMember', {
          teamId: this.$route.params.id,
          email
        });

        this.$toast.success({
          title: 'Member Added',
          message: `'${email}' was added to the team '${this.item.name}'.`
        });

        this.$v.$reset();
        this.emailToAdd = '';
      } finally {
        lock.release();
      }
    },
    async suggestMember(s) {
      if (s?.length) {
        return httpClient.get('/api/auth/suggest/users?q=' + s);
      } else {
        return [];
      }
    },
    tag(email) {
      this.emailToAdd = email;
    },
    async removeMember(email) {
      const lock = this.$lock();
      try {
        await this.$store.dispatch('teams/removeMember', {
          teamId: this.$route.params.id,
          email
        });

        this.$toast.success({
          title: 'Member Removed',
          message: `'${email}' was removed from the team '${this.item.name}'.`
        });
      } catch (e) {
        this.$toast.error({
          title: 'Failed to remove member',
          message: `Please, try again later or contact our development team.`
        });
      } finally {
        lock.release();
      }
    },
    back() {
      this.$router.back();
    },
    async confirmDelete() {
      const name = this.item.name;

      const confirmResult = await this.$confirm({
        title: 'Delete team?',
        message: `Are you sure you want to delete team '${name}'? This action can't be undone.`,
        confirm: 'Delete'
      });

      if (!confirmResult) {
        return;
      }

      const lock = this.$lock();

      await this.$store.dispatch('teams/delete', this.$route.params.id);

      lock.release();

      if (this.isDeleteRequestFailed) {
        this.$toast.error({
          title: 'Failed to delete team',
          message: `Please, try again later or contact our development team.`
        });
      } else {
        this.$toast.success({
          title: 'Team deleted',
          message: `Team '${name}' was successfully deleted.`
        });
        this.$router.push({ path: `/teams` });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.edit-team {
  width: 100%;
  .form-row {
    margin-bottom: 20px;
  }
  header {
    width: 100%;
    padding: 1rem 2rem;
    border-bottom: 1px solid var(--theme-highlight);
    box-sizing: border-box;
    position: relative;

    a {
      font-size: 0.8rem;
    }

    .action-wrapper {
      position: absolute;
      right: 2rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  h1 {
    color: var(--theme-on-surface);
    font-size: 1.5rem;
    font-weight: 700;
  }
  h3 {
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: 700;
    color: var(--theme-on-surface-dark);
    margin-bottom: 10px;
  }
  form,
  .form {
    max-width: 480px;

    > * {
      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }
      &:last-child {
        display: flex;
        justify-content: flex-end;
      }
    }

    .error {
      font-size: 0.8rem;
      color: var(--theme-error);
      text-align: left;
      padding: 0.25rem 0;
      display: none;
    }
    &.dirty {
      .error {
        display: block;
      }
    }
  }

  .users {
    display: grid;
    margin-bottom: 2rem;

    .user-list-wrapper {
      display: grid;
      grid-template-rows: 20px max-content minmax(0, 1fr);

      .header-wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-size: 0.75rem;
        margin: 0;
        padding: 0;
        font-weight: 400;
        margin-right: 6px;
        label {
          font-weight: 500;
        }

        > * {
          &:not(:last-child) {
            margin-right: 0.5rem;
          }
        }
      }
    }

    .user-list {
      margin: 0;
      padding: 0;
      list-style: none;

      .user-list-item {
        display: grid;
        grid-template-columns: minmax(0, 1fr) 30px;
        grid-gap: 0;
        border-bottom: 1px solid var(--theme-highlight);
        font-size: 0.75rem;

        > * {
          padding: 0.5rem 0.5rem;
          border-left: 1px solid var(--theme-highlight);

          &:last-child {
            border-right: 1px solid var(--theme-highlight);
            padding-top: 0;
            padding-bottom: 0;
          }
        }

        &:first-child {
          border-top: 1px solid var(--theme-highlight);
        }

        &.header {
          font-size: 0.75rem;
          color: var(--theme-on-background-accent);
          > * {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            padding: 0.25rem 0.5rem;
          }
        }

        .action-link-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          border-left-color: transparent;

          > * {
            opacity: 0;
          }
        }

        &:hover {
          .action-link-wrapper {
            > * {
              opacity: 1;
            }
          }
        }
      }

      &:not(:last-child) {
        margin-right: 6px;
      }

      &:last-child {
        overflow-y: scroll;
      }
    }
  }

  > div,
  > section {
    max-width: 480px;
    padding: 1rem 1rem 0 2rem;
  }
}
</style>
