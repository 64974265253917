function findNearestVertex(distances, visited) {
  let minDistance = Infinity;
  let nearestVertex = null;

  Object.keys(distances).forEach(vertex => {
    if (!visited[vertex] && distances[vertex] < minDistance) {
      minDistance = distances[vertex];
      nearestVertex = vertex;
    }
  });

  return nearestVertex;
}

export function dijkstra(graph, startVertex) {
  let visited = {};
  let distances = {};
  let previous = {};

  let vertices = Object.keys(graph);

  vertices.forEach(vertex => {
    distances[vertex] = Infinity;
    previous[vertex] = null;
  });

  distances[startVertex] = 0;

  function handleVertex(vertex) {
    let activeVertexDistance = distances[vertex];

    let neighbours = graph[activeVertex];
    if (neighbours) {
      Object.keys(neighbours).forEach(neighbourVertex => {
        let currentNeighbourDistance = distances[neighbourVertex];
        let newNeighbourDistance = activeVertexDistance + neighbours[neighbourVertex];

        if (newNeighbourDistance < currentNeighbourDistance) {
          distances[neighbourVertex] = newNeighbourDistance;
          previous[neighbourVertex] = vertex;
        }
      });
    }
    visited[vertex] = 1;
  }

  let activeVertex = findNearestVertex(distances, visited);
  while (activeVertex) {
    handleVertex(activeVertex);
    activeVertex = findNearestVertex(distances, visited);
  }

  return { distances, previous };
}
