import { factory, am } from './utils';

import httpClient from '@/utils/httpClient';

const ADD_MEMBER = am(`ADD_USER`);
const REMOVE_MEMBER = am(`REMOVE_USER`);
const GET_ASSISTANTS = am(`GET_ASSISTANTS`);

export default {
  namespaced: true,
  ...factory({
    state: {
      isAddMemberPending: false,
      isAddMemberFailed: false,
      isRemoveMemberPending: false,
      isRemoveMemberFailed: false,
      assistantsTeam: null,
      getAssistantsPending: false,
      getAssistantsFailed: false
    },
    mutations: {
      [ADD_MEMBER.STARTED](state) {
        state.isAddMemberPending = true;
        state.isAddMemberFailed = false;
      },
      [ADD_MEMBER.COMPLETED](state, { email, teamId }) {
        state.isAddMemberPending = false;

        const team = state.collection.find(team => team.id === teamId);
        if (team && team.members.indexOf(email) === -1) {
          team.members.push(email);
        }
        if (state.item.id === teamId && state.item.members.indexOf(email) === -1) {
          state.item.members.push(email);
        }
      },
      [ADD_MEMBER.FAILED](state) {
        state.isAddMemberPending = false;
        state.isAddMemberFailed = true;
      },
      [REMOVE_MEMBER.STARTED](state) {
        state.isRemoveMemberPending = true;
        state.isRemoveMemberFailed = false;
      },
      [REMOVE_MEMBER.COMPLETED](state, { email, teamId }) {
        state.isRemoveMemberPending = false;
        const team = state.collection.find(team => team.id === teamId);
        if (team) {
          team.members = team.members.filter(member => member !== email);
        }
        if (state.item.id === teamId) {
          state.item.members = state.item.members.filter(member => member !== email);
        }
      },
      [REMOVE_MEMBER.FAILED](state) {
        state.isRemoveMemberPending = false;
        state.isRemoveMemberFailed = true;
      },
      [GET_ASSISTANTS.STARTED](state) {
        state.assistantsTeam = null;
        state.getAssistantsPending = true;
        state.getAssistantsFailed = false;
      },
      [GET_ASSISTANTS.COMPLETED](state, team) {
        state.assistantsTeam = team;
        state.getAssistantsPending = false;
      },
      [GET_ASSISTANTS.FAILED](state) {
        state.assistantsTeam = null;
        state.getAssistantsPending = false;
        state.getAssistantsFailed = true;
      }
    },
    actions: {
      async addMember({ commit }, { teamId, email }) {
        try {
          commit(ADD_MEMBER.STARTED);
          await httpClient.post(`/api/auth/teams/${teamId}/members`, { email });

          commit(ADD_MEMBER.COMPLETED, { teamId, email });
        } catch (e) {
          commit(ADD_MEMBER.FAILED);
          throw e;
        }
      },
      async removeMember({ commit }, { teamId, email }) {
        try {
          commit(REMOVE_MEMBER.STARTED);
          await httpClient.delete(`/api/auth/teams/${teamId}/members/${encodeURIComponent(email)}`);

          commit(REMOVE_MEMBER.COMPLETED, { teamId, email });
        } catch (e) {
          commit(REMOVE_MEMBER.FAILED);
          throw e;
        }
      },
      async getAssistants({ commit }, email) {
        try {
          commit(GET_ASSISTANTS.STARTED);
          const team = await httpClient.get(`/api/auth/teams/assistants/${email}`);
          commit(GET_ASSISTANTS.COMPLETED, team);
        } catch (e) {
          commit(GET_ASSISTANTS.FAILED);
        }
      }
    }
  })('teams', {
    async getById(id) {
      // return {
      //   id,
      //   name: 'Team ' + id,
      //   members: ['a.drebot+01@galacticalabs.com', 'a.drebot+02@galacticalabs.com', 'a.drebot+03@galacticalabs.com', 'a.drebot+04@galacticalabs.com']
      // };
      return httpClient.get(`/api/auth/teams/${id}`);
    },
    async getCollection(filter = {}) {
      // return {
      //   data: [
      //     {
      //       id: 1,
      //       name: 'Team #1',
      //       createdAt: new Date()
      //     },
      //     {
      //       id: 2,
      //       name: 'Team #2',
      //       createdAt: new Date()
      //     }
      //   ],
      //   total: 2
      // };
      let query = Object.entries(filter)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');
      return httpClient.get(`/api/auth/teams?${query}`);
    },
    async create(data) {
      return httpClient.post('/api/auth/teams/', data);
    },
    async delete(id) {
      return httpClient.delete(`/api/auth/teams/${id}`);
    }
  })
};
