import { factory, cruduex, am } from './utils';

import httpClient from '@/utils/httpClient';

const IMPORT = am(`IMPORT`);
const ADD_TAG = am(`ADD_TAG`);
const REMOVE_TAG = am(`REMOVE_TAG`);

export default {
  namespaced: true,
  ...factory({
    state: {
      isAddTagPending: false,
      isRemoveTagPending: false
    },
    mutations: {
      [ADD_TAG.STARTED](state) {
        state.isAddTagPending = true;
      },
      [ADD_TAG.COMPLETED](state, { applicationId, tag }) {
        const application = state.collection.find(item => item.source.id === applicationId);
        if (application) {
          application.tags = application.tags || [];
          application.tags.push(tag);

          state.aggs.tags = state.aggs.tags || {};
          if (typeof state.aggs.tags[tag] === 'undefined') {
            state.aggs.tags = {
              ...state.aggs.tags,
              [tag]: 1
            };
          } else {
            state.aggs.tags[tag]++;
          }
        }
        state.isAddTagPending = false;
      },
      [ADD_TAG.FAILED](state) {
        state.isAddTagPending = false;
      },
      [REMOVE_TAG.STARTED](state) {
        state.isRemoveTagPending = true;
      },
      [REMOVE_TAG.COMPLETED](state, { applicationId, tag }) {
        const application = state.collection.find(item => item.source.id === applicationId);
        if (application) {
          const index = application.tags.findIndex(item => item === tag);
          if (index > -1) {
            application.tags.splice(index, 1);

            if (state.aggs.tags && state.aggs.tags[tag]) {
              state.aggs.tags[tag] = state.aggs.tags[tag] - 1;
            }

            if (state.aggs.tags[tag] <= 0) {
              delete state.aggs.tags[tag];
              state.aggs.tags = {
                ...state.aggs.tags
              };
            }
          }
        }
        state.isRemoveTagPending = false;
      },
      [REMOVE_TAG.FAILED](state) {
        state.isRemoveTagPending = false;
      }
    },
    actions: {
      async addTag({ commit, state }, { applicationId, tag }) {
        try {
          commit(ADD_TAG.STARTED);
          const application = state.collection.find(application => application.source.id === applicationId);
          if (!application) {
            throw new Error(`Unable to find application with id '${applicationId}'`);
          }

          httpClient.put(`/api/applications/${applicationId}`, {
            tags: Array.from(new Set([...application.tags, tag]))
          });
          commit(ADD_TAG.COMPLETED, { applicationId, tag });
        } catch (e) {
          commit(ADD_TAG.FAILED);
          throw e;
        }
      },
      async removeTag({ commit, state }, { applicationId, tag }) {
        try {
          commit(REMOVE_TAG.STARTED);
          const application = state.collection.find(application => application.source.id === applicationId);
          if (!application) {
            throw new Error(`Unable to find application with id '${applicationId}'`);
          }

          httpClient.put(`/api/applications/${applicationId}`, {
            tags: application.tags.filter(t => t !== tag)
          });

          commit(REMOVE_TAG.COMPLETED, { applicationId, tag });
        } catch (e) {
          commit(REMOVE_TAG.FAILED);
          throw e;
        }
      }
    }
  })(
    'portfolio',
    {
      async getById(id) {
        return httpClient.get(`/api/search/${id}`);
      },
      async getCollection({ search }) {
        const params = new URLSearchParams(search);

        const { data, total, aggs } = await httpClient.get(`/api/search?include=id,title,abstract,tags&` + params.toString());

        return {
          data,
          total,
          aggs,
          size: +params.get('size'),
          from: +params.get('from'),
          sort: params.get('sort') || ''
        };
      },
      async create({ id, ...obj }) {
        return httpClient.put(`/api/applications/${id}`, obj);
      },
      async update(id, data) {
        return httpClient.put(`/api/applications/${id}`, data);
      }
    },
    {
      import: {
        namespaced: true,
        state: {
          isImportRequestPending: false,
          isImportRequestFailed: false
        },
        mutations: {
          [IMPORT.STARTED](state) {
            state.isImportRequestPending = false;
            state.isImportRequestFailed = false;
          },
          [IMPORT.COMPLETED](state) {
            state.isImportRequestPending = false;
          },
          [IMPORT.FAILED](state) {
            state.isImportRequestPending = false;
            state.isImportRequestFailed = true;
          }
        },
        actions: {
          async csv({ commit }, { file }) {
            try {
              commit(IMPORT.STARTED);

              const response = await httpClient.post(`/api/applications/csv`, {
                csvFileData: await new Promise((resolve, reject) => {
                  const reader = new FileReader();
                  reader.readAsDataURL(file);
                  reader.onload = () => resolve(reader.result.split(',')[1]);
                  reader.onerror = error => reject(error);
                })
              });

              commit(IMPORT.COMPLETED);

              return response;
            } catch (e) {
              commit(IMPORT.FAILED);
            }
          }
        }
      },
      practitioners: {
        namespaced: true,
        ...cruduex('practitioners', {
          async getById(id) {
            throw new Error('Not Implemented');
          },
          async getCollection() {
            return await httpClient.get(`/api/pair-authorizator/practitioners`);
          },
          async create(obj) {
            throw new Error('Not Implemented');
          },
          async update(id, data) {
            throw new Error('Not Implemented');
          },
          async delete(id) {
            throw new Error('Not Implemented');
          }
        })
      },
      users: {
        namespaced: true,
        ...cruduex('users', {
          async getById(id) {
            return await httpClient.get(`/api/pair-authorizator/users/${id}`);
          },
          async getCollection() {
            return await httpClient.get(`/api/pair-authorizator/users`);
          },
          async create(obj) {
            throw new Error('Not Implemented');
          },
          async update(id, data) {
            return await httpClient.put(`/api/pair-authorizator/users/${id}`, data);
          },
          async delete(id) {
            throw new Error('Not Implemented');
          }
        })
      }
    }
  )
};
