<template>
  <div class="content">
    <div>
      <Checkbox v-model="localItem.renderOnly" label="Just render this template (don't convert <p> into object model)" @change="onChange" />
    </div>
    <Code
      v-model="localItem.template"
      :read-only="!$hasPermission('prompts.write') || isPromptStreaming"
      :suggestions="localSuggestions"
      :language="'handlebars'"
      class="editor"
      :auto-height="true"
      @input="onChange"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Code from '@/components/common/Code';
import Checkbox from '@/components/common/Checkbox';

export default {
  components: {
    Code,
    Checkbox
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    suggestions: {
      type: Array,
      required: true
    },
    variables: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      localItem: {
        ...this.item,
        renderOnly: this.item.renderOnly || false
      }
    };
  },
  computed: {
    ...mapState({
      documentation: s => s.prompts.sample?.documentation,
      isPromptStreaming: s => s.prompts.sample.isRequestPending
    }),
    localSuggestions() {
      let docSuggestions = [];
      const documentation = this.documentation;
      if (documentation) {
        docSuggestions =
          documentation && Array.isArray(documentation.helperFunctions)
            ? documentation.helperFunctions.map(s => ({
                label: s.name,
                documentation: s.description,
                arguments: s.arguments,
                example: s.example
              }))
            : [];
      }

      return [...this.suggestions, ...docSuggestions];
    }
  },
  methods: {
    onChange() {
      this.$emit('change', { ...this.localItem });
    }
  }
};
</script>

<style scoped lang="scss">
.content {
  display: grid;
  gap: 5px;
  padding: 10px;

  .editor {
    height: 200px;
  }
}
</style>
