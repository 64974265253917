<template>
  <section v-if="isGetCollectionRequestPending">
    <p-loading />
  </section>
  <section v-else class="portfolio">
    <div class="form-wrapper">
      <form :disabled="isGetCollectionRequestPending" @submit.prevent="submit">
        <div class="form-row">
          <p-text-field v-model="value" :multiline="true" :rows="8" :label="'Query'" placeholder="Type or paste a query here..." />
        </div>

        <div class="form-row submit">
          <div>
            <p-file-select variant="text" color="primary" :accept="'.csv'" :disabled="isImportRequestPending" @change="uploadCSV"
              >Upload CSV</p-file-select
            >
          </div>
          <p-button color="primary" :disabled="isGetCollectionRequestPending">Submit</p-button>
        </div>
      </form>
      <div class="faceted-search-wrapper">
        <p-faceted-search />
      </div>
    </div>
    <div class="list-wrapper">
      <div class="header-wrapper">
        <div class="link-wrapper">
          <p-button :disabled="total === 0" variant="text" color="primary" @click="downloadCSV">
            <p-icon name="download" size="md"></p-icon>download csv
          </p-button>
          <p-add-tag-modal :application="applicationToAddTag" @close="applicationToAddTag = null" @addTag="addTag" />
        </div>
        <div>
          <label>Results per page:</label>
          <p-dropdown :value="size" :options="options.size" @change="setSize"></p-dropdown>
        </div>
        <label>Total:</label> {{ total }}
      </div>
      <ul class="portfolio-list">
        <li class="portfolio-list-item header">
          <div>Application Number</div>
          <div>Title</div>
          <div>Abstract</div>
          <div>Tags</div>
        </li>
      </ul>
      <ul class="portfolio-list">
        <li v-for="entry in collection" :key="entry.source.id" class="portfolio-list-item">
          <!-- eslint-disable-next-line vue/no-v-html -->
          <div v-html="entry.id"></div>
          <!-- eslint-disable-next-line vue/no-v-html -->
          <div v-html="entry.title"></div>
          <!-- eslint-disable-next-line vue/no-v-html -->
          <div v-html="entry.abstract && entry.abstract.replace(/^(\s*<br( \/)?>)*|(<br( \/)?>\s*)*$/gm, '')"></div>
          <div>
            <ul class="tag-list">
              <li v-for="tag in entry.tags" :key="tag" class="tag-list-item">
                <span>{{ tag }}</span>
                <button tabindex="-1" @click="removeTag(entry, tag)">
                  <p-icon name="remove" size="sm"></p-icon>
                </button>
              </li>
              <li class="tag-list-item new" @click="applicationToAddTag = entry">
                <span>+ add tag</span>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import TextField from '@/components/common/TextField';
import Button from '@/components/common/Button';
import Icon from '@/components/common/Icon';
import Loading from '@/components/common/Loading';
import Dropdown from '@/components/common/Dropdown';
import FileSelect from '@/components/common/FileSelect';

import FacetedSearch from './FacetedSearch';
import AddTagModal from './AddTagModal';
import httpClient from '@/utils/httpClient';

export default {
  components: {
    'p-text-field': TextField,
    'p-button': Button,
    'p-icon': Icon,
    'p-loading': Loading,
    'p-dropdown': Dropdown,
    'p-faceted-search': FacetedSearch,
    'p-file-select': FileSelect,
    'p-add-tag-modal': AddTagModal
  },
  data() {
    return {
      value: '',
      options: {
        size: [
          { id: 50, label: '50' },
          { id: 100, label: '100' },
          { id: 250, label: '250' },
          { id: 500, label: '500' }
        ]
      },

      applicationToAddTag: null
    };
  },
  computed: {
    ...mapState({
      total: s => s.portfolio.total,
      size: s => s.portfolio.size,
      from: s => s.portfolio.from,
      sort: s => s.portfolio.sort,
      collection: s => s.portfolio.collection,
      isGetCollectionRequestPending: s => s.portfolio.isGetCollectionRequestPending,
      isImportRequestPending: s => s.portfolio.import.isImportRequestPending
    }),
    searchParams() {
      const { size = 250, from = 0 } = this.$route.query || {};

      const params = new URLSearchParams(document.location.search);
      params.set('size', size);
      params.set('from', from);

      return params.toString();
    }
  },
  watch: {
    $route: {
      handler(value) {
        this.value = (value.query && value.query.qs) || '';
        this.$store.dispatch('portfolio/getCollection', { search: this.searchParams });
      },
      immediate: true
    }
  },
  methods: {
    submit() {
      if (!this.value || !this.value.length) {
        return;
      }

      this.$router.push({
        path: '/portfolio',
        query: { qs: this.value }
      });
    },
    setSize(id) {
      const { size, from, ...query } = this.$route.query; // eslint-disable-line

      this.$router.push({
        path: this.$route.path,
        query: {
          ...query,
          ...(id ? { size: id } : {})
        }
      });
    },
    async downloadCSV() {
      const params = new URLSearchParams(this.searchParams);

      params.set('size', 10000);
      params.set('from', 0);

      const { downloadUrl } = await httpClient.get(`/api/search/csv?${params.toString()}`);

      window.open(`/api${downloadUrl}`, '_blank');
    },
    async uploadCSV([file]) {
      try {
        const result = await this.$store.dispatch('portfolio/import/csv', { file });

        this.$toast.success({
          title: 'Import completed',
          message: `${result.length} applications were updated`
        });
      } catch (e) {
        this.$toast.error({
          title: 'Import failed',
          message: `Please, try again later or contact our development team.`
        });
      }
    },
    async removeTag(application, tagToRemove) {
      const confirmResult = await this.$confirm({
        title: 'Remove tag?',
        message: `Are you sure you want to remove tag '${tagToRemove}'? This action can't be undone.`,
        confirm: 'Delete'
      });

      if (!confirmResult) {
        return;
      }

      const lock = this.$lock();
      try {
        await this.$store.dispatch('portfolio/removeTag', {
          applicationId: application.source.id,
          tag: tagToRemove
        });
        this.$toast.success({
          title: 'Remove Tag completed',
          message: `Tag '${tagToRemove}' was removed.`
        });
      } catch (e) {
        this.$toast.error({
          title: 'Remove tag failed',
          message: `Please, try again later or contact our development team.`
        });
      }

      lock.release();
    },
    async addTag(application, tagToAdd) {
      const lock = this.$lock();

      try {
        await this.$store.dispatch('portfolio/addTag', {
          applicationId: application.source.id,
          tag: tagToAdd
        });
        this.$toast.success({
          title: 'Add Tag completed',
          message: `Tag '${tagToAdd}' was added.`
        });
      } catch (e) {
        this.$toast.error({
          title: 'Add Tag failed',
          message: `Please, try again later or contact our development team.`
        });
      }

      lock.release();

      this.applicationToAddTag = null;
    }
  }
};
</script>

<style lang="scss" scoped>
.portfolio {
  display: grid;
  grid-template-columns: 300px minmax(0, 1fr);
  grid-template-rows: minmax(0, 1fr);
  grid-gap: 2rem;
  padding: 1rem 1rem 0 1rem;
  width: 100%;

  .form-wrapper {
    display: grid;
    grid-template-rows: max-content minmax(0, 1fr);
    grid-gap: 1rem;

    form {
      .form-row {
        label {
          font-size: 0.8rem;
        }
        &.submit {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        &:not(:last-child) {
          margin-bottom: 1rem;
        }
      }
    }

    .faceted-search-wrapper {
      padding: 0.5rem 0;
      overflow-y: scroll;
    }

    form,
    .faceted-search-wrapper {
      min-width: 0;
    }
  }

  .list-wrapper {
    display: grid;
    grid-template-rows: 20px max-content minmax(0, 1fr);

    .header-wrapper {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-size: 0.75rem;
      margin: 0;
      padding: 0;
      font-weight: 400;
      margin-right: 6px;
      label {
        font-weight: 500;
        margin-right: 0.5rem;
      }

      .link-wrapper {
        margin-right: auto;

        a {
          margin-right: 1rem;
          display: inline-flex;
          justify-content: flex-start;
          align-items: center;
          text-transform: uppercase;

          > * {
            &:not(:last-child) {
              margin-right: 0.25rem;
            }
          }
        }
      }
    }
  }

  .portfolio-list {
    margin: 0;
    padding: 0;
    list-style: none;

    .portfolio-list-item {
      display: grid;
      grid-template-columns: 125px minmax(0, 1fr) minmax(0, 2fr) 200px;
      grid-gap: 0;
      border-bottom: 1px solid var(--theme-highlight);
      font-size: 0.75rem;

      > * {
        padding: 0.5rem 0.5rem;
        border-left: 1px solid var(--theme-highlight);

        &:last-child {
          border-right: 1px solid var(--theme-highlight);
        }
      }

      &:first-child {
        border-top: 1px solid var(--theme-highlight);
      }

      &.header {
        font-size: 0.75rem;
        color: var(--theme-on-background-accent);
        > * {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          padding: 0.25rem 0.5rem;
        }
      }

      .tag-list {
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        .tag-list-item {
          display: inline-flex;
          padding: 0.25rem 0.35rem;
          color: var(--theme-on-surface);
          border: 1px solid var(--theme-on-surface-accent);
          background: var(--theme-surface);
          border-radius: 2px;
          box-sizing: border-box;
          font-size: 0.75rem;
          position: relative;

          margin-right: 0.5rem;
          margin-bottom: 0.5rem;

          & button {
            position: absolute;
            border: 1px solid var(--theme-on-surface-accent);
            background: var(--theme-surface);
            color: var(--theme-on-surface-accent);
            top: -8px;
            right: -8px;
            padding: 0;
            margin: 0;
            width: 16px;
            height: 16px;
            z-index: 1;
            border-radius: 50%;

            display: none;
            cursor: pointer;
            outline: none;

            &:focus {
              outline: none;
            }
          }

          &:hover {
            button {
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }

          &.new {
            font-style: italic;
            border-color: var(--theme-on-surface-accent);
            color: var(--theme-on-surface-accent);
            border-style: dotted;
            display: block;
            cursor: pointer;
          }
        }
      }
    }

    &:not(:last-child) {
      margin-right: 6px;
    }

    &:last-child {
      overflow-y: scroll;
    }
  }
}
</style>
