var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"editor-panel"},[_c('hub-button',{staticClass:"control-button",class:{ 'is-active': _vm.editor.isActive('bold') },attrs:{"variant":"text"},on:{"click":function($event){_vm.editor
        .chain()
        .focus()
        .toggleBold()
        .run()}}},[_c('hub-icon',{attrs:{"size":_vm.size,"name":"format-bold"}})],1),_c('hub-button',{staticClass:"control-button",class:{ 'is-active': _vm.editor.isActive('italic') },attrs:{"variant":"text"},on:{"click":function($event){_vm.editor
        .chain()
        .focus()
        .toggleItalic()
        .run()}}},[_c('hub-icon',{attrs:{"size":_vm.size,"name":"format-italic"}})],1),_c('hub-button',{staticClass:"control-button",class:{ 'is-active': _vm.editor.isActive('strike') },attrs:{"variant":"text"},on:{"click":function($event){_vm.editor
        .chain()
        .focus()
        .toggleStrike()
        .run()}}},[_c('hub-icon',{attrs:{"size":_vm.size,"name":"format-strikethrough-variant"}})],1),_c('hub-button',{staticClass:"control-button",class:{ 'is-active': _vm.editor.isActive('heading', { level: 2 }) },attrs:{"variant":"text"},on:{"click":function($event){_vm.editor
        .chain()
        .focus()
        .toggleHeading({ level: 2 })
        .run()}}},[_c('hub-icon',{attrs:{"size":_vm.size,"name":"format-header-1"}})],1),_c('hub-button',{staticClass:"control-button",class:{ 'is-active': _vm.editor.isActive('bulletList') },attrs:{"variant":"text"},on:{"click":function($event){_vm.editor
        .chain()
        .focus()
        .toggleBulletList()
        .run()}}},[_c('hub-icon',{attrs:{"size":_vm.size,"name":"format-list-bulleted"}})],1),_c('hub-button',{staticClass:"control-button",class:{ 'is-active': _vm.editor.isActive('orderedList') },attrs:{"variant":"text"},on:{"click":function($event){_vm.editor
        .chain()
        .focus()
        .toggleOrderedList()
        .run()}}},[_c('hub-icon',{attrs:{"size":_vm.size,"name":"format-list-numbered"}})],1),_c('hub-button',{staticClass:"control-button",class:{ 'is-active': _vm.editor.isActive('codeBlock') },attrs:{"variant":"text"},on:{"click":function($event){_vm.editor
        .chain()
        .focus()
        .toggleCodeBlock()
        .run()}}},[_c('hub-icon',{attrs:{"size":_vm.size,"name":"xml"}})],1),_c('hub-button',{staticClass:"control-button",class:{ 'is-active': _vm.editor.isActive('blockquote') },attrs:{"variant":"text"},on:{"click":function($event){_vm.editor
        .chain()
        .focus()
        .toggleBlockquote()
        .run()}}},[_c('hub-icon',{attrs:{"size":_vm.size,"name":"format-quote-close"}})],1),_c('hub-button',{staticClass:"control-button",class:{ 'is-active': _vm.editor.isActive('link') },attrs:{"variant":"text"},on:{"click":function($event){return _vm.onAddLink('link')}}},[_c('hub-icon',{attrs:{"size":_vm.size,"name":"link-variant"}})],1),(_vm.editor.isActive('link'))?_c('hub-button',{staticClass:"control-button",attrs:{"variant":"text"},on:{"click":function($event){return _vm.editor.commands.unsetLink()}}},[_c('hub-icon',{attrs:{"size":_vm.size,"name":"link-variant-remove"}})],1):_vm._e(),_c('hub-button',{staticClass:"control-button",class:{ 'is-active': _vm.editor.isActive('iframe') },attrs:{"variant":"text"},on:{"click":function($event){return _vm.onAddLink('video')}}},[_c('hub-icon',{attrs:{"size":_vm.size,"name":"video-plus"}})],1),_c('hub-button',{staticClass:"control-button",attrs:{"variant":"text"},on:{"click":function($event){_vm.editor
        .chain()
        .focus()
        .undo()
        .run()}}},[_c('hub-icon',{attrs:{"size":_vm.size,"name":"undo"}})],1),_c('hub-button',{staticClass:"control-button",attrs:{"variant":"text"},on:{"click":function($event){_vm.editor
        .chain()
        .focus()
        .redo()
        .run()}}},[_c('hub-icon',{attrs:{"size":_vm.size,"name":"redo"}})],1),(_vm.addingLink)?_c('div',{staticClass:"link-holder"},[_c('hub-text-field',{attrs:{"focus":true,"placeholder":_vm.addingLink === 'link' ? 'Type or insert link' : 'Insert iframe code'},model:{value:(_vm.link),callback:function ($$v) {_vm.link=$$v},expression:"link"}}),_c('div',{staticClass:"icons"},[(_vm.link)?_c('hub-button',{attrs:{"variant":"text"},on:{"click":_vm.addLink}},[_c('hub-icon',{attrs:{"name":"check"}})],1):_vm._e(),_c('hub-button',{attrs:{"variant":"text"},on:{"click":function($event){_vm.addingLink = ''}}},[_c('hub-icon',{attrs:{"name":"close"}})],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }