<template>
  <div class="uniqueness-dropdown">
    <Multiselect label="Milestone uniqueness" :value="localValue" :disabled="disabled" :options="options" @input="onChange" />
  </div>
</template>
<script>
import Multiselect from '@/components/common/Multiselect';

const mapValueToTitle = (value) => {
  if (value === 'uniqueAtTime') {
    return 'Unique at a time';
  }

  if (value === 'unique') {
    return 'Unique';
  }

  if (value === 'repeatable') {
    return 'Repeatable';
  }
};
export default {
  components: {
    Multiselect
  },
  props: {
    disabled: {
      type: Boolean
    },
    value: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      //'Unique at a time', 'Unique', 'Repeatable'
      //'uniqueAtTime' | 'unique' | 'repeatable'
      options: ['Unique at a time', 'Unique', 'Repeatable'],
      localValue: mapValueToTitle(this.value)
    };
  },
  watch: {
    value(nw) {
      this.localValue = mapValueToTitle(nw);
    }
  },
  methods: {
    onChange(e) {
      let valueToEmit;
      if (e === 'Unique at a time') {
        valueToEmit = 'uniqueAtTime';
      }
      if (e === 'Unique') {
        valueToEmit = 'unique';
      }
      if (e === 'Repeatable') {
        valueToEmit = 'repeatable';
      }
      this.$emit('input', valueToEmit);
    }
  }
};
</script>
<style lang="scss" scoped>
.uniqueness-dropdown {
  margin-bottom: 15px;
}
</style>
