import { am } from './utils';

import httpClient from '@/utils/httpClient';

const logger = console;

const INITIALIZE = am(`INITIALIZE`);
const GET_COLLECTION = am(`GET_PROCESSING_COLLECTION`);
const CREATE = am(`CREATE_PROCESSING`);
const DELETE = am(`DELETE_PROCESSING`);

const UPDATE_PROCESSING = am(`UPDATE_PROCESSING`);

const state = {
  isGetCollectionRequestPending: false,
  isGetCollectionRequestFailed: false,

  isCreateRequestPending: false,
  isCreateRequestFailed: false,

  isDeleteRequestPending: false,
  isDeleteRequestFailed: false,

  collection: [],
  total: 0,
  size: 0,
  from: 0,
  aggs: null,

  isReady: false,
  active: null
};

const mutations = {
  [INITIALIZE.STARTED](state) {
    state.isReady = false;
    state.active = null;
    state.jobs = null;
  },
  [INITIALIZE.COMPLETED](state, { active, jobs }) {
    state.isReady = true;
    state.active = active;
    state.jobs = jobs;
  },
  [GET_COLLECTION.STARTED](state) {
    state.isGetCollectionRequestPending = true;
    state.isGetCollectionRequestFailed = false;
  },
  [GET_COLLECTION.FAILED](state) {
    state.isGetCollectionRequestPending = false;
    state.isGetCollectionRequestFailed = true;
  },
  [GET_COLLECTION.COMPLETED](state, collection) {
    state.isGetCollectionRequestPending = false;

    if (Array.isArray(collection)) {
      state.collection = collection;
    } else {
      state.collection = collection.data;
      state.aggs = collection.aggs;
      state.total = collection.total;
      state.size = collection.size;
      state.from = collection.from;
    }
  },

  [CREATE.STARTED](state) {
    state.isCreateRequestPending = true;
    state.isCreateRequestFailed = false;
  },
  [CREATE.FAILED](state) {
    state.isCreateRequestPending = false;
    state.isCreateRequestFailed = true;
  },
  [CREATE.COMPLETED](state, collection) {
    state.isCreateRequestPending = false;
    for (const item of collection) {
      const existing = state.collection.find(e => e.id === item.id);
      if (existing) {
        existing.status = item.status;
        existing.updatedAt = item.updatedAt;
        existing.message = item.message;
        existing.stack = item.stack;
      }
    }
  },

  [DELETE.STARTED](state) {
    state.isDeleteRequestPending = true;
    state.isDeleteRequestFailed = false;
  },
  [DELETE.FAILED](state) {
    state.isDeleteRequestPending = false;
    state.isDeleteRequestFailed = true;
  },
  [DELETE.COMPLETED](state, id) {
    state.isDeleteRequestPending = false;
    const index = state.collection.findIndex(t => t.id === id);
    if (index) {
      state.collection.splice(index, 1);
    }
    if (state.item && state.item.id === id) {
      state.item = null;
    }
  },

  [UPDATE_PROCESSING.COMPLETED](state, { active }) {
    state.active = active;
  }
};

const actions = {
  async initialize({ commit }) {
    commit(INITIALIZE.STARTED);
    try {
      const response = await httpClient.get(`/api/dispatcher/status`);

      commit(INITIALIZE.COMPLETED, response);
    } catch (e) {
      logger.error(e);
    }
  },
  async create({ commit }, { applications }) {
    commit(CREATE.STARTED);
    try {
      const response = await httpClient.post(`/api/dispatcher/schedule`, applications);
      commit(CREATE.COMPLETED, response);

      return response;
    } catch (e) {
      commit(CREATE.FAILED);
      logger.error(e);
    }
  },

  async delete({ commit }, id) {
    commit(DELETE.STARTED);
    try {
      await httpClient.delete(`/api/dispatcher/${encodeURIComponent(id)}`);
      commit(DELETE.COMPLETED, id);
    } catch (e) {
      commit(DELETE.FAILED);
      logger.error(e);
    }
  },

  async getCollection({ commit }) {
    commit(GET_COLLECTION.STARTED);
    try {
      const { data, total, aggs } = await httpClient.get(`/api/dispatcher/`);

      commit(GET_COLLECTION.COMPLETED, {
        data,
        total,
        aggs
      });
    } catch (e) {
      commit(GET_COLLECTION.FAILED);
      logger.error(e);
    }
  },

  async pause({ commit }) {
    try {
      await httpClient.post(`/api/dispatcher/pause`);
      commit(UPDATE_PROCESSING.COMPLETED, { active: false });
    } catch (e) {
      logger.error(e);
    }
  },
  async resume({ commit }) {
    try {
      await httpClient.post(`/api/dispatcher/resume`);
      commit(UPDATE_PROCESSING.COMPLETED, { active: true });
    } catch (e) {
      logger.error(e);
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
