<template>
  <p-create v-if="$hasPermission('templates.write')" />
  <p-not-found v-else />
</template>

<script>
import CreateDrawing from './../components/drawings/CreateDrawing.vue';
import NotFound from './../components/NotFound.vue';
export default {
  components: {
    'p-create': CreateDrawing,
    'p-not-found': NotFound
  }
};
</script>
